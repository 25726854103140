import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCalGetFile, apiCallGet, apiCallPost } from "../../Services/ApiClient";
import {
  GET_REFERRALS_API, REFERRAL_TYPE_API, GET_REFERRAL_DETAIL_API,
  GET_REWARD_HISTORY_API, UPDATE_USER_LEVEL, APPROVAL_REQUEST_LIST, UPDATE_REFERRAL_STATUS,
  GET_CLAIM_REQUEST, UPDATE_CLAIM_STATUS
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getReferralUrlList ****************************************************/
export const getReferralList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_REFERRALS_API, data).then(response => {
        const res = response.data;
        // console.log('res getReferralUrlList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getReferralUrlList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Get Referral Type List ****************************************************/
export const getReferralTypeList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(REFERRAL_TYPE_API).then(response => {
        const res = response.data;
        // console.log('res getReferralTypeList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getReferralTypeList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getReferralDetail ****************************************************/
export const getReferralDetail = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_REFERRAL_DETAIL_API, data).then(response => {
        const res = response.data;
        // console.log('res getReferralDetail::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getReferralDetail er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getRewardHistory ****************************************************/
export const getRewardHistory = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_REWARD_HISTORY_API, data).then(response => {
        const res = response.data;
        // console.log('res getRewardHistory::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getRewardHistory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** updateUserLevel ****************************************************/
export const updateUserLevel = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_USER_LEVEL, data).then(response => {
        const res = response.data;
        // console.log('res updateUserLevel::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateUserLevel er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getApprovalRequestList ****************************************************/
export const getApprovalRequestList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(APPROVAL_REQUEST_LIST, data).then(response => {
        const res = response.data;
        // console.log('res getApprovalRequestList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getApprovalRequestList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** updateStatusRequest ****************************************************/
export const updateStatusRequest = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_REFERRAL_STATUS, data).then(response => {
        const res = response.data;
        // console.log('res updateStatusRequest::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateStatusRequest er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getClaimRequestList ****************************************************/
export const getClaimRequestList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_CLAIM_REQUEST, data).then(response => {
        const res = response.data;
        // console.log('res getClaimRequestList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getClaimRequestList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getUpdateClaimStatus ****************************************************/
export const getUpdateClaimStatus = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_CLAIM_STATUS, data).then(response => {
        const res = response.data;
        // console.log('res getUpdateClaimStatus::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getUpdateClaimStatus er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};