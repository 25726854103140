import React, { useEffect, useState } from "react";
import { Col, Row, Table, Select } from "antd";
import CkEdior from "../../Ui/CkEdior/CkEdior";
import "./Questionnaire.scss";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  MAX_OPTIONS_LENGTH,
  MAX_OPTIONS_LENGTH_EXCEEDED,
  REMOVE_OPTION_INDEX,
} from "../../constant/constant";
import { toast } from "../../../Components/Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestion,
  getCategoryList,
  getQuestionList,
  getQuestionsList,
  deleteQuestion,
  updateQuestionById,
} from "../../../redux/_actions/questionAction";
import Swal from "sweetalert2";
import moment from "moment";

function QuestionnaireTab({ activeTab }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [questionsList, setQuestionsList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [question, setQuestion] = useState({
    title: "",
    answers: [
      { option: "", id: 1, skipTo: 0, category: 0 },
      { option: "", id: 2, skipTo: 0, category: 0 },
    ],
  });
  const [editableState, setEditableState] = useState({
    id: "",
    sequence: "",
  });
  const [validation, setValidation] = useState({
    title: "",
    answers: [{ option: "" }],
  });
  const [limit] = useState(20);
  const [selectedPage, setSelectedPage] = useState(1);
  const page = 1;

  useEffect(() => {
    fetchQuestionList();
    fetchCategoryList();
    fetchQuestionsList();
    window.scrollTo(0, 0); // Move Screeen to Top
  }, [selectedPage, activeTab]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const fetchQuestionList = () => {
    setTimeout(() => {
      dispatch(getQuestionList({}))
        .then((response) => {
          const tempData = [];
          response?.map((val) => {
            const regex = /(<([^>]+)>)/gi;
            const result = val.label.replace(regex, "");
            const lastResult = stripHtmlTags(result);
            return tempData.push({ value: val.value, label: lastResult });
          });
          setQuestionList(tempData);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const fetchCategoryList = () => {
    setTimeout(() => {
      dispatch(getCategoryList({}))
        .then((response) => {
          const tempData = [];
          response?.map((val) => {
            return tempData.push({ value: val.id, label: val.name });
          });
          setCategoryList(tempData);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const fetchQuestionsList = () => {
    const data = {
      limit: limit,
      page: pageCount,
    };
    setTimeout(() => {
      dispatch(getQuestionsList({ data }))
        .then((response) => {
          const res = response.data;
          setQuestionsList(res);
          setMetaData(response?.meta);
          setTotalPages(response?.meta?.total);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };
  const getQuestionString = (value) => {
    const regex = /(<([^>]+)>)/gi;
    const result = value.replace(regex, "");
    return result;
  };

  const deleteQuestionById = (item) => {
    setLoading(true);
    const data = {
      questionId: item.id,
    };
    setTimeout(() => {
      dispatch(deleteQuestion({ data }))
        .then((response) => {
          fetchQuestionsList();
          Swal.fire({
            icon: "success",
            title: "Success!",
            confirmButtonColor: "#d3d3d3",
            text: "Question deleted successfully!",
            color: "#7D80DF",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  const QuestionnaireColumn = [
    {
      title: "Question Number",
      dataIndex: "questionNumber",
      key: "questionNumber",
    },
    {
      title: "Question Bank",
      dataIndex: "questionBank",
      key: "questionBank",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: <span className="center">Action</span>,
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const editQuestion = (data) => {
    const { question, answers, id, sequence } = data;
    setEditableState({ id, sequence });
    // let regex = /(<([^>]+)>)/gi,
    //   body = question,
    //   result = body.replace(regex, "");
    setQuestion({
      title: stripHtmlTags(question),
      answers: answers,
    });
    window.scrollTo(0, 0); // Move Screeen to Top
  };

  /************************************** data for Questionnaire table ****************************************************/
  const dataSource = questionsList?.map((item, index) => {
    return {
      questionNumber: (
        <div className="imgsec">
          <div>
            {
              <label className="ml-15">
                {(metaData?.page - 1) * limit + (index + 1)}
              </label>
            }
          </div>
        </div>
      ),
      questionBank: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{stripHtmlTags(item.question)}</span>
        </div>
      ),
      date: (
        <div style={{ marginLeft: 10 }} className="imgsec disable">
          <span className="fontFamilyText">
            {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss A")}
          </span>
        </div>
      ),
      actions: (
        <div className="imgsec actionOptions" style={{ marginLeft: 5 }}>
          <button
            className="deleteBtn"
            onClick={() => {
              editQuestion(item);
            }}
          >
            <EditOutlined /> <span>Edit</span>
          </button>
          <button
            className="deleteBtn"
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to delete?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#d3d3d3",
                confirmButtonText: "Yes",
                cancelButtonText: "NO",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteQuestionById(item);
                }
              });
            }}
          >
            <DeleteOutlined /> <span>Delete</span>
          </button>
        </div>
      ),
    };
  });
  const editorHandler = (data) => {
    setQuestion((prevState) => ({
      ...prevState,
      title: data.trimStart(),
    }));
  };
  const getAlphabetIndex = (index) => {
    var alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    return alphabet[index].toUpperCase();
  };
  function renderAnswers() {
    return (
      <>
        {console.log("question", question)}
        {Array.isArray(question?.answers) &&
          question?.answers.map((data, index) => (
            <div className="selectores selectorPosItems">
              <Row gutter={[12, 12]} key={question.answers[index].id}>
                <Col xs={24} md={12} lg={6}>
                  <InputCustom
                    label="Select Option"
                    inputCustum
                    basicInput
                    placeholder={`Option ${getAlphabetIndex(index)}`}
                    value={
                      Array.isArray(question?.answers) &&
                      question.answers[index].option
                    }
                    onChange={(e) => {
                      let state = [...question.answers];
                      state[index].option = e.target.value.trimStart();
                      setQuestion({
                        ...question,
                        answers: state,
                      });
                    }}
                  />
                  <p className="error">{validation?.answers[index]?.option}</p>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <CustomSelect
                    labelcustom="labelcustom"
                    CustomSelectStyle="CustomSelectStyle"
                    label={"Link To"}
                    placeholder="Select"
                    data={questionList}
                    defaultData={
                      Array.isArray(question?.answers) &&
                      question.answers[index].skipTo
                    }
                    onChange={(e) => {
                      let state = [...question.answers];
                      state[index].skipTo = e;
                      setQuestion({
                        ...question,
                        answers: state,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <CustomSelect
                    labelcustom="labelcustom"
                    CustomSelectStyle="CustomSelectStyle"
                    label={"Assign Category"}
                    placeholder="Select"
                    data={categoryList}
                    defaultData={
                      Array.isArray(question?.answers) &&
                      question.answers[index].category
                    }
                    onChange={(e) => {
                      let state = [...question.answers];
                      state[index].category = e;
                      setQuestion({
                        ...question,
                        answers: state,
                      });
                    }}
                  />
                </Col>
                {index > REMOVE_OPTION_INDEX ? (
                  <Col
                    xs={24}
                    md={12}
                    lg={6}
                    className="deleteOption deleteAlign"
                  >
                    <ButtonCustom
                      onClick={() => {
                        let arr = [...question.answers];
                        let errorArr = [...validation.answers];
                        arr.splice(index, 1);
                        errorArr.splice(index, 1);

                        //create new id
                        for (let i = index; i < arr.length; i++) {
                          const element = arr[i];
                          element.id = i + 1;
                        }

                        setQuestion({
                          ...question,
                          answers: arr,
                        });
                        setValidation({
                          ...validation,
                          answers: errorArr,
                        });
                      }}
                      label="Remove"
                      yellowBtn
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          ))}
      </>
    );
  }
  const checkValidation = () => {
    let errors = validation;
    if (!question.title) {
      errors.title = "Question is required";
    } else {
      errors.title = "";
    }

    if (question.answers) {
      let array = [...question.answers];
      let arrError = [];
      for (let index = 0; index < question.answers.length; index++) {
        let answer = array[index];
        if (!answer.option) {
          arrError.push({ option: `Option is required` });
        } else {
          arrError.push({ option: `` });
        }
      }
      errors.answers = arrError;
    }
    setValidation({ ...errors });
  };
  const verifyValidation = () => {
    let result = true;
    if (validation.title !== "") {
      result = false;
    }
    if (validation.answers) {
      for (let index = 0; index < validation.answers.length; index++) {
        const obj = validation.answers[index];
        if (obj.option !== "") {
          result = false;
        }
      }
    }
    return result;
  };
  const setupAnswerData = (data) => {
    let question = { ...data };
    let modifiedAnswerArray =
      Array.isArray(data?.answers) &&
      data.answers.map((data) => {
        return { ...data };
      });
    return { ...question, answers: modifiedAnswerArray };
  };
  const resetState = () => {
    setQuestion({
      title: "",
      answers: [
        { option: "", id: 1, skipTo: 0, category: 0 },
        { option: "", id: 2, skipTo: 0, category: 0 },
      ],
    });
    setValidation({
      title: "",
      answers: [{ option: "" }],
    });
    setEditableState({
      id: "",
      sequence: "",
    });
    fetchQuestionList();
  };
  const saveQuestion = async () => {
    checkValidation();
    const result = verifyValidation();
    if (result) {
      let data = setupAnswerData(question);
      setLoading(true);
      setTimeout(() => {
        dispatch(addQuestion({ data }))
          .then((response) => {
            fetchQuestionsList();
            Swal.fire({
              icon: "success",
              title: "Success!",
              confirmButtonColor: "#d3d3d3",
              text: "Question added successfully!",
              color: "#7D80DF",
            });
            resetState();
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    }
  };
  const updateQuestion = async () => {
    checkValidation();
    const result = verifyValidation();
    if (result) {
      let data = setupAnswerData(question);
      data.questionId = editableState.id;
      setLoading(true);
      setTimeout(() => {
        dispatch(updateQuestionById({ data }))
          .then((response) => {
            fetchQuestionsList();
            Swal.fire({
              icon: "success",
              title: "Success!",
              confirmButtonColor: "#d3d3d3",
              text: "Question updated successfully!",
              color: "#7D80DF",
            });
            resetState();
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    }
  };
  return (
    <div>
      {console.log("question?.title", question?.title)}
      <CkEdior
        editorHandler={editorHandler}
        additionalFeature={question?.title}
        title={"Add Question"}
      />
      <div className="textleftOuter">
        <span className="errTxt">{validation.title}</span>
      </div>
      {renderAnswers()}
      <div className="addOpption">
        <button
          onClick={() => {
            if (question.answers.length >= MAX_OPTIONS_LENGTH) {
              toast.error(MAX_OPTIONS_LENGTH_EXCEEDED);
              return;
            }
            let arr = [...question.answers];
            arr.push({
              option: "",
              id: question.answers.length + 1,
              skipTo: 0,
              category: 0,
            });
            setQuestion({
              ...question,
              answers: arr,
            });
          }}
        >
          <h2>Add Options +</h2>
        </button>
      </div>
      {editableState.id === "" ? (
        <div className="addButton">
          <ButtonCustom
            label="Save"
            yellowBtn
            onClick={() => {
              saveQuestion();
            }}
          />
        </div>
      ) : (
        <>
          <div className="update_operation">
            <div className="addButton">
              <ButtonCustom
                label="Update"
                yellowBtn
                onClick={() => {
                  updateQuestion();
                }}
              />
            </div>
            <div className="addButton cancelButton">
              <ButtonCustom
                label="Cancel"
                yellowBtn
                onClick={() => {
                  resetState();
                }}
              />
            </div>
          </div>
        </>
      )}

      <Table
        dataSource={dataSource}
        columns={QuestionnaireColumn}
        pagination={false}
        size="large"
      />
    </div>
  );
}

export default QuestionnaireTab;
