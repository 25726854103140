import React, { useEffect } from "react";
import { Checkbox } from "antd";
import "./Checkboxstyle.scss"


function CheckboxCustom(props) {
  const { checkboxTxt, checkbox, customClass, onChange, checked, changeStyle } = props;
  useEffect(() => {
    //console.log('chk checked prop value::::', checked)
  }, [props])

  return (
    <div>
      {changeStyle ?
        <Checkbox onChange={onChange} checked={checked} className={`checkboxCustom ${checkbox ? "checkbox" : ''} ${customClass}`} {...props}>
          {checkboxTxt ? <span className="checkboxTxtNew fontFamilyText">{checkboxTxt}</span> : null}
        </Checkbox>
        : <Checkbox onChange={onChange} checked={checked} className={`checkboxCustom ${checkbox ? "checkbox" : ''} ${customClass}`} {...props}>
          {checkboxTxt ? <span className="checkboxTxt fontFamilyText">{checkboxTxt}</span> : null}
        </Checkbox>
      }
    </div>
  );
}

export default CheckboxCustom;
