import { Breadcrumb, Dropdown, Menu, Table } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import { EyeOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

function MasterFranchise() {
    const menu = (
        <Menu className="topdropdown">
          <Menu.Item>
            <Menu
              className="trsp_itme"
              style={{ backgroundColor: "transparent !important" }}
            >
              <Menu.Item
                className="subitems"
                style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
              >
               General Public 
              </Menu.Item>
              <Menu.Item
                className="subitems"
                style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
              >
               Franchise
              </Menu.Item>
              <Menu.Item
                className="subitems"
                style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
              >
               Premium Franchise
              </Menu.Item>
              <Menu.Item
                className="subitems"
                style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
              >
                Master Franchise
              </Menu.Item>
            </Menu>
          </Menu.Item>
        </Menu>
      );
      const dataSource = [
        {
          key: "1",
          name: "Hina Khan",
          email: "tamanna.chandel@antiersolutions.com",
          details: "details",
          public: (
            <Link to={""} className="buttonView">
              <span className="fontFamilyText">
                View <EyeOutlined />
              </span>
            </Link>
          ),
          franchise: (
            <Link to={""} className="buttonView">
              <span className="fontFamilyText">
                View <EyeOutlined />
              </span>
            </Link>
          ),
          premium: (
            <Link to={""} className="buttonView">
              <span className="fontFamilyText">
                View <EyeOutlined />
              </span>
            </Link>
          ),
          mask: (
            <Link to={""} className="buttonView">
              <span className="fontFamilyText">
                View <EyeOutlined />
              </span>
            </Link>
          ),
          category: (
            <div className="">
            <Dropdown overlayClassName="popoverClassCustom" overlay={menu} trigger={["click"]} className="ReferralDetailsDropdown">
              <button>
                Filter <DownOutlined />
              </button>
            </Dropdown>
            </div>
          ),
          Settlement: <div className="referralpayment"> <button>Settle Payment</button></div>,
        },
      ];
      const columns = [
        {
          title: "User Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Details of Referral",
          dataIndex: "details",
          key: "details",
        },
    
        {
          title: "General Public ",
          dataIndex: "public",
          key: "public",
        },
        {
          title: " Franchise",
          dataIndex: "franchise",
          key: "franchise",
        },
        {
          title: " Premium Franchise",
          dataIndex: "premium",
          key: "premium",
        },
        {
          title: " Master Franchise",
          dataIndex: "mask",
          key: "mask",
        },
        {
          title: "Category of Referee",
          dataIndex: "category",
          key: "catgory",
        },
        {
          title: "Payment Settlement",
          dataIndex: "Settlement",
          key: "Settlement",
        },
      ];
    
      <Table dataSource={dataSource} columns={columns} />;
    
  return (
    <div>
    <div className="breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item>Master Franchise</Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className="commonPadding userDatils ReferralDetails-style">
      <div style={{ marginTop: 15 }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="large"
        />
      </div>
    </div>
  </div>
  )
}

export default MasterFranchise