export const APP_NAME = "Triskel";
export const ETH_IMG =
  "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png";
export const MATIC_IMG =
  "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png";
export const TRON_IMG =
  "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png";
/************************************** Regex ****************************************************/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const NUMBER_REGEX = /^\d*$/;
export const NUMBER_DECIM_REGEX = /^\d*\.?\d*$/;
/*************************************Async Storage Constants******************************* */
export const ACCESS_TOKEN = "AccessToken";
export const UPDATE_TOKEN = "updateToken";
export const REFRESH_TOKEN = "RefreshToken";
export const DEVICE_TOKEN = "DeviceToken";
export const APP_THEME = "appTheme";
export const DEFAULT_KEY = "defaultkey";
export const GOOGLE_AUTH_STATUS = "google_auth_status";
// export const CONTRACT_ADDRESS = "0x5b0E2D62529BF0Bd72cA91B2bEfbA091f5c73aD1";
// export const CONTRACT_ADDRESS = "0x1b3Db57446d3410095A890Ffc5122213AeF81c2A";
// export const CONTRACT_ADDRESS = "0x9172271b98793aeab7e8eD3632C12029b2Fd3c59";
// export const CONTRACT_ADDRESS = "0xD95bE4AaB4557cf9850ca26a714a2d163e86Fbb7";
// export const CONTRACT_ADDRESS = "0x5d6199b63c0e3e1d149d5eE4d573b64062A669DA";
// export const CONTRACT_ADDRESS = "0xe358d1850A39f2733f5c49f26ca9b925bD93b962";
export const CONTRACT_ADDRESS = "0x5611Fd06E483163e26EA906AA527A1DdceDa1fDa";

export const KEY = `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEArirHYtQsM9z+1Tl37rM6TjCduq/1DBSojBBoC4RmzCifNAzL6D4V
R+FvDF9MmGdBPP2PBJsNKL5yMqV5J5Aj8S05dnnTJkKS2LGuB1UMB8uec8XHAFPc
/i5YSlnunwFiB+7LONO72VbKEJoyCOCNIyQvdXdOHdZSucj1Ae0mlFExin3zXMoQ
FxsLdhP0JSuqgMbrAJtRCsiyXCuxIKU61Pg1/BqQhtjdCnzNsBegFpSn7QOK20av
AqPpSXtDt9XpxBq9BHJ6FocqrClQzuHnlQy6DBNqwQx+iyBrHampEgw2ul11dOl0
8PQTaBl/Xb1mPh1z82K66IzEpEumcTbw0wIDAQAB
-----END RSA PUBLIC KEY-----`;
export const CARD_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIICWwIBAAKBgQDrB9OHFU1XnAL7qhFf0ODVVxAvod/ZEzfyobKfXVE2i1dn+fVc
nhlW2kGWnjwjQu3HFGixECBCp6J0xLCuDWNI9gNCod9AJEJHZSvZcaDdJtcPWDLF
LyDpiQdtjUA9gXszgBempVztbpFmY7x1NiimHeU9IGtFn9d1x40fWEL1+wIDAQAB
AoGARhJAQ27bbzpJPWTX5zwsPA7H+ZuGVH7BWGpTCeZVdzCV7577QxCzt0w8773f
ahfjMk5oZBnNtHGsIqodjtbY+W5z9HBmHYXzMlUvWBrxquM5RvotufsvGn/N7h+B
4ZDa2fd4nJOrcLkASBVnzUQB/2lRNABnPLMupqSHw4cjIrkCQQD1dN5ikBxqFrNi
rMIuOIpJV/EIbg2HOSA4YFtEQA1MB32c9Dkp/Ezpe8X9j7KU1wolH1gfJLc89eHF
3BkEcUhdAkEA9SBPW6ru5iugelTisb4QvagSl6m5pgfdPpD0yW0u0s0zEplvz3dD
HCMsRF+I6xe7c6YNcWLk/CgpMlwwQU9yNwJAKcw08mPH8ujaszrn9ib6pXKsQNs8
woIf0nnWENE3cnL0GP4dneCw2DGTjZIivuW/fILR5rvB2EemoqLQP8lqoQJAEGau
CKMiQvJOVYESffQKqLXzMIShsFM8YXBPgJ62kFI8hZUQnyAKtHpB/v0paxl2aGGG
6rxEN/7z6rOyYtxO9wJAWBbFjRR6nqHOPO2qYmS31NrDaVs7h3MEHNNCSQIawbjv
gTVl4J0bSMtPOlFC1x155qKwxeWUJyTsbEJ1SwYgSg==
-----END RSA PRIVATE KEY-----`;
