import React, { useEffect, useState } from "react";
import "./Annoucements.scss";
import { Breadcrumb, Col, Row, Table } from "antd";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CheckboxCustom from "../../Ui/checkbox/CheckboxCustom";
import InputCustom from "../../Ui/input/InputCustom";
import { SearchOutlined } from "@ant-design/icons";
import ModalCommon from "../ModalCommon/ModalCommon";
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getUserListAnnouncement, sendAnnouncement, updateList } from "../../../redux/_actions/announcementsActions";
import Paginate from "../../Ui/Paginate/Paginate";
import Swal from "sweetalert2";

let paginationData = '';
function Annoucements() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);
  const data = [
    { name: "Google account Users", key: 'Google', status: false },
    { name: "Facebook Users", key: 'Facebook', status: false },
    { name: "Email Users", key: 'Email', status: false },
    { name: "Apple Users", key: 'Apple', status: false },
    { name: "LinkedIn Users", key: 'LinkedIn', status: false },
  ];
  const columns1 = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "User Login Type",
      dataIndex: "userLoginType",
      key: "userLoginType",
      // align: 'center'
    },
  ];
  const dispatch = useDispatch();
  const limit = 25;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [list, setList] = useState();
  const [updateRecord, setUpdateRecord] = useState(data);
  const [search, setSearch] = useState('');
  const [isAllSelected, setAllSelected] = useState(false);
  const userList = useSelector((state) => state?.LoginReducer?.myUserList);

  useEffect(() => {
    let category_Arr = [];
    updateRecord.map(item => {
      if (item.status == true) {
        category_Arr.push(item.key)
      }
    })
    category_Arr.length > 0 ? hitSearchApi(category_Arr) : dispatch(updateList({ prop: 'myUserList', value: [] }));
  }, [pageCount])

  /************************************** dataSource1 ****************************************************/
  const dataSource1 = list?.map((item, index) => {
    return {
      key: item.key,
      userId: (<div className="tableIconStyle"><span className="fontFamilyText">{item.email}</span></div>),
      userLoginType: (<div className="tableIconStyle"><span style={{ marginLeft: 25, textTransform: 'capitalize' }} className="fontFamilyText">{item.login_type}</span></div>),
    }
  })
  /************************************** handleCancel ****************************************************/
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /************************************** rowSelection ****************************************************/
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(updateList({ prop: 'myUserList', value: selectedRowKeys }))
    }
  };
  /************************************** rowSelection ****************************************************/
  const publishToAll = () => {
    if (message.trim().length == 0) {
      return toast.error('Enter message')
    } else {
      setLoading(true);
      const data = userList.length > 0 ? { title: title, details: message, user_ids: userList } : { title: title, details: message }
      setTimeout(() => {
        dispatch(sendAnnouncement({ data })).then(res => {
          setLoading(false);
          Swal.fire({
            text: "Announcement sent successfully!",
            icon: "success",
            // confirmButtonColor: "#7D80DF",
            confirmButtonColor: '#d3d3d3',
            confirmButtonText: "Ok",
          }).then(response => {
            dispatch(updateList({ prop: 'myUserList', value: [] }))
            setTitle('');
            setMessage('');
            setList();
            setPageCount('');
          })
        }).catch(err => {
          setLoading(false)
        })
      }, 100);
    }
  }
  /************************************** rowSelection ****************************************************/
  const onChange = (e, index, item) => {
    setSearch('');
    let tempList = [...updateRecord]
    tempList[index].status = e.target.checked
    console.log('chk templist:::::', tempList)
    const newList = tempList.filter(item => item.status == false)
    console.log('chk newList:::::', newList)
    newList ? newList.length == 0 ? setAllSelected(true) : setAllSelected(false) : setAllSelected(false)
    setUpdateRecord(tempList);
  };
  /************************************** publishtoSpecific ****************************************************/
  const publishtoSpecific = () => {
    setAllSelected(false);
    setList()
    setUpdateRecord(data);
    setSearch('');
    setIsModalOpen(true);
  }
  /************************************** onSubmit ****************************************************/
  const onSubmit = () => {
    setPageCount('');
    dispatch(updateList({ prop: 'myUserList', value: [] }))
    paginationData = ''
    let category_Arr = [];
    updateRecord.map(item => {
      if (item.status == true) {
        category_Arr.push(item.key)
      }
    })
    if (search.trim().length == 0 && category_Arr.length == 0) {
      return toast.error('Select a category or search a user')
    } else {
      hitSearchApi(category_Arr)
    }
  }
  /************************************** hitSearchApi ****************************************************/
  const hitSearchApi = (category_Arr) => {
    const data = {
      category: category_Arr,
      search: search,
      page: pageCount || 1,
      limit: limit
    }
    setLoading(true);
    setTimeout(() => {
      dispatch(getUserListAnnouncement({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => (item["key"] = item.user_id));
          setLoading(false);
          paginationData = response?.meta?.pages || 0
          setList(res);
        } else {
          setLoading(false);
          setList();
          paginationData = ''
        }
        setIsModalOpen(false);
      }).catch(err => {
        setLoading(false);
        toast.error(err);
      })
    }, 100);
  }
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /************************************** onSelectAll ****************************************************/
  const onSelectAll = () => {
    console.log('dgdggdgdgd', isAllSelected)
    setSearch('');
    let tempList = [...updateRecord]
    tempList.map((item, index) => {
      item.status = isAllSelected == true ? false : true
    })
    console.log('chk templist:::::', tempList)
    setAllSelected(!isAllSelected)
    setUpdateRecord(tempList);
  }
  const permission = () => {
    if(tab_data){
      let editPermission = tab_data.find(d => d.name === "Announcements");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else{
      return true;
    }
  }
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Announcements</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="annoucements commonPadding">
        <Row>
          <Col xs={24}>
            <div className="annoucements_block">
              {/* ----------------------------------------------------------- */}
              <div className="loginScreen__innerInput">
                <InputCustom placeholder={'Enter Title'} maxLength={50} basicInput label="Title" inputCustum value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
              <div className="loginScreen__innerInput">
                <InputCustom placeholder={'Enter Message'} maxLength={500} textInput label="Message *" inputCustum value={message} onChange={(e) => setMessage(e.target.value)} />
              </div>
              {/* ----------------------------------------------------------- */}
              <ButtonCustom disabled={!permission()} label={userList.length > 0 ? "Publish" : "Publish  to all"} yellowBtn onClick={() => publishToAll()} />
              {/* ----------------------------------------------------------- */}
              <ButtonCustom disabled={!permission()} style={{ marginLeft: 15 }} label="Add Specific User" yellowBtn onClick={() => publishtoSpecific()} />
              {/* ----------------------------------------------------------- */}
            </div>
          </Col>
          {/* ----------------------------------------------------------- */}
          <ModalCommon headTittle={"App Notifications to Specific Users"} isModalVisible={isModalOpen} handleCancel={handleCancel}>
            <div className="modalApp">
              <div className="annoucements_block">
                <Row gutter={24}>
                  {/* ----------------------------------------------------------- */}
                  <Col xs={24} md={24}>
                    <div className="annoucements_block_multiData">
                      <h3 className="fontFamilyText" style={{ marginTop: 15 }}>Search using Email </h3>
                      <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={search} onChange={(e) => { setSearch(e.target.value); setUpdateRecord(data); setAllSelected(false) }} />
                    </div>
                  </Col>
                  {/* ----------------------------------------------------------- */}
                  <Col xs={24} md={24} className="mt20">
                    <div className="annoucements_block_multiData">
                      <div className="annoucements_block_checkbox">
                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                          <h3 className="fontFamilyText" style={{ marginBottom: 0, lineHeight: "32px" }}>Send to a Category </h3>
                          {/* <div onClick={() => onSelectAll()}> */}
                          <ButtonCustom style={{ marginTop: 0, width: "fit-content" }} label={isAllSelected == true ? 'Deselect All' : 'Select All'} yellowBtn onClick={() => onSelectAll()} />
                          {/* <span className={'textedit'}>{isAllSelected == true ? 'Deselect All' : 'Select All'}</span> */}
                          {/* </div> */}
                        </div>

                        <div className="annoucements_block_checkboxCard">
                          {updateRecord.map((ele, index) => (
                            <CheckboxCustom checked={ele.status} onChange={(e) => onChange(e, index, ele)} key={index} checkbox checkboxTxt={ele.name} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* ----------------------------------------------------------- */}
                </Row>
                <div style={{ display: 'flex', justifyContent: "center", marginTop: 15 }}><ButtonCustom label="Submit List" yellowBtn onClick={() => onSubmit()} /></div>
              </div>
            </div>
          </ModalCommon>
          {/* ----------------------------------------------------------- */}
        </Row>
        {list && <div style={{ marginTop: 50 }}>
          <Table
            rowSelection={{ ...rowSelection, preserveSelectedRowKeys: true }}
            dataSource={dataSource1}
            columns={columns1}
            pagination={false}
            size="large"
            hoverable={false}
          />
        </div>
        }
        <Loader isLoading={isLoading} />
        {(paginationData > 1 && list) && <Paginate paginationData={paginationData} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      </div>
    </>
  );
}

export default Annoucements;
