export function SpotOrderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25188"
        data-name="Group 25188"
        transform="translate(-7333 -15592)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(7333 15592)"
          fill="none"
        />
        <g
          id="Group_25186"
          data-name="Group 25186"
          transform="translate(5024.521 13197.166)"
        >
          <path
            id="Path_45810"
            data-name="Path 45810"
            d="M2328.638,2401.955v2.545q-2.227,0-4.454,0a2.882,2.882,0,0,0-.649.043,2.366,2.366,0,0,0-1.838,1.652,2.335,2.335,0,0,0,.512,2.385,2.413,2.413,0,0,0,1.909.808c1.507-.009,3.014,0,4.521,0v2.545a2.6,2.6,0,0,1-2.712,2.133c-3.94-.034-7.881-.011-11.821-.012a3.733,3.733,0,0,1-.5-.029,2.48,2.48,0,0,1-2.124-2.411q0-4.663,0-9.326a2.474,2.474,0,0,1,2.44-2.453q6.145-.01,12.29,0a2.328,2.328,0,0,1,1.2.333A2.525,2.525,0,0,1,2328.638,2401.955Z"
            transform="translate(0 0)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45811"
            data-name="Path 45811"
            d="M2580.721,2533.305v3.014l-4.148.006a5.168,5.168,0,0,1-.617-.024,1.5,1.5,0,0,1-1.026-2.418,1.569,1.569,0,0,1,1.325-.585q1.656,0,3.312,0C2579.952,2533.3,2580.336,2533.3,2580.721,2533.305Zm-4.277,1.047c-.128,0-.257-.008-.385,0a.444.444,0,0,0-.418.457.44.44,0,0,0,.416.458c.25.012.5.011.753,0a.44.44,0,0,0,.418-.456.445.445,0,0,0-.416-.46C2576.69,2534.345,2576.567,2534.353,2576.444,2534.353Z"
            transform="translate(-252.083 -127.867)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function MarketsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25240"
        data-name="Group 25240"
        transform="translate(-14214 6882)"
      >
        <path
          id="Path_45869"
          data-name="Path 45869"
          d="M0,0H24V24H0Z"
          transform="translate(14214 -6882)"
          fill="none"
        />
        <g
          id="Group_25239"
          data-name="Group 25239"
          transform="translate(14215 -6881)"
        >
          <path
            id="Path_45867"
            data-name="Path 45867"
            d="M2,10.716A8.716,8.716,0,0,1,10.716,2v8.716l6.163,6.163A8.716,8.716,0,0,1,2,10.716Z"
            fill="#fff"
          />
          <path
            id="Path_45868"
            data-name="Path 45868"
            d="M18.245,4.929A8.717,8.717,0,0,1,18.8,16.616l-6.159-5.906Z"
            transform="translate(-1.366 -0.376)"
            fill="#76808f"
            opacity="0.35"
          />
        </g>
      </g>
    </svg>
  );
}

export function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25188"
        data-name="Group 25188"
        transform="translate(-7333 -15592)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(7333 15592)"
          fill="none"
        />
        <g
          id="Group_25186"
          data-name="Group 25186"
          transform="translate(5024.521 13197.166)"
        >
          <path
            id="Path_45810"
            data-name="Path 45810"
            d="M2328.638,2401.955v2.545q-2.227,0-4.454,0a2.882,2.882,0,0,0-.649.043,2.366,2.366,0,0,0-1.838,1.652,2.335,2.335,0,0,0,.512,2.385,2.413,2.413,0,0,0,1.909.808c1.507-.009,3.014,0,4.521,0v2.545a2.6,2.6,0,0,1-2.712,2.133c-3.94-.034-7.881-.011-11.821-.012a3.733,3.733,0,0,1-.5-.029,2.48,2.48,0,0,1-2.124-2.411q0-4.663,0-9.326a2.474,2.474,0,0,1,2.44-2.453q6.145-.01,12.29,0a2.328,2.328,0,0,1,1.2.333A2.525,2.525,0,0,1,2328.638,2401.955Z"
            transform="translate(0 0)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45811"
            data-name="Path 45811"
            d="M2580.721,2533.305v3.014l-4.148.006a5.168,5.168,0,0,1-.617-.024,1.5,1.5,0,0,1-1.026-2.418,1.569,1.569,0,0,1,1.325-.585q1.656,0,3.312,0C2579.952,2533.3,2580.336,2533.3,2580.721,2533.305Zm-4.277,1.047c-.128,0-.257-.008-.385,0a.444.444,0,0,0-.418.457.44.44,0,0,0,.416.458c.25.012.5.011.753,0a.44.44,0,0,0,.418-.456.445.445,0,0,0-.416-.46C2576.69,2534.345,2576.567,2534.353,2576.444,2534.353Z"
            transform="translate(-252.083 -127.867)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function DepositIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25370"
        data-name="Group 25370"
        transform="translate(-154 -218)"
      >
        <g
          id="Group_25200"
          data-name="Group 25200"
          transform="translate(-7179 -15374)"
        >
          <rect
            id="Rectangle_8234"
            data-name="Rectangle 8234"
            width="24"
            height="24"
            transform="translate(7333 15592)"
            fill="none"
          />
        </g>
        <g
          id="Group_25280"
          data-name="Group 25280"
          transform="translate(220.192 2142.771)"
        >
          <path
            id="Path_45892"
            data-name="Path 45892"
            d="M-62.192-1710.131v-8.539h15.966v8.539Zm3.193-6.946c0,.18,0,.34,0,.5a.535.535,0,0,1-.56.556c-.164,0-.327,0-.492,0v3.193h.453a.544.544,0,0,1,.6.606c0,.151,0,.3,0,.45h9.58c0-.172,0-.336,0-.5a.534.534,0,0,1,.56-.556c.163,0,.327,0,.492,0v-3.193c-.165,0-.318,0-.471,0a.54.54,0,0,1-.581-.588c0-.157,0-.313,0-.467Z"
            transform="translate(0 -193.474)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45893"
            data-name="Path 45893"
            d="M52.6-1921.771l2.657,3.189H53.674v5.35H51.546v-5.337h-1.61Z"
            transform="translate(-106.813)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45894"
            data-name="Path 45894"
            d="M-41.744-1835.168l1.768,1.771-.744.741-.454-.485-.037.024v2.38h-1.067v-2.424l-.493.531-.756-.756Z"
            transform="translate(-17.781 -82.498)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45895"
            data-name="Path 45895"
            d="M182.757-1830.152v-2.406l-.466.5-.75-.744,1.756-1.755,1.761,1.76-.738.736-.491-.522v2.429Z"
            transform="translate(-232.18 -83.081)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45896"
            data-name="Path 45896"
            d="M74.157-1662.768a1.6,1.6,0,0,1,1.591,1.61,1.6,1.6,0,0,1-1.6,1.582,1.6,1.6,0,0,1-1.591-1.61A1.6,1.6,0,0,1,74.157-1662.768Zm.519,1.076H73.633v1.043h1.042Z"
            transform="translate(-128.36 -246.727)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45897"
            data-name="Path 45897"
            d="M7.8-1659.592c-.549,0-1.069,0-1.589,0a.19.19,0,0,1-.138-.094,1.644,1.644,0,0,0-.831-.835.208.208,0,0,1-.1-.151q-.013-.514,0-1.028a.209.209,0,0,1,.1-.149,1.648,1.648,0,0,0,.821-.822.2.2,0,0,1,.131-.1c.525-.007,1.05,0,1.6,0A2.659,2.659,0,0,0,7.8-1659.592Z"
            transform="translate(-64.133 -246.71)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45898"
            data-name="Path 45898"
            d="M151.112-1659.58a2.664,2.664,0,0,0,0-3.192c.557,0,1.092,0,1.627,0,.035,0,.082.057.1.1a1.637,1.637,0,0,0,.832.833.208.208,0,0,1,.1.154c.009.337,0,.674.007,1.01a.156.156,0,0,1-.113.169,1.577,1.577,0,0,0-.821.818c-.02.044-.076.1-.116.1C152.2-1659.578,151.667-1659.58,151.112-1659.58Z"
            transform="translate(-203.194 -246.722)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function WithdrawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25371"
        data-name="Group 25371"
        transform="translate(-160 -289)"
      >
        <rect
          id="Rectangle_8236"
          data-name="Rectangle 8236"
          width="24"
          height="24"
          transform="translate(160 289)"
          fill="none"
        />
        <g
          id="Group_25281"
          data-name="Group 25281"
          transform="translate(-352.074 2305.197)"
        >
          <path
            id="Path_45899"
            data-name="Path 45899"
            d="M570.38-1938.414h9.964v.207q0,4.514,0,9.028a1.87,1.87,0,0,1-1.965,1.968h-6.033a1.87,1.87,0,0,1-1.965-1.968q0-4.514,0-9.028Zm6.2,4.073a.689.689,0,0,0,.612-.439.582.582,0,0,0-.22-.705,5.461,5.461,0,0,0-.822-.386.247.247,0,0,1-.188-.2.6.6,0,0,0-.53-.473.529.529,0,0,0-.595.311.657.657,0,0,1-.485.444.97.97,0,0,0-.217.128,1.463,1.463,0,0,0,.2,2.544,8.234,8.234,0,0,0,.789.267c.228.073.463.125.686.209a.211.211,0,0,1,.055.4.781.781,0,0,1-.378.125,1.741,1.741,0,0,1-1.035-.272.62.62,0,0,0-.867.191.606.606,0,0,0,.215.86,4.292,4.292,0,0,0,.724.322c.132.047.211.09.245.243a.6.6,0,0,0,.564.454.574.574,0,0,0,.592-.386c.049-.2.164-.253.326-.317a1.44,1.44,0,0,0,.974-1.387,1.317,1.317,0,0,0-.9-1.36,7.27,7.27,0,0,0-.756-.245c-.222-.069-.45-.123-.668-.2a.207.207,0,0,1-.065-.385.78.78,0,0,1,.39-.142,1.618,1.618,0,0,1,.982.247A3.231,3.231,0,0,0,576.58-1934.341Z"
            transform="translate(-51.569 -68.797)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45900"
            data-name="Path 45900"
            d="M532.511-2008.467H515.088c0-.054-.013-.1-.013-.153,0-.557,0-1.115,0-1.673a1.862,1.862,0,0,1,1.91-1.9q6.808,0,13.616,0a1.861,1.861,0,0,1,1.909,1.9C532.515-2009.689,532.511-2009.088,532.511-2008.467Zm-3.739-1.24c.214,0,.428.006.642,0a.622.622,0,0,0,.605-.613.62.62,0,0,0-.611-.627q-.632-.012-1.264,0a.617.617,0,0,0-.615.623.624.624,0,0,0,.62.619C528.358-2009.7,528.565-2009.706,528.773-2009.707Z"
            transform="translate(0 0)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45901"
            data-name="Path 45901"
            d="M517.712-1938.319v3.7a1.984,1.984,0,0,1-2.423-1.385.953.953,0,0,1-.047-.248c0-.685,0-1.371,0-2.066Z"
            transform="translate(-0.154 -68.886)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45902"
            data-name="Path 45902"
            d="M738.939-1938.263c0,.739.055,1.468-.015,2.186a1.788,1.788,0,0,1-1.711,1.531c-.233.011-.467,0-.716,0v-3.719Z"
            transform="translate(-206.461 -68.938)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function BuysellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25196"
        data-name="Group 25196"
        transform="translate(-7397 -15663)"
      >
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(7397 15663)"
          fill="none"
        />
        <g
          id="Group_25195"
          data-name="Group 25195"
          transform="translate(5321.147 12623.25)"
        >
          <path
            id="Path_45815"
            data-name="Path 45815"
            d="M2185.027,3042.75a5.87,5.87,0,0,1,1.486.4,5.445,5.445,0,0,1,1.6,8.946,5.123,5.123,0,0,1-3.02,1.428,5.421,5.421,0,0,1-1.433-10.744l.2-.032Zm.115,6.426a.769.769,0,0,1-.757.773,1.578,1.578,0,0,1-.98-.245.482.482,0,0,0-.677.155.5.5,0,0,0,.148.669,2.068,2.068,0,0,0,.811.359c.126.021.184.068.187.209a.49.49,0,0,0,.5.514.475.475,0,0,0,.471-.521.262.262,0,0,1,.2-.287,1.673,1.673,0,0,0-.126-3.191,4.977,4.977,0,0,1-.954-.426.372.372,0,0,1-.18-.426.517.517,0,0,1,.444-.44,1.018,1.018,0,0,1,.768.162.49.49,0,0,0,.549-.812,1.457,1.457,0,0,0-.5-.254.226.226,0,0,1-.2-.249.473.473,0,0,0-.479-.479.482.482,0,0,0-.49.475c0,.188-.084.249-.232.32a1.438,1.438,0,0,0-.842,1.21,1.289,1.289,0,0,0,.682,1.352c.114.065.229.129.347.188.292.148.6.247.906.369A.558.558,0,0,1,2185.141,3049.176Z"
            transform="translate(-95.612)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45816"
            data-name="Path 45816"
            d="M2138.191,3285.886c0-1.639-.006-3.277,0-4.916a1.683,1.683,0,0,1,1.255-1.636,4.66,4.66,0,0,1,2.837.01,12.612,12.612,0,0,1,1.274.558,3.447,3.447,0,0,0,1.69.375c.417-.022.836,0,1.254,0,.456,0,.642.217.646.668a1.371,1.371,0,0,1-1.478,1.411c-.968-.013-1.937,0-2.9,0-.346,0-.57.187-.574.476a.521.521,0,0,0,.575.5c.985,0,1.971,0,2.956,0a1.391,1.391,0,0,0,1.034-.424q1.055-1.061,2.115-2.115a2.475,2.475,0,0,1,1.871-.76,2.442,2.442,0,0,1,1.658.691.535.535,0,0,1,.016.821q-1.856,1.863-3.719,3.719a2.37,2.37,0,0,1-1.223.622Z"
            transform="translate(-56.615 -225.533)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45817"
            data-name="Path 45817"
            d="M2079.238,3320.645a.6.6,0,0,1-.385-.609c0-1.283,0-2.566,0-3.849a.568.568,0,0,1,.635-.639c.315,0,.63,0,.945,0,.106,0,.156.037.156.15q0,2.457,0,4.914a.159.159,0,0,1-.007.034Z"
            transform="translate(0 -260.291)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function TransactionHistoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25244"
        data-name="Group 25244"
        transform="translate(-14198 6574)"
      >
        <path
          id="Path_45870"
          data-name="Path 45870"
          d="M16.3,16.295a6.337,6.337,0,0,1-4.5,1.862H10.582V20.6H11.8A8.8,8.8,0,1,0,5.58,5.576L7.309,7.305a6.357,6.357,0,0,1,8.991,8.99Zm-3.273-8.4H10.582v4.418L13.7,15.423l1.729-1.729-2.4-2.4ZM5.572,9.423V10.6l-.09.006a2.9,2.9,0,0,0-1.744.647,1.968,1.968,0,0,0-.625,1.53,2.13,2.13,0,0,0,.661,1.606,4.017,4.017,0,0,0,1.847.9l.074.019v1.859l-.12-.028a3.853,3.853,0,0,1-.82-.3,2.457,2.457,0,0,1-.583-.391L3.139,17.606a4.855,4.855,0,0,0,2.218.953l.078.016v1.249H6.979v-1.18l.084-.011a2.535,2.535,0,0,0,1.68-.871,2.4,2.4,0,0,0,.52-1.528A1.993,1.993,0,0,0,8.61,14.7a4.121,4.121,0,0,0-1.733-.855l-.072-.02V12.063l.122.032A2.748,2.748,0,0,1,8,12.641L9.014,11.53a3.924,3.924,0,0,0-1.834-.842L7.1,10.671V9.423H5.573ZM5.757,13.6l-.134-.055a1.244,1.244,0,0,1-.474-.3A.586.586,0,0,1,5,12.841a.757.757,0,0,1,.634-.771l.12-.029V13.6Zm1.034,3.6V15.5l.144.078a.864.864,0,0,1,.487.811.88.88,0,0,1-.094.42.728.728,0,0,1-.4.335l-.132.049Z"
          transform="translate(14197.887 -6573.998)"
          fill="#fff"
          fillRule="evenodd"
        />
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(14198 -6574)"
          fill="none"
        />
      </g>
    </svg>
  );
}

export function BuyCryptoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_27767"
        data-name="Group 27767"
        transform="translate(0.301 -1.3)"
      >
        <path
          id="Path_46122"
          data-name="Path 46122"
          d="M13.5,3.3H5.7V20.8H18.2V7.9L13.5,3.3Zm2.7,15.5H7.7V5.3h5V8.8h3.5Z"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_8939"
          data-name="Rectangle 8939"
          width="24"
          height="24"
          transform="translate(-0.301 1.3)"
          fill="none"
        />
      </g>
    </svg>
  );
}

export function ConvertHistoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25193"
        data-name="Group 25193"
        transform="translate(-7333 -15540)"
      >
        <rect
          id="Rectangle_8232"
          data-name="Rectangle 8232"
          width="24"
          height="24"
          transform="translate(7333 15540)"
          fill="none"
        />
        <g
          id="Group_25185"
          data-name="Group 25185"
          transform="translate(5656.787 13037.553)"
        >
          <path
            id="Path_45805"
            data-name="Path 45805"
            d="M1879.71,2655.29q0,1.926,0,3.853a1.208,1.208,0,0,1-1.257,1.256h-5.359a1.209,1.209,0,0,1-1.253-1.259q0-3.8,0-7.6a1.208,1.208,0,0,1,1.243-1.251h5.482a1.108,1.108,0,0,1,1.144,1.15Q1879.711,2653.364,1879.71,2655.29Zm-3.942-1.641c.513,0,1.027,0,1.54,0a1.123,1.123,0,0,0,.294-.035.531.531,0,0,0,.379-.606.56.56,0,0,0-.615-.455q-1.592,0-3.185,0a.945.945,0,0,0-.258.04.534.534,0,0,0-.356.59.561.561,0,0,0,.609.464C1874.706,2653.652,1875.237,2653.649,1875.767,2653.649Zm-.011,1.681h1.487a1.351,1.351,0,0,0,.347-.031.532.532,0,0,0,.388-.615.57.57,0,0,0-.638-.449q-1.566,0-3.133,0a1.086,1.086,0,0,0-.276.037.529.529,0,0,0-.365.6.563.563,0,0,0,.614.457C1874.707,2655.333,1875.232,2655.33,1875.757,2655.33Z"
            transform="translate(-182.66 -137.108)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45806"
            data-name="Path 45806"
            d="M1680.213,2511.575q0-1.9,0-3.8a1.209,1.209,0,0,1,1.257-1.255c.531,0,1.063,0,1.594,0,.156,0,.2.046.148.193a1.42,1.42,0,0,0-.041.469c0,.636.012,1.273,0,1.909a2.219,2.219,0,0,0,2.238,2.231c.642,0,1.284,0,1.926,0a2.2,2.2,0,0,0,.606-.069c.106-.03.152,0,.147.113-.005.134,0,.269,0,.4q0,1.812,0,3.625a1.207,1.207,0,0,1-1.238,1.239h-5.393a1.208,1.208,0,0,1-1.238-1.239Q1680.212,2513.484,1680.213,2511.575Z"
            transform="translate(0 -0.067)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45807"
            data-name="Path 45807"
            d="M1876.24,2545a.742.742,0,0,1,.55-.293.547.547,0,0,1,.52.322.524.524,0,0,1-.062.609c-.388.412-.785.816-1.2,1.2a.573.573,0,0,1-.834-.059q-.532-.52-1.052-1.052a.585.585,0,1,1,.817-.836l.118.106a3.155,3.155,0,0,0-.07-1.061.573.573,0,0,0-.594-.32c-.718,0-1.436,0-2.154,0a.554.554,0,0,1-.534-.425.581.581,0,0,1,.27-.648.558.558,0,0,1,.285-.071c.788,0,1.576-.005,2.364,0a1.676,1.676,0,0,1,1.575,1.585C1876.244,2544.354,1876.24,2544.651,1876.24,2545Z"
            transform="translate(-182.554 -34.335)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45808"
            data-name="Path 45808"
            d="M1729.01,2735.974a.81.81,0,0,1-.54.281.577.577,0,0,1-.462-.938c.119-.143.257-.27.389-.4.239-.24.476-.481.718-.718a.606.606,0,0,1,.929,0q.525.515,1.04,1.04a.587.587,0,1,1-.818.838l-.108-.1a3.922,3.922,0,0,0,.044,1.013.575.575,0,0,0,.6.36c.718,0,1.436,0,2.153,0a.555.555,0,0,1,.543.413.581.581,0,0,1-.242.644.535.535,0,0,1-.3.087c-.794,0-1.587.006-2.381,0a1.676,1.676,0,0,1-1.57-1.573C1729.005,2736.615,1729.01,2736.314,1729.01,2735.974Z"
            transform="translate(-45.433 -216.884)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45809"
            data-name="Path 45809"
            d="M1766.28,2508.056c0-.321,0-.642,0-.963a.572.572,0,0,1,.347-.593.589.589,0,0,1,.687.153q.8.77,1.608,1.534c.321.306.64.614.965.915a.609.609,0,0,1,.211.659.543.543,0,0,1-.55.406c-.531.011-1.061.011-1.592.01a4.8,4.8,0,0,1-.716-.019,1.13,1.13,0,0,1-.958-1.086C1766.277,2508.732,1766.28,2508.394,1766.28,2508.056Z"
            transform="translate(-82.038)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function EarnRewardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25246"
        data-name="Group 25246"
        transform="translate(-14135 6506)"
      >
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(14135 -6506)"
          fill="none"
        />
        <g
          id="Group_25245"
          data-name="Group 25245"
          transform="translate(14307.256 -5360.777)"
        >
          <path
            id="Path_45871"
            data-name="Path 45871"
            d="M-160.7-1129.106a6.561,6.561,0,0,1-6.557-6.582,6.563,6.563,0,0,1,6.551-6.534,6.563,6.563,0,0,1,6.575,6.583A6.561,6.561,0,0,1-160.7-1129.106Zm-.013-1.1a5.467,5.467,0,0,0,5.475-5.455,5.459,5.459,0,0,0-5.428-5.463,5.46,5.46,0,0,0-5.483,5.426A5.461,5.461,0,0,0-160.713-1130.2Z"
            transform="translate(0 0)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45872"
            data-name="Path 45872"
            d="M-16.553-876.326a7.387,7.387,0,0,0,3.345-1.167l1.679,3.18c-.079,0-.135,0-.19,0-.589-.052-1.179-.1-1.768-.161a.172.172,0,0,0-.206.114c-.278.582-.561,1.161-.863,1.783Z"
            transform="translate(-143.48 -252.041)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45873"
            data-name="Path 45873"
            d="M-146.472-877.133a7.382,7.382,0,0,0,3.352,1.15l-1.99,3.73c-.035-.056-.062-.09-.081-.129-.262-.536-.526-1.072-.78-1.612a.2.2,0,0,0-.243-.137c-.57.061-1.142.107-1.713.158-.064.005-.128,0-.219,0Z"
            transform="translate(-18.195 -252.384)"
            fill="#f5f5f5"
          />
          <path
            id="Path_45874"
            data-name="Path 45874"
            d="M-123.557-1093.829a4.691,4.691,0,0,1-4.7-4.7,4.693,4.693,0,0,1,4.678-4.681,4.7,4.7,0,0,1,4.71,4.7A4.69,4.69,0,0,1-123.557-1093.829Zm-3.075-5.536-.011.043c.047.036.094.072.141.107.542.393,1.082.788,1.628,1.176a.173.173,0,0,1,.076.236c-.218.638-.428,1.279-.641,1.918-.019.056-.033.114-.049.17l.037.016,1.894-1.369,1.9,1.374a.186.186,0,0,0,0-.128c-.21-.654-.416-1.308-.636-1.958a.2.2,0,0,1,.092-.276c.541-.384,1.076-.775,1.613-1.165.048-.035.093-.075.178-.144h-.253c-.658,0-1.315,0-1.973,0a.2.2,0,0,1-.223-.166c-.2-.643-.418-1.282-.63-1.922-.017-.051-.039-.1-.075-.188-.037.1-.058.156-.076.211-.2.624-.413,1.246-.61,1.873a.226.226,0,0,1-.26.194c-.651-.01-1.3,0-1.955,0Z"
            transform="translate(-37.13 -37.148)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}

export function SpotOrderLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25188"
        data-name="Group 25188"
        transform="translate(-7333 -15592)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(7333 15592)"
          fill="none"
        />
        <g
          id="Group_25186"
          data-name="Group 25186"
          transform="translate(5024.521 13197.166)"
        >
          <path
            id="Path_45810"
            data-name="Path 45810"
            d="M2328.638,2401.955v2.545q-2.227,0-4.454,0a2.882,2.882,0,0,0-.649.043,2.366,2.366,0,0,0-1.838,1.652,2.335,2.335,0,0,0,.512,2.385,2.413,2.413,0,0,0,1.909.808c1.507-.009,3.014,0,4.521,0v2.545a2.6,2.6,0,0,1-2.712,2.133c-3.94-.034-7.881-.011-11.821-.012a3.733,3.733,0,0,1-.5-.029,2.48,2.48,0,0,1-2.124-2.411q0-4.663,0-9.326a2.474,2.474,0,0,1,2.44-2.453q6.145-.01,12.29,0a2.328,2.328,0,0,1,1.2.333A2.525,2.525,0,0,1,2328.638,2401.955Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_45811"
            data-name="Path 45811"
            d="M2580.721,2533.305v3.014l-4.148.006a5.168,5.168,0,0,1-.617-.024,1.5,1.5,0,0,1-1.026-2.418,1.569,1.569,0,0,1,1.325-.585q1.656,0,3.312,0C2579.952,2533.3,2580.336,2533.3,2580.721,2533.305Zm-4.277,1.047c-.128,0-.257-.008-.385,0a.444.444,0,0,0-.418.457.44.44,0,0,0,.416.458c.25.012.5.011.753,0a.44.44,0,0,0,.418-.456.445.445,0,0,0-.416-.46C2576.69,2534.345,2576.567,2534.353,2576.444,2534.353Z"
            transform="translate(-252.083 -127.867)"
          />
        </g>
      </g>
    </svg>
  );
}

export function MarketsIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25240"
        data-name="Group 25240"
        transform="translate(-14214 6882)"
      >
        <path
          id="Path_45869"
          data-name="Path 45869"
          d="M0,0H24V24H0Z"
          transform="translate(14214 -6882)"
          fill="none"
        />
        <g
          id="Group_25239"
          data-name="Group 25239"
          transform="translate(14215 -6881)"
        >
          <path
            id="Path_45867"
            data-name="Path 45867"
            d="M2,10.716A8.716,8.716,0,0,1,10.716,2v8.716l6.163,6.163A8.716,8.716,0,0,1,2,10.716Z"
          />
          <path
            id="Path_45868"
            data-name="Path 45868"
            d="M18.245,4.929A8.717,8.717,0,0,1,18.8,16.616l-6.159-5.906Z"
            transform="translate(-1.366 -0.376)"
            fill="#76808f"
            opacity="0.35"
          />
        </g>
      </g>
    </svg>
  );
}

export function WalletIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25188"
        data-name="Group 25188"
        transform="translate(-7333 -15592)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(7333 15592)"
          fill="none"
        />
        <g
          id="Group_25186"
          data-name="Group 25186"
          transform="translate(5024.521 13197.166)"
        >
          <path
            id="Path_45810"
            data-name="Path 45810"
            d="M2328.638,2401.955v2.545q-2.227,0-4.454,0a2.882,2.882,0,0,0-.649.043,2.366,2.366,0,0,0-1.838,1.652,2.335,2.335,0,0,0,.512,2.385,2.413,2.413,0,0,0,1.909.808c1.507-.009,3.014,0,4.521,0v2.545a2.6,2.6,0,0,1-2.712,2.133c-3.94-.034-7.881-.011-11.821-.012a3.733,3.733,0,0,1-.5-.029,2.48,2.48,0,0,1-2.124-2.411q0-4.663,0-9.326a2.474,2.474,0,0,1,2.44-2.453q6.145-.01,12.29,0a2.328,2.328,0,0,1,1.2.333A2.525,2.525,0,0,1,2328.638,2401.955Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_45811"
            data-name="Path 45811"
            d="M2580.721,2533.305v3.014l-4.148.006a5.168,5.168,0,0,1-.617-.024,1.5,1.5,0,0,1-1.026-2.418,1.569,1.569,0,0,1,1.325-.585q1.656,0,3.312,0C2579.952,2533.3,2580.336,2533.3,2580.721,2533.305Zm-4.277,1.047c-.128,0-.257-.008-.385,0a.444.444,0,0,0-.418.457.44.44,0,0,0,.416.458c.25.012.5.011.753,0a.44.44,0,0,0,.418-.456.445.445,0,0,0-.416-.46C2576.69,2534.345,2576.567,2534.353,2576.444,2534.353Z"
            transform="translate(-252.083 -127.867)"
          />
        </g>
      </g>
    </svg>
  );
}

export function DepositIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25370"
        data-name="Group 25370"
        transform="translate(-154 -218)"
      >
        <g
          id="Group_25200"
          data-name="Group 25200"
          transform="translate(-7179 -15374)"
        >
          <rect
            id="Rectangle_8234"
            data-name="Rectangle 8234"
            width="24"
            height="24"
            transform="translate(7333 15592)"
            fill="none"
          />
        </g>
        <g
          id="Group_25280"
          data-name="Group 25280"
          transform="translate(220.192 2142.771)"
        >
          <path
            id="Path_45892"
            data-name="Path 45892"
            d="M-62.192-1710.131v-8.539h15.966v8.539Zm3.193-6.946c0,.18,0,.34,0,.5a.535.535,0,0,1-.56.556c-.164,0-.327,0-.492,0v3.193h.453a.544.544,0,0,1,.6.606c0,.151,0,.3,0,.45h9.58c0-.172,0-.336,0-.5a.534.534,0,0,1,.56-.556c.163,0,.327,0,.492,0v-3.193c-.165,0-.318,0-.471,0a.54.54,0,0,1-.581-.588c0-.157,0-.313,0-.467Z"
            transform="translate(0 -193.474)"
          />
          <path
            id="Path_45893"
            data-name="Path 45893"
            d="M52.6-1921.771l2.657,3.189H53.674v5.35H51.546v-5.337h-1.61Z"
            transform="translate(-106.813)"
          />
          <path
            id="Path_45894"
            data-name="Path 45894"
            d="M-41.744-1835.168l1.768,1.771-.744.741-.454-.485-.037.024v2.38h-1.067v-2.424l-.493.531-.756-.756Z"
            transform="translate(-17.781 -82.498)"
          />
          <path
            id="Path_45895"
            data-name="Path 45895"
            d="M182.757-1830.152v-2.406l-.466.5-.75-.744,1.756-1.755,1.761,1.76-.738.736-.491-.522v2.429Z"
            transform="translate(-232.18 -83.081)"
          />
          <path
            id="Path_45896"
            data-name="Path 45896"
            d="M74.157-1662.768a1.6,1.6,0,0,1,1.591,1.61,1.6,1.6,0,0,1-1.6,1.582,1.6,1.6,0,0,1-1.591-1.61A1.6,1.6,0,0,1,74.157-1662.768Zm.519,1.076H73.633v1.043h1.042Z"
            transform="translate(-128.36 -246.727)"
          />
          <path
            id="Path_45897"
            data-name="Path 45897"
            d="M7.8-1659.592c-.549,0-1.069,0-1.589,0a.19.19,0,0,1-.138-.094,1.644,1.644,0,0,0-.831-.835.208.208,0,0,1-.1-.151q-.013-.514,0-1.028a.209.209,0,0,1,.1-.149,1.648,1.648,0,0,0,.821-.822.2.2,0,0,1,.131-.1c.525-.007,1.05,0,1.6,0A2.659,2.659,0,0,0,7.8-1659.592Z"
            transform="translate(-64.133 -246.71)"
          />
          <path
            id="Path_45898"
            data-name="Path 45898"
            d="M151.112-1659.58a2.664,2.664,0,0,0,0-3.192c.557,0,1.092,0,1.627,0,.035,0,.082.057.1.1a1.637,1.637,0,0,0,.832.833.208.208,0,0,1,.1.154c.009.337,0,.674.007,1.01a.156.156,0,0,1-.113.169,1.577,1.577,0,0,0-.821.818c-.02.044-.076.1-.116.1C152.2-1659.578,151.667-1659.58,151.112-1659.58Z"
            transform="translate(-203.194 -246.722)"
          />
        </g>
      </g>
    </svg>
  );
}

export function WithdrawIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25371"
        data-name="Group 25371"
        transform="translate(-160 -289)"
      >
        <rect
          id="Rectangle_8236"
          data-name="Rectangle 8236"
          width="24"
          height="24"
          transform="translate(160 289)"
          fill="none"
        />
        <g
          id="Group_25281"
          data-name="Group 25281"
          transform="translate(-352.074 2305.197)"
        >
          <path
            id="Path_45899"
            data-name="Path 45899"
            d="M570.38-1938.414h9.964v.207q0,4.514,0,9.028a1.87,1.87,0,0,1-1.965,1.968h-6.033a1.87,1.87,0,0,1-1.965-1.968q0-4.514,0-9.028Zm6.2,4.073a.689.689,0,0,0,.612-.439.582.582,0,0,0-.22-.705,5.461,5.461,0,0,0-.822-.386.247.247,0,0,1-.188-.2.6.6,0,0,0-.53-.473.529.529,0,0,0-.595.311.657.657,0,0,1-.485.444.97.97,0,0,0-.217.128,1.463,1.463,0,0,0,.2,2.544,8.234,8.234,0,0,0,.789.267c.228.073.463.125.686.209a.211.211,0,0,1,.055.4.781.781,0,0,1-.378.125,1.741,1.741,0,0,1-1.035-.272.62.62,0,0,0-.867.191.606.606,0,0,0,.215.86,4.292,4.292,0,0,0,.724.322c.132.047.211.09.245.243a.6.6,0,0,0,.564.454.574.574,0,0,0,.592-.386c.049-.2.164-.253.326-.317a1.44,1.44,0,0,0,.974-1.387,1.317,1.317,0,0,0-.9-1.36,7.27,7.27,0,0,0-.756-.245c-.222-.069-.45-.123-.668-.2a.207.207,0,0,1-.065-.385.78.78,0,0,1,.39-.142,1.618,1.618,0,0,1,.982.247A3.231,3.231,0,0,0,576.58-1934.341Z"
            transform="translate(-51.569 -68.797)"
          />
          <path
            id="Path_45900"
            data-name="Path 45900"
            d="M532.511-2008.467H515.088c0-.054-.013-.1-.013-.153,0-.557,0-1.115,0-1.673a1.862,1.862,0,0,1,1.91-1.9q6.808,0,13.616,0a1.861,1.861,0,0,1,1.909,1.9C532.515-2009.689,532.511-2009.088,532.511-2008.467Zm-3.739-1.24c.214,0,.428.006.642,0a.622.622,0,0,0,.605-.613.62.62,0,0,0-.611-.627q-.632-.012-1.264,0a.617.617,0,0,0-.615.623.624.624,0,0,0,.62.619C528.358-2009.7,528.565-2009.706,528.773-2009.707Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_45901"
            data-name="Path 45901"
            d="M517.712-1938.319v3.7a1.984,1.984,0,0,1-2.423-1.385.953.953,0,0,1-.047-.248c0-.685,0-1.371,0-2.066Z"
            transform="translate(-0.154 -68.886)"
          />
          <path
            id="Path_45902"
            data-name="Path 45902"
            d="M738.939-1938.263c0,.739.055,1.468-.015,2.186a1.788,1.788,0,0,1-1.711,1.531c-.233.011-.467,0-.716,0v-3.719Z"
            transform="translate(-206.461 -68.938)"
          />
        </g>
      </g>
    </svg>
  );
}

export function BuysellIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25196"
        data-name="Group 25196"
        transform="translate(-7397 -15663)"
      >
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(7397 15663)"
          fill="none"
        />
        <g
          id="Group_25195"
          data-name="Group 25195"
          transform="translate(5321.147 12623.25)"
        >
          <path
            id="Path_45815"
            data-name="Path 45815"
            d="M2185.027,3042.75a5.87,5.87,0,0,1,1.486.4,5.445,5.445,0,0,1,1.6,8.946,5.123,5.123,0,0,1-3.02,1.428,5.421,5.421,0,0,1-1.433-10.744l.2-.032Zm.115,6.426a.769.769,0,0,1-.757.773,1.578,1.578,0,0,1-.98-.245.482.482,0,0,0-.677.155.5.5,0,0,0,.148.669,2.068,2.068,0,0,0,.811.359c.126.021.184.068.187.209a.49.49,0,0,0,.5.514.475.475,0,0,0,.471-.521.262.262,0,0,1,.2-.287,1.673,1.673,0,0,0-.126-3.191,4.977,4.977,0,0,1-.954-.426.372.372,0,0,1-.18-.426.517.517,0,0,1,.444-.44,1.018,1.018,0,0,1,.768.162.49.49,0,0,0,.549-.812,1.457,1.457,0,0,0-.5-.254.226.226,0,0,1-.2-.249.473.473,0,0,0-.479-.479.482.482,0,0,0-.49.475c0,.188-.084.249-.232.32a1.438,1.438,0,0,0-.842,1.21,1.289,1.289,0,0,0,.682,1.352c.114.065.229.129.347.188.292.148.6.247.906.369A.558.558,0,0,1,2185.141,3049.176Z"
            transform="translate(-95.612)"
          />
          <path
            id="Path_45816"
            data-name="Path 45816"
            d="M2138.191,3285.886c0-1.639-.006-3.277,0-4.916a1.683,1.683,0,0,1,1.255-1.636,4.66,4.66,0,0,1,2.837.01,12.612,12.612,0,0,1,1.274.558,3.447,3.447,0,0,0,1.69.375c.417-.022.836,0,1.254,0,.456,0,.642.217.646.668a1.371,1.371,0,0,1-1.478,1.411c-.968-.013-1.937,0-2.9,0-.346,0-.57.187-.574.476a.521.521,0,0,0,.575.5c.985,0,1.971,0,2.956,0a1.391,1.391,0,0,0,1.034-.424q1.055-1.061,2.115-2.115a2.475,2.475,0,0,1,1.871-.76,2.442,2.442,0,0,1,1.658.691.535.535,0,0,1,.016.821q-1.856,1.863-3.719,3.719a2.37,2.37,0,0,1-1.223.622Z"
            transform="translate(-56.615 -225.533)"
          />
          <path
            id="Path_45817"
            data-name="Path 45817"
            d="M2079.238,3320.645a.6.6,0,0,1-.385-.609c0-1.283,0-2.566,0-3.849a.568.568,0,0,1,.635-.639c.315,0,.63,0,.945,0,.106,0,.156.037.156.15q0,2.457,0,4.914a.159.159,0,0,1-.007.034Z"
            transform="translate(0 -260.291)"
          />
        </g>
      </g>
    </svg>
  );
}

export function TransactionHistoryIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25244"
        data-name="Group 25244"
        transform="translate(-14198 6574)"
      >
        <path
          id="Path_45870"
          data-name="Path 45870"
          d="M16.3,16.295a6.337,6.337,0,0,1-4.5,1.862H10.582V20.6H11.8A8.8,8.8,0,1,0,5.58,5.576L7.309,7.305a6.357,6.357,0,0,1,8.991,8.99Zm-3.273-8.4H10.582v4.418L13.7,15.423l1.729-1.729-2.4-2.4ZM5.572,9.423V10.6l-.09.006a2.9,2.9,0,0,0-1.744.647,1.968,1.968,0,0,0-.625,1.53,2.13,2.13,0,0,0,.661,1.606,4.017,4.017,0,0,0,1.847.9l.074.019v1.859l-.12-.028a3.853,3.853,0,0,1-.82-.3,2.457,2.457,0,0,1-.583-.391L3.139,17.606a4.855,4.855,0,0,0,2.218.953l.078.016v1.249H6.979v-1.18l.084-.011a2.535,2.535,0,0,0,1.68-.871,2.4,2.4,0,0,0,.52-1.528A1.993,1.993,0,0,0,8.61,14.7a4.121,4.121,0,0,0-1.733-.855l-.072-.02V12.063l.122.032A2.748,2.748,0,0,1,8,12.641L9.014,11.53a3.924,3.924,0,0,0-1.834-.842L7.1,10.671V9.423H5.573ZM5.757,13.6l-.134-.055a1.244,1.244,0,0,1-.474-.3A.586.586,0,0,1,5,12.841a.757.757,0,0,1,.634-.771l.12-.029V13.6Zm1.034,3.6V15.5l.144.078a.864.864,0,0,1,.487.811.88.88,0,0,1-.094.42.728.728,0,0,1-.4.335l-.132.049Z"
          transform="translate(14197.887 -6573.998)"
          fillRule="evenodd"
        />
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(14198 -6574)"
          fill="none"
        />
      </g>
    </svg>
  );
}

export function BuyCryptoIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_27793"
        data-name="Group 27793"
        transform="translate(-36.001 -611)"
      >
        <rect
          id="Rectangle_8947"
          data-name="Rectangle 8947"
          width="24"
          height="24"
          transform="translate(36.001 611)"
          fill="none"
        />
        <g
          id="Group_25590"
          data-name="Group 25590"
          transform="translate(36.301 610.7)"
        >
          <path
            id="Path_46121"
            data-name="Path 46121"
            d="M9.707,12.729h4.5v-2h-4.5Zm0,4h4.5v-2h-4.5Z"
          />
          <path
            id="Path_46122"
            data-name="Path 46122"
            d="M13.5,3.3H5.7V20.8H18.2V7.9L13.5,3.3Zm2.7,15.5H7.7V5.3h5V8.8h3.5Z"
          />
        </g>
      </g>
    </svg>
  );
}

export function ConvertHistoryIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25193"
        data-name="Group 25193"
        transform="translate(-7333 -15540)"
      >
        <rect
          id="Rectangle_8232"
          data-name="Rectangle 8232"
          width="24"
          height="24"
          transform="translate(7333 15540)"
          fill="none"
        />
        <g
          id="Group_25185"
          data-name="Group 25185"
          transform="translate(5656.787 13037.553)"
        >
          <path
            id="Path_45805"
            data-name="Path 45805"
            d="M1879.71,2655.29q0,1.926,0,3.853a1.208,1.208,0,0,1-1.257,1.256h-5.359a1.209,1.209,0,0,1-1.253-1.259q0-3.8,0-7.6a1.208,1.208,0,0,1,1.243-1.251h5.482a1.108,1.108,0,0,1,1.144,1.15Q1879.711,2653.364,1879.71,2655.29Zm-3.942-1.641c.513,0,1.027,0,1.54,0a1.123,1.123,0,0,0,.294-.035.531.531,0,0,0,.379-.606.56.56,0,0,0-.615-.455q-1.592,0-3.185,0a.945.945,0,0,0-.258.04.534.534,0,0,0-.356.59.561.561,0,0,0,.609.464C1874.706,2653.652,1875.237,2653.649,1875.767,2653.649Zm-.011,1.681h1.487a1.351,1.351,0,0,0,.347-.031.532.532,0,0,0,.388-.615.57.57,0,0,0-.638-.449q-1.566,0-3.133,0a1.086,1.086,0,0,0-.276.037.529.529,0,0,0-.365.6.563.563,0,0,0,.614.457C1874.707,2655.333,1875.232,2655.33,1875.757,2655.33Z"
            transform="translate(-182.66 -137.108)"
          />
          <path
            id="Path_45806"
            data-name="Path 45806"
            d="M1680.213,2511.575q0-1.9,0-3.8a1.209,1.209,0,0,1,1.257-1.255c.531,0,1.063,0,1.594,0,.156,0,.2.046.148.193a1.42,1.42,0,0,0-.041.469c0,.636.012,1.273,0,1.909a2.219,2.219,0,0,0,2.238,2.231c.642,0,1.284,0,1.926,0a2.2,2.2,0,0,0,.606-.069c.106-.03.152,0,.147.113-.005.134,0,.269,0,.4q0,1.812,0,3.625a1.207,1.207,0,0,1-1.238,1.239h-5.393a1.208,1.208,0,0,1-1.238-1.239Q1680.212,2513.484,1680.213,2511.575Z"
            transform="translate(0 -0.067)"
          />
          <path
            id="Path_45807"
            data-name="Path 45807"
            d="M1876.24,2545a.742.742,0,0,1,.55-.293.547.547,0,0,1,.52.322.524.524,0,0,1-.062.609c-.388.412-.785.816-1.2,1.2a.573.573,0,0,1-.834-.059q-.532-.52-1.052-1.052a.585.585,0,1,1,.817-.836l.118.106a3.155,3.155,0,0,0-.07-1.061.573.573,0,0,0-.594-.32c-.718,0-1.436,0-2.154,0a.554.554,0,0,1-.534-.425.581.581,0,0,1,.27-.648.558.558,0,0,1,.285-.071c.788,0,1.576-.005,2.364,0a1.676,1.676,0,0,1,1.575,1.585C1876.244,2544.354,1876.24,2544.651,1876.24,2545Z"
            transform="translate(-182.554 -34.335)"
          />
          <path
            id="Path_45808"
            data-name="Path 45808"
            d="M1729.01,2735.974a.81.81,0,0,1-.54.281.577.577,0,0,1-.462-.938c.119-.143.257-.27.389-.4.239-.24.476-.481.718-.718a.606.606,0,0,1,.929,0q.525.515,1.04,1.04a.587.587,0,1,1-.818.838l-.108-.1a3.922,3.922,0,0,0,.044,1.013.575.575,0,0,0,.6.36c.718,0,1.436,0,2.153,0a.555.555,0,0,1,.543.413.581.581,0,0,1-.242.644.535.535,0,0,1-.3.087c-.794,0-1.587.006-2.381,0a1.676,1.676,0,0,1-1.57-1.573C1729.005,2736.615,1729.01,2736.314,1729.01,2735.974Z"
            transform="translate(-45.433 -216.884)"
          />
          <path
            id="Path_45809"
            data-name="Path 45809"
            d="M1766.28,2508.056c0-.321,0-.642,0-.963a.572.572,0,0,1,.347-.593.589.589,0,0,1,.687.153q.8.77,1.608,1.534c.321.306.64.614.965.915a.609.609,0,0,1,.211.659.543.543,0,0,1-.55.406c-.531.011-1.061.011-1.592.01a4.8,4.8,0,0,1-.716-.019,1.13,1.13,0,0,1-.958-1.086C1766.277,2508.732,1766.28,2508.394,1766.28,2508.056Z"
            transform="translate(-82.038)"
          />
        </g>
      </g>
    </svg>
  );
}

export function EarnRewardIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25246"
        data-name="Group 25246"
        transform="translate(-14135 6506)"
      >
        <rect
          id="Rectangle_8237"
          data-name="Rectangle 8237"
          width="24"
          height="24"
          transform="translate(14135 -6506)"
          fill="none"
        />
        <g
          id="Group_25245"
          data-name="Group 25245"
          transform="translate(14307.256 -5360.777)"
        >
          <path
            id="Path_45871"
            data-name="Path 45871"
            d="M-160.7-1129.106a6.561,6.561,0,0,1-6.557-6.582,6.563,6.563,0,0,1,6.551-6.534,6.563,6.563,0,0,1,6.575,6.583A6.561,6.561,0,0,1-160.7-1129.106Zm-.013-1.1a5.467,5.467,0,0,0,5.475-5.455,5.459,5.459,0,0,0-5.428-5.463,5.46,5.46,0,0,0-5.483,5.426A5.461,5.461,0,0,0-160.713-1130.2Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_45872"
            data-name="Path 45872"
            d="M-16.553-876.326a7.387,7.387,0,0,0,3.345-1.167l1.679,3.18c-.079,0-.135,0-.19,0-.589-.052-1.179-.1-1.768-.161a.172.172,0,0,0-.206.114c-.278.582-.561,1.161-.863,1.783Z"
            transform="translate(-143.48 -252.041)"
          />
          <path
            id="Path_45873"
            data-name="Path 45873"
            d="M-146.472-877.133a7.382,7.382,0,0,0,3.352,1.15l-1.99,3.73c-.035-.056-.062-.09-.081-.129-.262-.536-.526-1.072-.78-1.612a.2.2,0,0,0-.243-.137c-.57.061-1.142.107-1.713.158-.064.005-.128,0-.219,0Z"
            transform="translate(-18.195 -252.384)"
          />
          <path
            id="Path_45874"
            data-name="Path 45874"
            d="M-123.557-1093.829a4.691,4.691,0,0,1-4.7-4.7,4.693,4.693,0,0,1,4.678-4.681,4.7,4.7,0,0,1,4.71,4.7A4.69,4.69,0,0,1-123.557-1093.829Zm-3.075-5.536-.011.043c.047.036.094.072.141.107.542.393,1.082.788,1.628,1.176a.173.173,0,0,1,.076.236c-.218.638-.428,1.279-.641,1.918-.019.056-.033.114-.049.17l.037.016,1.894-1.369,1.9,1.374a.186.186,0,0,0,0-.128c-.21-.654-.416-1.308-.636-1.958a.2.2,0,0,1,.092-.276c.541-.384,1.076-.775,1.613-1.165.048-.035.093-.075.178-.144h-.253c-.658,0-1.315,0-1.973,0a.2.2,0,0,1-.223-.166c-.2-.643-.418-1.282-.63-1.922-.017-.051-.039-.1-.075-.188-.037.1-.058.156-.076.211-.2.624-.413,1.246-.61,1.873a.226.226,0,0,1-.26.194c-.651-.01-1.3,0-1.955,0Z"
            transform="translate(-37.13 -37.148)"
          />
        </g>
      </g>
    </svg>
  );
}

export function DashboardIcon({ customColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customColor}`}
    >
      <g
        id="Group_25390"
        data-name="Group 25390"
        transform="translate(-30 -90)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(30 90)"
          fill="none"
        />
        <path
          id="Path_46077"
          data-name="Path 46077"
          d="M16,8a4,4,0,1,1-4-4A4,4,0,0,1,16,8ZM8,14a4,4,0,0,0-4,4v2H20V18a4,4,0,0,0-4-4Z"
          transform="translate(30 90)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
// export function SecurityIcon() {
//     return(
//     )
// }
export function IdentiFicationIcon({ customColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customColor}`}
    >
      <g
        id="Group_25388"
        data-name="Group 25388"
        transform="translate(-32 -198)"
      >
        <rect
          id="Rectangle_8231"
          data-name="Rectangle 8231"
          width="24"
          height="24"
          transform="translate(32 198)"
          fill="none"
        />
        <path
          id="Path_46079"
          data-name="Path 46079"
          d="M3,5H21V19H3Zm7,5A2,2,0,1,1,8,8,2,2,0,0,1,10,10Zm3-1h6v2.5H13Zm6,4.5H13V16h6Zm-8,0V16H5V13.5Z"
          transform="translate(32 198)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function RefferalIcon({ customColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customColor}`}
    >
      <g
        id="Group_25386"
        data-name="Group 25386"
        transform="translate(-30 -306)"
      >
        <rect
          id="Rectangle_8235"
          data-name="Rectangle 8235"
          width="24"
          height="24"
          transform="translate(30 306)"
          fill="none"
        />
        <path
          id="Path_46081"
          data-name="Path 46081"
          d="M11,8.5A3.5,3.5,0,1,1,7.5,5,3.5,3.5,0,0,1,11,8.5ZM2,17a3,3,0,0,1,3-3h5a3,3,0,0,1,3,3v3H2Zm14.5-1V13h-3V10h3V7h3v3h3v3h-3v3Z"
          transform="translate(30 306)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function ApimanagmentIcon({ customColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customColor}`}
    >
      <g
        id="Group_25391"
        data-name="Group 25391"
        transform="translate(-32 -360)"
      >
        <g
          id="Group_25200"
          data-name="Group 25200"
          transform="translate(-7301 -15232)"
        >
          <rect
            id="Rectangle_8234"
            data-name="Rectangle 8234"
            width="24"
            height="24"
            transform="translate(7333 15592)"
            fill="none"
          />
        </g>
        <path
          id="Path_46082"
          data-name="Path 46082"
          d="M10,5a7,7,0,1,0,0,14h3V16h3V13H13V11h3V8H13V5ZM21,5H18V19h3Z"
          transform="translate(32 360)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function SettingIcon({ customColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customColor}`}
    >
      <g
        id="Group_25387"
        data-name="Group 25387"
        transform="translate(-30 -252)"
      >
        <rect
          id="Rectangle_8236"
          data-name="Rectangle 8236"
          width="24"
          height="24"
          transform="translate(30 252)"
          fill="none"
        />
        <path
          id="Path_46080"
          data-name="Path 46080"
          d="M21,6H13.21A4,4,0,0,0,5.79,6H3V9H5.79a4,4,0,0,0,7.42,0H21ZM10.79,15H3v3h7.79a4,4,0,0,0,7.42,0H21V15H18.21a4,4,0,0,0-7.42,0Z"
          transform="translate(30 251.503)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function DashboardIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25390"
        data-name="Group 25390"
        transform="translate(-30 -90)"
      >
        <rect
          id="Rectangle_8234"
          data-name="Rectangle 8234"
          width="24"
          height="24"
          transform="translate(30 90)"
          fill="none"
        />
        <path
          id="Path_46077"
          data-name="Path 46077"
          d="M16,8a4,4,0,1,1-4-4A4,4,0,0,1,16,8ZM8,14a4,4,0,0,0-4,4v2H20V18a4,4,0,0,0-4-4Z"
          transform="translate(30 90)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function SecurityIconLight({ fillColorCustom }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={fillColorCustom}
    >
      <g
        id="Group_25389"
        data-name="Group 25389"
        transform="translate(-30 -144)"
      >
        <path
          id="Path_45869"
          data-name="Path 45869"
          d="M0,0H24V24H0Z"
          transform="translate(30 144)"
          fill="none"
        />
        <path
          id="Path_46078"
          data-name="Path 46078"
          d="M4,16V4H20V16l-8,5ZM14,9.76a2,2,0,0,1-1,1.732V14H11V11.492A2,2,0,1,1,14,9.76Z"
          transform="translate(30 144)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function IdentiFicationIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25388"
        data-name="Group 25388"
        transform="translate(-32 -198)"
      >
        <rect
          id="Rectangle_8231"
          data-name="Rectangle 8231"
          width="24"
          height="24"
          transform="translate(32 198)"
          fill="none"
        />
        <path
          id="Path_46079"
          data-name="Path 46079"
          d="M3,5H21V19H3Zm7,5A2,2,0,1,1,8,8,2,2,0,0,1,10,10Zm3-1h6v2.5H13Zm6,4.5H13V16h6Zm-8,0V16H5V13.5Z"
          transform="translate(32 198)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function RefferalIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25386"
        data-name="Group 25386"
        transform="translate(-30 -306)"
      >
        <rect
          id="Rectangle_8235"
          data-name="Rectangle 8235"
          width="24"
          height="24"
          transform="translate(30 306)"
          fill="none"
        />
        <path
          id="Path_46081"
          data-name="Path 46081"
          d="M11,8.5A3.5,3.5,0,1,1,7.5,5,3.5,3.5,0,0,1,11,8.5ZM2,17a3,3,0,0,1,3-3h5a3,3,0,0,1,3,3v3H2Zm14.5-1V13h-3V10h3V7h3v3h3v3h-3v3Z"
          transform="translate(30 306)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function ApimanagmentIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_25391"
        data-name="Group 25391"
        transform="translate(-32 -360)"
      >
        <g
          id="Group_25200"
          data-name="Group 25200"
          transform="translate(-7301 -15232)"
        >
          <rect
            id="Rectangle_8234"
            data-name="Rectangle 8234"
            width="24"
            height="24"
            transform="translate(7333 15592)"
            fill="none"
          />
        </g>
        <path
          id="Path_46082"
          data-name="Path 46082"
          d="M10,5a7,7,0,1,0,0,14h3V16h3V13H13V11h3V8H13V5ZM21,5H18V19h3Z"
          transform="translate(32 360)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function SettingIconLight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className=""
    >
      <g
        id="Group_25387"
        data-name="Group 25387"
        transform="translate(-30 -252)"
      >
        <rect
          id="Rectangle_8236"
          data-name="Rectangle 8236"
          width="24"
          height="24"
          transform="translate(30 252)"
          fill="none"
        />
        <path
          id="Path_46080"
          data-name="Path 46080"
          d="M21,6H13.21A4,4,0,0,0,5.79,6H3V9H5.79a4,4,0,0,0,7.42,0H21ZM10.79,15H3v3h7.79a4,4,0,0,0,7.42,0H21V15H18.21a4,4,0,0,0-7.42,0Z"
          transform="translate(30 251.503)"
          fill="#f5f5f5"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function CardDetailDark() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9H17M7 15H17M15 13V17M9 7V11M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function CardDetail() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9H17M7 15H17M15 13V17M9 7V11M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20Z"
        stroke="#f5f5f5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
      />
    </svg>
  );
}
export function CardControl() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 32 32"
      data-name="Layer 1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        className="cls-1"
        d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6Zm0,18H4V8H28Z"
      />
      <path className="cls-1" d="M18,14h7a1,1,0,0,0,0-2H18a1,1,0,0,0,0,2Z" />
      <path className="cls-1" d="M18,18h4a1,1,0,0,0,0-2H18a1,1,0,0,0,0,2Z" />
      <path
        className="cls-1"
        d="M7,21a1,1,0,0,0,1-1c0-.28.88-.89,1.28-1l.11,0a2.3,2.3,0,0,0,1.1-.58,2.2,2.2,0,0,0,1,0,2.3,2.3,0,0,0,1.1.58l.07,0c.43.16,1.28.77,1.32,1.05a1,1,0,0,0,2,0c0-1.77-2.37-2.86-2.72-3h0A5,5,0,0,0,14,14a3.16,3.16,0,0,0-3-3,3.16,3.16,0,0,0-3,3,5,5,0,0,0,.74,3h0C8.37,17.14,6,18.23,6,20A1,1,0,0,0,7,21Zm4-8a1.17,1.17,0,0,1,1,1,2.69,2.69,0,0,1-.71,2.29.41.41,0,0,1-.58,0A2.68,2.68,0,0,1,10,14,1.17,1.17,0,0,1,11,13Z"
      />
    </svg>
  );
}
export function CardControllight() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 32 32"
      data-name="Layer 1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <path
        fill="#fff"
        className="cls-1"
        d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6Zm0,18H4V8H28Z"
      />
      <path
        fill="#fff"
        className="cls-1"
        d="M18,14h7a1,1,0,0,0,0-2H18a1,1,0,0,0,0,2Z"
      />
      <path
        fill="#fff"
        className="cls-1"
        d="M18,18h4a1,1,0,0,0,0-2H18a1,1,0,0,0,0,2Z"
      />
      <path
        fill="#fff"
        className="cls-1"
        d="M7,21a1,1,0,0,0,1-1c0-.28.88-.89,1.28-1l.11,0a2.3,2.3,0,0,0,1.1-.58,2.2,2.2,0,0,0,1,0,2.3,2.3,0,0,0,1.1.58l.07,0c.43.16,1.28.77,1.32,1.05a1,1,0,0,0,2,0c0-1.77-2.37-2.86-2.72-3h0A5,5,0,0,0,14,14a3.16,3.16,0,0,0-3-3,3.16,3.16,0,0,0-3,3,5,5,0,0,0,.74,3h0C8.37,17.14,6,18.23,6,20A1,1,0,0,0,7,21Zm4-8a1.17,1.17,0,0,1,1,1,2.69,2.69,0,0,1-.71,2.29.41.41,0,0,1-.58,0A2.68,2.68,0,0,1,10,14,1.17,1.17,0,0,1,11,13Z"
      />
    </svg>
  );
}

export function ReferrralIcon() {
  return (
    <svg
      fill="#000000"
      width="23px"
      height="23px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5,22a4,4,0,0,0,3.858-3h6.284a4.043,4.043,0,1,0,2.789-4.837L14.816,8.836a4,4,0,1,0-5.63,0L6.078,14.166A3.961,3.961,0,0,0,5,14a4,4,0,0,0,0,8Zm14-6a2,2,0,1,1-2,2A2,2,0,0,1,19,16ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4ZM10.922,9.834A3.961,3.961,0,0,0,12,10a3.909,3.909,0,0,0,1.082-.168l3.112,5.323A4,4,0,0,0,15.142,17H8.858a3.994,3.994,0,0,0-1.044-1.838ZM5,16a2,2,0,1,1-2,2A2,2,0,0,1,5,16Z" />
    </svg>
  );
}

export function ReferrralIconLight() {
  return (
    <svg
      fill="#fff"
      width="23px"
      height="23px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5,22a4,4,0,0,0,3.858-3h6.284a4.043,4.043,0,1,0,2.789-4.837L14.816,8.836a4,4,0,1,0-5.63,0L6.078,14.166A3.961,3.961,0,0,0,5,14a4,4,0,0,0,0,8Zm14-6a2,2,0,1,1-2,2A2,2,0,0,1,19,16ZM12,4a2,2,0,1,1-2,2A2,2,0,0,1,12,4ZM10.922,9.834A3.961,3.961,0,0,0,12,10a3.909,3.909,0,0,0,1.082-.168l3.112,5.323A4,4,0,0,0,15.142,17H8.858a3.994,3.994,0,0,0-1.044-1.838ZM5,16a2,2,0,1,1-2,2A2,2,0,0,1,5,16Z" />
    </svg>
  );
}
export function ApprovalRecquestIcon() {
  return (
    <svg
      fill="#000"
      width="23px"
      height="23px"
      viewBox="0 0 16 16"
      id="request-approval-16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path_47"
        data-name="Path 47"
        d="M-19,12a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0-19,12Zm0-3a1,1,0,0,1,1,1,1,1,0,0,1-1,1,1,1,0,0,1-1-1A1,1,0,0,1-19,9Zm2.5,4h-5A2.5,2.5,0,0,0-24,15.5,1.5,1.5,0,0,0-22.5,17h7A1.5,1.5,0,0,0-14,15.5,2.5,2.5,0,0,0-16.5,13Zm1,3h-7a.5.5,0,0,1-.5-.5A1.5,1.5,0,0,1-21.5,14h5A1.5,1.5,0,0,1-15,15.5.5.5,0,0,1-15.5,16ZM-8,3.5v5A2.5,2.5,0,0,1-10.5,11h-2.793l-1.853,1.854A.5.5,0,0,1-15.5,13a.489.489,0,0,1-.191-.038A.5.5,0,0,1-16,12.5v-2a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v.793l1.146-1.147A.5.5,0,0,1-13.5,10h3A1.5,1.5,0,0,0-9,8.5v-5A1.5,1.5,0,0,0-10.5,2h-7A1.5,1.5,0,0,0-19,3.5v3a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-3A2.5,2.5,0,0,1-17.5,1h7A2.5,2.5,0,0,1-8,3.5Zm-6.854,4.354-2-2a.5.5,0,0,1,0-.708.5.5,0,0,1,.708,0L-14.5,6.793l2.646-2.647a.5.5,0,0,1,.708,0,.5.5,0,0,1,0,.708l-3,3A.5.5,0,0,1-14.5,8,.5.5,0,0,1-14.854,7.854Z"
        transform="translate(24 -1)"
      />
    </svg>
  );
}

export function ApprovalRecquestIconLight() {
  return (
    <svg
      fill="#fff"
      width="23px"
      height="23px"
      viewBox="0 0 16 16"
      id="request-approval-16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path_47"
        data-name="Path 47"
        d="M-19,12a2,2,0,0,0,2-2,2,2,0,0,0-2-2,2,2,0,0,0-2,2A2,2,0,0,0-19,12Zm0-3a1,1,0,0,1,1,1,1,1,0,0,1-1,1,1,1,0,0,1-1-1A1,1,0,0,1-19,9Zm2.5,4h-5A2.5,2.5,0,0,0-24,15.5,1.5,1.5,0,0,0-22.5,17h7A1.5,1.5,0,0,0-14,15.5,2.5,2.5,0,0,0-16.5,13Zm1,3h-7a.5.5,0,0,1-.5-.5A1.5,1.5,0,0,1-21.5,14h5A1.5,1.5,0,0,1-15,15.5.5.5,0,0,1-15.5,16ZM-8,3.5v5A2.5,2.5,0,0,1-10.5,11h-2.793l-1.853,1.854A.5.5,0,0,1-15.5,13a.489.489,0,0,1-.191-.038A.5.5,0,0,1-16,12.5v-2a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v.793l1.146-1.147A.5.5,0,0,1-13.5,10h3A1.5,1.5,0,0,0-9,8.5v-5A1.5,1.5,0,0,0-10.5,2h-7A1.5,1.5,0,0,0-19,3.5v3a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-3A2.5,2.5,0,0,1-17.5,1h7A2.5,2.5,0,0,1-8,3.5Zm-6.854,4.354-2-2a.5.5,0,0,1,0-.708.5.5,0,0,1,.708,0L-14.5,6.793l2.646-2.647a.5.5,0,0,1,.708,0,.5.5,0,0,1,0,.708l-3,3A.5.5,0,0,1-14.5,8,.5.5,0,0,1-14.854,7.854Z"
        transform="translate(24 -1)"
      />
    </svg>
  );
}
export function RewardManagementIcon() {
  return (
    <svg
      fill="#000000"
      height="23px"
      width="23px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.028 512.028"
    >
      <g>
        <g>
          <g>
            <path
              d="M436.776,497.792l-63.125-178.731c38.912-33.28,63.68-82.624,63.68-137.728C437.331,81.344,355.987,0,255.997,0
         S74.664,81.344,74.664,181.333c0,55.083,24.789,104.427,63.68,137.728L75.261,497.792c-1.451,4.075-0.256,8.661,3.008,11.541
         c2.005,1.749,4.523,2.667,7.061,2.667c1.621,0,3.264-0.384,4.779-1.131l79.232-39.616l48,38.421
         c2.901,2.304,6.72,2.923,10.219,1.728c3.477-1.237,6.08-4.139,6.869-7.744l14.741-66.347l29.035,68.203
         c1.301,3.093,3.968,5.355,7.211,6.165c3.179,0.875,6.656,0.064,9.28-2.005l48-38.421l79.232,39.616
         c1.515,0.747,3.136,1.131,4.779,1.131c2.539,0,5.056-0.917,7.061-2.667C437.032,506.453,438.205,501.867,436.776,497.792z
          M307.773,332.565l-33.045,7.573c-6.144,0.725-12.373,1.195-18.731,1.195c-88.235,0-160-71.765-160-160s71.765-160,160-160
         s160,71.765,160,160C415.997,251.435,370.643,310.997,307.773,332.565z"
            />
            <path
              d="M255.997,64.021c-64.704,0-117.333,52.651-117.333,117.333c0,32.32,13.141,61.632,34.347,82.859
         c0.043,0.021,0.043,0.107,0.085,0.149c0.235,0.235,0.512,0.384,0.768,0.619c5.12,5.013,10.645,9.6,16.597,13.632
         c0.683,0.469,1.408,0.832,2.091,1.28c5.675,3.669,11.648,6.933,17.941,9.6c0.725,0.32,1.493,0.533,2.24,0.832
         c6.379,2.539,13.013,4.629,19.883,6.016c0.405,0.085,0.832,0.107,1.237,0.192c7.168,1.387,14.571,2.155,22.144,2.155
         c7.573,0,14.955-0.789,22.144-2.155c0.405-0.085,0.832-0.107,1.237-0.192c6.869-1.387,13.504-3.477,19.883-6.016
         c0.747-0.299,1.515-0.512,2.24-0.832c6.293-2.667,12.267-5.931,17.941-9.6c0.683-0.448,1.408-0.811,2.091-1.28
         c5.952-4.032,11.477-8.619,16.597-13.632c0.235-0.235,0.533-0.384,0.768-0.619c0.043-0.043,0.043-0.107,0.085-0.149
         c21.205-21.248,34.347-50.56,34.347-82.859C373.331,116.672,320.701,64.021,255.997,64.021z M333.8,237.141
         c-9.856-16.917-24.768-29.952-42.368-37.568c10.901-9.771,17.92-23.808,17.92-39.573c0-29.419-23.936-53.333-53.333-53.333
         S202.685,130.581,202.685,160c0,15.765,6.997,29.803,17.92,39.573c-17.621,7.595-32.533,20.629-42.368,37.568
         c-11.371-15.765-18.24-34.944-18.24-55.808c0-52.928,43.072-96,96-96c52.928,0,96,43.072,96,96
         C351.997,202.197,345.128,221.376,333.8,237.141z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RewardManagementIconLight() {
  return (
    <svg
      fill="#fff"
      height="23px"
      width="23px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.028 512.028"
    >
      <g>
        <g>
          <g>
            <path
              d="M436.776,497.792l-63.125-178.731c38.912-33.28,63.68-82.624,63.68-137.728C437.331,81.344,355.987,0,255.997,0
         S74.664,81.344,74.664,181.333c0,55.083,24.789,104.427,63.68,137.728L75.261,497.792c-1.451,4.075-0.256,8.661,3.008,11.541
         c2.005,1.749,4.523,2.667,7.061,2.667c1.621,0,3.264-0.384,4.779-1.131l79.232-39.616l48,38.421
         c2.901,2.304,6.72,2.923,10.219,1.728c3.477-1.237,6.08-4.139,6.869-7.744l14.741-66.347l29.035,68.203
         c1.301,3.093,3.968,5.355,7.211,6.165c3.179,0.875,6.656,0.064,9.28-2.005l48-38.421l79.232,39.616
         c1.515,0.747,3.136,1.131,4.779,1.131c2.539,0,5.056-0.917,7.061-2.667C437.032,506.453,438.205,501.867,436.776,497.792z
          M307.773,332.565l-33.045,7.573c-6.144,0.725-12.373,1.195-18.731,1.195c-88.235,0-160-71.765-160-160s71.765-160,160-160
         s160,71.765,160,160C415.997,251.435,370.643,310.997,307.773,332.565z"
            />
            <path
              d="M255.997,64.021c-64.704,0-117.333,52.651-117.333,117.333c0,32.32,13.141,61.632,34.347,82.859
         c0.043,0.021,0.043,0.107,0.085,0.149c0.235,0.235,0.512,0.384,0.768,0.619c5.12,5.013,10.645,9.6,16.597,13.632
         c0.683,0.469,1.408,0.832,2.091,1.28c5.675,3.669,11.648,6.933,17.941,9.6c0.725,0.32,1.493,0.533,2.24,0.832
         c6.379,2.539,13.013,4.629,19.883,6.016c0.405,0.085,0.832,0.107,1.237,0.192c7.168,1.387,14.571,2.155,22.144,2.155
         c7.573,0,14.955-0.789,22.144-2.155c0.405-0.085,0.832-0.107,1.237-0.192c6.869-1.387,13.504-3.477,19.883-6.016
         c0.747-0.299,1.515-0.512,2.24-0.832c6.293-2.667,12.267-5.931,17.941-9.6c0.683-0.448,1.408-0.811,2.091-1.28
         c5.952-4.032,11.477-8.619,16.597-13.632c0.235-0.235,0.533-0.384,0.768-0.619c0.043-0.043,0.043-0.107,0.085-0.149
         c21.205-21.248,34.347-50.56,34.347-82.859C373.331,116.672,320.701,64.021,255.997,64.021z M333.8,237.141
         c-9.856-16.917-24.768-29.952-42.368-37.568c10.901-9.771,17.92-23.808,17.92-39.573c0-29.419-23.936-53.333-53.333-53.333
         S202.685,130.581,202.685,160c0,15.765,6.997,29.803,17.92,39.573c-17.621,7.595-32.533,20.629-42.368,37.568
         c-11.371-15.765-18.24-34.944-18.24-55.808c0-52.928,43.072-96,96-96c52.928,0,96,43.072,96,96
         C351.997,202.197,345.128,221.376,333.8,237.141z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function AssetNamesIcon() {
  return (
    <svg
      fill="#fff"
      height="23px"
      width="23px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5H21M10 10H21M10 14H21M3 19H21M3 9L6 12L3 15"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function AssetNamesIconLight() {
  return (
    <svg
      fill="#000"
      height="23px"
      width="23px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5H21M10 10H21M10 14H21M3 19H21M3 9L6 12L3 15"
        stroke="#000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export function OtcTransactionIconDark() {
  return (
    <svg
      fill="#000000"
      height="23px"
      width="23px"
      version="1.1"
      viewBox="0 0 490.24 490.24"
    >
      <g>
        <path
          d="M259.367,182.89v160.498h-17.408c-10.19,0-17.969,4.075-21.339,11.181c-3.371,7.106-1.606,15.708,4.84,23.601l57.354,70.21
		c5.78,7.073,13.995,11.131,22.54,11.131c8.544,0,16.76-4.057,22.541-11.132l57.353-70.209c6.447-7.894,8.211-16.495,4.84-23.601
		c-3.371-7.105-11.148-11.181-21.338-11.181h-17.409V182.892c0-11.631-9.462-21.094-21.093-21.094H280.46
		C268.829,161.798,259.367,171.26,259.367,182.89z M241.304,364.388h28.563c5.799,0,10.5-4.701,10.5-10.5V182.89
		c0-0.051,0.042-0.092,0.093-0.092h49.787c0.052,0,0.093,0.041,0.093,0.094v170.996c0,5.799,4.701,10.5,10.5,10.5l28.67-0.01
		l-57.879,70.714c-1.801,2.204-4.031,3.418-6.278,3.418c-2.247,0-4.477-1.214-6.277-3.417L241.304,364.388z"
        />
        <path
          d="M121.49,146.853h17.409V307.35c0,11.631,9.462,21.093,21.093,21.093h49.787c11.631,0,21.093-9.462,21.093-21.092V146.853
		h17.408c10.19,0,17.969-4.075,21.339-11.181c3.371-7.107,1.606-15.708-4.84-23.601l-57.354-70.21
		c-5.78-7.073-13.995-11.131-22.54-11.131c-8.544,0-16.76,4.057-22.541,11.132l-57.352,70.209
		c-6.447,7.894-8.212,16.495-4.841,23.602C103.523,142.777,111.3,146.853,121.49,146.853z M120.851,125.853l57.758-70.704
		c1.801-2.204,4.031-3.418,6.278-3.418c2.247,0,4.477,1.214,6.277,3.417l57.766,70.705h-28.556c-5.799,0-10.5,4.701-10.5,10.5
		v170.998c0,0.051-0.042,0.092-0.093,0.092h-49.787c-0.052,0-0.093-0.041-0.093-0.093V136.353c0-5.799-4.701-10.5-10.5-10.5H120.851
		z"
        />
        <path
          d="M418.049,0H72.191c-5.799,0-10.5,4.701-10.5,10.5v469.24c0,5.799,4.701,10.5,10.5,10.5h345.858
		c5.799,0,10.5-4.701,10.5-10.5V10.5C428.549,4.701,423.848,0,418.049,0z M407.549,469.24H82.691V21h324.858V469.24z"
        />
      </g>
    </svg>
  );
}
export function OtcTransactionIconLight() {
  return (
    <svg
      fill="#ffffff"
      height="23px"
      width="23px"
      version="1.1"
      viewBox="0 0 490.24 490.24"
    >
      <g>
        <path
          d="M259.367,182.89v160.498h-17.408c-10.19,0-17.969,4.075-21.339,11.181c-3.371,7.106-1.606,15.708,4.84,23.601l57.354,70.21
		c5.78,7.073,13.995,11.131,22.54,11.131c8.544,0,16.76-4.057,22.541-11.132l57.353-70.209c6.447-7.894,8.211-16.495,4.84-23.601
		c-3.371-7.105-11.148-11.181-21.338-11.181h-17.409V182.892c0-11.631-9.462-21.094-21.093-21.094H280.46
		C268.829,161.798,259.367,171.26,259.367,182.89z M241.304,364.388h28.563c5.799,0,10.5-4.701,10.5-10.5V182.89
		c0-0.051,0.042-0.092,0.093-0.092h49.787c0.052,0,0.093,0.041,0.093,0.094v170.996c0,5.799,4.701,10.5,10.5,10.5l28.67-0.01
		l-57.879,70.714c-1.801,2.204-4.031,3.418-6.278,3.418c-2.247,0-4.477-1.214-6.277-3.417L241.304,364.388z"
        />
        <path
          d="M121.49,146.853h17.409V307.35c0,11.631,9.462,21.093,21.093,21.093h49.787c11.631,0,21.093-9.462,21.093-21.092V146.853
		h17.408c10.19,0,17.969-4.075,21.339-11.181c3.371-7.107,1.606-15.708-4.84-23.601l-57.354-70.21
		c-5.78-7.073-13.995-11.131-22.54-11.131c-8.544,0-16.76,4.057-22.541,11.132l-57.352,70.209
		c-6.447,7.894-8.212,16.495-4.841,23.602C103.523,142.777,111.3,146.853,121.49,146.853z M120.851,125.853l57.758-70.704
		c1.801-2.204,4.031-3.418,6.278-3.418c2.247,0,4.477,1.214,6.277,3.417l57.766,70.705h-28.556c-5.799,0-10.5,4.701-10.5,10.5
		v170.998c0,0.051-0.042,0.092-0.093,0.092h-49.787c-0.052,0-0.093-0.041-0.093-0.093V136.353c0-5.799-4.701-10.5-10.5-10.5H120.851
		z"
        />
        <path
          d="M418.049,0H72.191c-5.799,0-10.5,4.701-10.5,10.5v469.24c0,5.799,4.701,10.5,10.5,10.5h345.858
		c5.799,0,10.5-4.701,10.5-10.5V10.5C428.549,4.701,423.848,0,418.049,0z M407.549,469.24H82.691V21h324.858V469.24z"
        />
      </g>
    </svg>
  );
}
export function OtcControlIconLight() {
  return (
    <svg
      fill="#fff"
      height="23px"
      width="23px"
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>tree-view-line</title>
      <path
        d="M15,32H11a1,1,0,0,1-1-1V27a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,15,32Zm-3-2h2V28H12Z"
        class="clr-i-outline clr-i-outline-path-1"
      ></path>
      <path
        d="M15,16H11a1,1,0,0,0-1,1v1.2H5.8V12H7a1,1,0,0,0,1-1V7A1,1,0,0,0,7,6H3A1,1,0,0,0,2,7v4a1,1,0,0,0,1,1H4.2V29.8h6.36a.8.8,0,0,0,0-1.6H5.8V19.8H10V21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V17A1,1,0,0,0,15,16ZM4,8H6v2H4ZM14,20H12V18h2Z"
        class="clr-i-outline clr-i-outline-path-2"
      ></path>
      <path
        d="M34,9a1,1,0,0,0-1-1H10v2H33A1,1,0,0,0,34,9Z"
        class="clr-i-outline clr-i-outline-path-3"
      ></path>
      <path
        d="M33,18H18v2H33a1,1,0,0,0,0-2Z"
        class="clr-i-outline clr-i-outline-path-4"
      ></path>
      <path
        d="M33,28H18v2H33a1,1,0,0,0,0-2Z"
        class="clr-i-outline clr-i-outline-path-5"
      ></path>
      <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
    </svg>
  );
}

export function OtcControlIconDark() {
  return (
    <svg
      fill="#000"
      height="23px"
      width="23px"
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>tree-view-line</title>
      <path
        d="M15,32H11a1,1,0,0,1-1-1V27a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,15,32Zm-3-2h2V28H12Z"
        class="clr-i-outline clr-i-outline-path-1"
      ></path>
      <path
        d="M15,16H11a1,1,0,0,0-1,1v1.2H5.8V12H7a1,1,0,0,0,1-1V7A1,1,0,0,0,7,6H3A1,1,0,0,0,2,7v4a1,1,0,0,0,1,1H4.2V29.8h6.36a.8.8,0,0,0,0-1.6H5.8V19.8H10V21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V17A1,1,0,0,0,15,16ZM4,8H6v2H4ZM14,20H12V18h2Z"
        class="clr-i-outline clr-i-outline-path-2"
      ></path>
      <path
        d="M34,9a1,1,0,0,0-1-1H10v2H33A1,1,0,0,0,34,9Z"
        class="clr-i-outline clr-i-outline-path-3"
      ></path>
      <path
        d="M33,18H18v2H33a1,1,0,0,0,0-2Z"
        class="clr-i-outline clr-i-outline-path-4"
      ></path>
      <path
        d="M33,28H18v2H33a1,1,0,0,0,0-2Z"
        class="clr-i-outline clr-i-outline-path-5"
      ></path>
      <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
    </svg>
  );
}

export function UserUnlockwhite() {
  return (
    <svg
      style={{ fill: "#ffffff" }}
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <title>user-unlock (3)-svg</title>
      <path
        id="Layer"
        fill-rule="evenodd"
        class="s0"
        d="m2 6c0-3.3 2.7-6 6-6 3.3 0 6 2.7 6 6 0 3.3-2.7 6-6 6-3.3 0-6-2.7-6-6zm22 12v2c0 2.2-1.8 4-4 4h-4c-2.2 0-4-1.8-4-4v-2c0-1.5 0.8-2.8 2-3.5v-1.5c0-2.2 1.8-4 4-4 1.1 0 2.2 0.5 3 1.3 0.3 0.4 0.3 1.1-0.1 1.4-0.4 0.4-1 0.4-1.4 0-0.4-0.5-0.9-0.7-1.5-0.7-1.1 0-2 0.9-2 2v1h4c2.2 0 4 1.8 4 4zm-4.5 1c0-0.8-0.7-1.5-1.5-1.5-0.8 0-1.5 0.7-1.5 1.5 0 0.8 0.7 1.5 1.5 1.5 0.8 0 1.5-0.7 1.5-1.5zm-9.5 1c0 1.5 0.6 2.9 1.5 4h-10.5c-0.6 0-1-0.4-1-1v-0.7c0-4.1 3-7.8 7.1-8.2q1.4-0.2 2.7 0.1c0.7 0.2 0.9 0.9 0.7 1.4q-0.5 1.1-0.5 2.4z"
      />
    </svg>
  );
}

export function UserUnlockblack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="m8,12c3.309,0,6-2.691,6-6S11.309,0,8,0,2,2.691,2,6s2.691,6,6,6Zm12,2h-4v-1c0-1.103.897-2,2-2,.568,0,1.111.243,1.492.667.368.411,1,.445,1.412.077.411-.369.445-1.001.076-1.412-.759-.847-1.845-1.332-2.98-1.332-2.206,0-4,1.794-4,4v1.537c-1.195.693-2,1.986-2,3.463v2c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4v-2c0-2.206-1.794-4-4-4Zm-2,6.5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm-8-.5c0,1.535.58,2.938,1.532,4H1c-.552,0-1-.448-1-1v-.729c0-4.115,2.993-7.76,7.083-8.219.96-.108,1.891-.044,2.766.164.616.146.887.839.637,1.42-.312.726-.486,1.525-.486,2.364v2Z" />
    </svg>
  );
}
