import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./CkEdior.scss";
function CkEdior(props) {
  let { editorHandler, additionalFeature, name, title } = props;
  return (
    <section className="textAreaStyle">
      <h2>{title}</h2>
      <div className="inputContact">
      <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote",
              "ckfinder",
              "|",
              "imageTextAlternative",
              "imageUpload",
              "imageStyle:full",
              "imageStyle:side",
              "|",
              "mediaEmbed",
              "insertTable",
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "|",
              "undo", 
              "redo",
            ],
          }}
          // config={{
          //   ckfinder: {
          //     uploadUrl: "https://avi.url.com/uploads",
          //   },
          // }}
          data={additionalFeature}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            editor.setData(additionalFeature);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            editorHandler(data, name); 
          }}
        />
      </div>
    </section>
  );
}

export default CkEdior;
