import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Grid } from "antd";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { REACT_APP_DOMAIN_KEY } from "../../../Services/EndPoints";
import { useSelector } from "react-redux";
import {
  ApprovalRecquestIcon,
  ApprovalRecquestIconLight,
  AssetNamesIcon,
  AssetNamesIconLight,
  DashboardIcon,
  DashboardIconLight,
  IdentiFicationIcon,
  IdentiFicationIconLight,
  OtcControlIconDark,
  OtcControlIconLight,
  OtcTransactionIconDark,
  OtcTransactionIconLight,
  ReferrralIcon,
  ReferrralIconLight,
  RefferalIcon,
  RefferalIconLight,
  RewardManagementIcon,
  RewardManagementIconLight,
  SecurityIconLight,
  SettingIcon,
  SettingIconLight,
  UserUnlock,
  UserUnlockblack,
  UserUnlockwhite,
} from "../../Assets/Images/SidebarIcons";
import { CardDetail } from "../../Assets/Images/SidebarIcons.jsx";
import {
  CardDetailDark,
  CardControl,
  CardControllight,
} from "../../Assets/Images/SidebarIcons.jsx";
import "./DashboardLayout.scss";
import useWindowDimensions from "../../Ui/useWindowDimensions/useWindowDimensions.jsx";
import { getData, saveData } from "../../../Services/Utils";
import * as Constants from "../../../constants/constants";
import {
  ApartmentOutlined,
  AuditOutlined,
  PictureOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import ContactUs from "../../Pages/ContactUs/ContactUs.jsx";

function getItem(label, key, icon, children) {
  return { key, icon, children, label };
}

function DashboardLayout(props) {
  const navigate = useNavigate();
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = Layout;
  const width = useWindowDimensions();
  const location = useLocation();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);

  const themes = getData(Constants.APP_THEME);
  let key = getData(Constants.DEFAULT_KEY) || "1";
  const [theme, setTheme] = useState();
  // const [items, setItems] = useState([]);

  useEffect(() => {
    // Extract the key from the current route path
    const currentKey = location.pathname.split("/")[1];
    setDefaultSelectedKeys([currentKey]);
  }, [location.pathname]);

  /************************************** changeTheme ****************************************************/
  const changeTheme = (data) => {
    setTheme(data);
  };
  /************************************** onPressItem ****************************************************/

  let role_name = login?.admin_user_access_data
    ? login?.admin_user_access_data?.role_name
    : "super_admin";

  // let tab_data = login?.admin_user_access_data?.access;
  // console.log(tab_data);
  // tab_data = JSON.parse(tab_data);

  const items =
    role_name === "super_admin"
      ? [
          getItem(
            <Link to="/dashboard">
              <span className="fontFamilyText">Dashboard</span>
            </Link>,
            "dashboard",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <DashboardIconLight />
              ) : (
                <DashboardIcon />
              )}
            </span>
          ),

          getItem(
            <Link to="/userDetails">
              <span className="fontFamilyText ">User details</span>
            </Link>,
            "userDetails",
            <span className="anticon anticon-desktop ant-menu-item-icon ">
              {theme == "darkTheme" ? (
                <SecurityIconLight fillColorCustom="fillBlack" />
              ) : (
                <SecurityIconLight fillColorCustom="fillWhite" />
              )}
            </span>
          ),

          getItem(
            <Link to="/appCustomisation">
              <span className="fontFamilyText ">App customisation</span>
            </Link>,
            "appCustomisation",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <IdentiFicationIconLight />
              ) : (
                <IdentiFicationIcon />
              )}
            </span>
          ),
          getItem(
            <Link to="/announcements">
              <span className="fontFamilyText ">Announcements</span>
            </Link>,
            "announcements",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <RefferalIconLight /> : <RefferalIcon />}
            </span>
          ),
          getItem(
            <Link to="/card-details">
              <span className="fontFamilyText ">Card Details</span>
            </Link>,
            "card-details",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <CardControllight /> : <CardControl />}
            </span>
          ),
          getItem(
            <Link to="/cardcontrol">
              <span className="fontFamilyText ">Card Control</span>
            </Link>,
            "cardcontrol",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <CardDetail /> : <CardDetailDark />}
            </span>
          ),
          getItem(
            <Link to="/card-issuance">
              <span className="fontFamilyText ">Physical Card Issuance</span>
            </Link>,
            "card-issuance",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <RefferalIconLight /> : <RefferalIcon />}
            </span>
          ),
          getItem(
            <Link to="/referrals">
              <span className="fontFamilyText ">Referrals</span>
            </Link>,
            "referrals",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <ReferrralIconLight />
              ) : (
                <ReferrralIcon />
              )}
            </span>
          ),
          getItem(
            <Link to="/ApprovalRecquest">
              <span className="fontFamilyText ">Approval Request</span>
            </Link>,
            "ApprovalRecquest",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <ApprovalRecquestIconLight />
              ) : (
                <ApprovalRecquestIcon />
              )}
            </span>
          ),
          getItem(
            <Link to="/RewardManagement">
              <span className="fontFamilyText ">Reward Management</span>
            </Link>,
            "RewardManagement",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <RewardManagementIconLight />
              ) : (
                <RewardManagementIcon />
              )}
            </span>
          ),
          getItem(
            <Link to="/adminControls">
              <span className="fontFamilyText ">Admin Controls</span>
            </Link>,
            "adminControls",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? <SettingIconLight /> : <SettingIcon />}
            </span>
          ),
          getItem(
            <Link to="/ContactUs">
              <span className="fontFamilyText ">Issue Management</span>
            </Link>,
            "ContactUs",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <AuditOutlined style={{ fontSize: "22px", color: "#fff" }} />
              ) : (
                <AuditOutlined style={{ fontSize: "22px", color: "#000" }} />
              )}
            </span>
          ),
          getItem(
            <Link to="/sub-admin">
              <span className="fontFamilyText ">Sub Admin</span>
            </Link>,
            "sub-admin",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <ApartmentOutlined
                  style={{ fontSize: "22px", color: "#fff" }}
                />
              ) : (
                <ApartmentOutlined
                  style={{ fontSize: "22px", color: "#000" }}
                />
              )}
            </span>
          ),
          getItem(
            <Link to="/OtcControls">
              <span className="fontFamilyText ">OTC Control</span>
            </Link>,
            "OtcControls",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <OtcControlIconLight
                  style={{ fontSize: "22px", color: "#fff" }}
                />
              ) : (
                <OtcControlIconDark
                  style={{ fontSize: "22px", color: "#000" }}
                />
              )}
            </span>
          ),
          getItem(
            <Link to="/OtcTransactions">
              <span className="fontFamilyText ">OTC Transactions</span>
            </Link>,
            "OtcTransactions",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <OtcTransactionIconLight
                  style={{ fontSize: "22px", color: "#fff" }}
                />
              ) : (
                <OtcTransactionIconDark
                  style={{ fontSize: "22px", color: "#000" }}
                />
              )}
            </span>
          ),
          getItem(
            <Link to="/assetIssuance">
              <span className="fontFamilyText ">Asset Issuance</span>
            </Link>,
            "assetIssuance",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <PictureOutlined style={{ fontSize: "22px" }} />
              ) : (
                <PictureOutlined style={{ color: "#000", fontSize: "22px" }} />
              )}
            </span>
          ),
          getItem(
            <Link to="/questionnaire">
              <span className="fontFamilyText ">Questionnaire</span>
            </Link>,
            "questionnaire",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <QuestionCircleOutlined style={{ fontSize: "22px" }} />
              ) : (
                <QuestionCircleOutlined
                  style={{ color: "#000", fontSize: "22px" }}
                />
              )}
            </span>
          ),
          getItem(
            <Link to="/assets">
              <span className="fontFamilyText ">Asset Names</span>
            </Link>,
            "assets",
            <span className="anticon anticon-desktop ant-menu-item-icon">
              {theme == "darkTheme" ? (
                <AssetNamesIcon style={{ fontSize: "22px" }} />
              ) : (
                <AssetNamesIconLight
                  style={{ color: "#000", fontSize: "22px" }}
                />
              )}
            </span>
          ),
          getItem(
            <Link to="/mintingAddresses">
              <span className="fontFamilyText ">Minting Addresses</span>
            </Link>,
            "mintingAddresses",
            <span className="anticon anticon-desktop ant-menu-item-icon ">
              {theme == "darkTheme" ? (
                <DashboardIconLight />
              ) : (
                <DashboardIcon />
              )}
            </span>
          ),
          getItem(
            <Link to="/secondaryProposals">
              <span className="fontFamilyText">Secondary MarketPlace</span>
            </Link>,
            "secondaryProposals",
            <span className="anticon anticon-desktop ant-menu-item-icon ">
              {theme == "darkTheme" ? (
                <OtcTransactionIconLight />
              ) : (
                <OtcTransactionIconDark />
              )}
            </span>
          ),
          getItem(
            <Link to="/unblockRequest">
              <span className="fontFamilyText">Unblock Request</span>
            </Link>,
            "unblockRequest",
            <span className="anticon anticon-desktop ant-menu-item-icon ">
              {theme == "darkTheme" ? <UserUnlockwhite /> : <UserUnlockblack />}
            </span>
          ),
        ]
      : (() => {
          const itemsArray = [];
          let tab_data = login?.admin_user_access_data?.access;
          tab_data = JSON.parse(tab_data);
          tab_data.forEach((item, index) => {
            switch (item.name) {
              case "Dashboard":
                itemsArray.push(
                  getItem(
                    <Link to="/dashboard">
                      <span className="fontFamilyText">Dashboard</span>
                    </Link>,
                    "dashboard",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <DashboardIconLight />
                      ) : (
                        <DashboardIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "User Details":
                itemsArray.push(
                  getItem(
                    <Link to="/userDetails">
                      <span className="fontFamilyText ">User details</span>
                    </Link>,
                    "userDetails",
                    <span className="anticon anticon-desktop ant-menu-item-icon ">
                      {theme == "darkTheme" ? (
                        <SecurityIconLight fillColorCustom="fillBlack" />
                      ) : (
                        <SecurityIconLight fillColorCustom="fillWhite" />
                      )}
                    </span>
                  )
                );
                break;
              // Add cases for other menu items here
              case "App customisation":
                itemsArray.push(
                  getItem(
                    <Link to="/appCustomisation">
                      <span className="fontFamilyText ">App customisation</span>
                    </Link>,
                    "appCustomisation",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <IdentiFicationIconLight />
                      ) : (
                        <IdentiFicationIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Announcements":
                itemsArray.push(
                  getItem(
                    <Link to="/announcements">
                      <span className="fontFamilyText ">Announcements</span>
                    </Link>,
                    "announcements",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <RefferalIconLight />
                      ) : (
                        <RefferalIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Card Details":
                itemsArray.push(
                  getItem(
                    <Link to="/card-details">
                      <span className="fontFamilyText ">Card Details</span>
                    </Link>,
                    "card-details",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <CardControllight />
                      ) : (
                        <CardControl />
                      )}
                    </span>
                  )
                );
                break;
              case "Card Control":
                itemsArray.push(
                  getItem(
                    <Link to="/cardcontrol">
                      <span className="fontFamilyText ">Card Control</span>
                    </Link>,
                    "cardcontrol",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <CardDetail />
                      ) : (
                        <CardDetailDark />
                      )}
                    </span>
                  )
                );
                break;
              case "Physical Card Issuance":
                itemsArray.push(
                  getItem(
                    <Link to="/card-issuance">
                      <span className="fontFamilyText ">
                        Physical Card Issuance
                      </span>
                    </Link>,
                    "card-issuance",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <RefferalIconLight />
                      ) : (
                        <RefferalIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Referrals":
                itemsArray.push(
                  getItem(
                    <Link to="/referrals">
                      <span className="fontFamilyText ">Referrals</span>
                    </Link>,
                    "referrals",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <ReferrralIconLight />
                      ) : (
                        <ReferrralIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Approval Request":
                itemsArray.push(
                  getItem(
                    <Link to="/ApprovalRecquest">
                      <span className="fontFamilyText ">Approval Request</span>
                    </Link>,
                    "ApprovalRecquest",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <ApprovalRecquestIconLight />
                      ) : (
                        <ApprovalRecquestIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Reward Management":
                itemsArray.push(
                  getItem(
                    <Link to="/RewardManagement">
                      <span className="fontFamilyText ">Reward Management</span>
                    </Link>,
                    "RewardManagement",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <RewardManagementIconLight />
                      ) : (
                        <RewardManagementIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Admin Controls":
                itemsArray.push(
                  getItem(
                    <Link to="/adminControls">
                      <span className="fontFamilyText ">Admin Controls</span>
                    </Link>,
                    "adminControls",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <SettingIconLight />
                      ) : (
                        <SettingIcon />
                      )}
                    </span>
                  )
                );
                break;
              case "Issue Management":
                itemsArray.push(
                  getItem(
                    <Link to="/ContactUs">
                      <span className="fontFamilyText ">Issue Management</span>
                    </Link>,
                    "ContactUs",
                    <span className="anticon anticon-desktop ant-menu-item-icon">
                      {theme == "darkTheme" ? (
                        <AuditOutlined
                          style={{ fontSize: "22px", color: "#fff" }}
                        />
                      ) : (
                        <AuditOutlined
                          style={{ fontSize: "22px", color: "#000" }}
                        />
                      )}
                    </span>
                  )
                );
                break;
            }
          });

          // Add Admin Controls separately
          itemsArray.push(
            getItem(
              <Link to="/adminControls">
                <span className="fontFamilyText ">Admin Controls</span>
              </Link>,
              "adminControls",
              <span className="anticon anticon-desktop ant-menu-item-icon">
                {theme == "darkTheme" ? <SettingIconLight /> : <SettingIcon />}
              </span>
            )
          );

          return itemsArray;
        })();

  return (
    <>
      <Header changeTheme={changeTheme} />
      <Layout className={width < 991 ? "dashboardSidebarOuter" : ""}>
        <Sider
          width="250px"
          collapsedWidth="100px"
          onCollapse={(value) => setCollapsed(value)}
          className={`overFlow ${width < 991 ? "dashboardSidebar" : ""}`}
        >
          <Menu theme="dark" mode="inline" selectedKeys={defaultSelectedKeys}>
            {items.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                // className={`${
                //   defaultSelectedKeys.includes(item.key) ? "active" : ""
                // }`}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content>
          <Outlet context={theme} />
        </Content>
      </Layout>
      <Footer />
    </>
  );
}

export default DashboardLayout;
