import React, { useEffect } from "react";
import Loader1 from '../Components/Assets/AdminImages/Loader.json';
import Loader2 from '../Components/Assets/AdminImages/Loader1.json';
import Loader3 from '../Components/Assets/AdminImages/Loader2.json';
import * as Constants from '../constants/constants'
import Lottie from 'react-lottie';
import { getData } from "../Services/Utils";

function Loader(props) {
    const themes = getData(Constants.APP_THEME);
    useEffect(() => {
    }, [props])
    const defaultOptions = {
        animationData: themes === "darkTheme" ? Loader3 : Loader1,
    };

    if (props.isLoading) {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(10, 3, 3, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "10000000",
                }}
            >
                <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    isStopped={props.isLoading}
                />
                {/* <LoadingOverlay active={isLoading} spinner text="Please wait" /> */}
            </div>
        );
    } else {
        return <div></div>;
    }
}
export default Loader;
