import React from 'react'
import { Menu, Table, Tabs, Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import Cards from '../../Ui/Cards/Cards';
import "./CardStatus.scss";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { useLocation } from 'react-router-dom';
import { downloadCSV, getUserCardDetail, getUserCardHistory } from '../../../redux/_actions/cardAction';
import { decodeData, toFixedExp } from '../../../Services/Utils';
import moment from 'moment';
import { Dropdown } from 'antd';
import { BASE_URL, DOWNLOAD_HISTORY } from '../../../Services/EndPoints';
import * as Constants from '../../../constants/constants';
import NodeRSA from 'node-rsa';

let active_key = 1;
let trx_type = ''
function CardStatus() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },

    {
      title: "Type of Txn.",
      dataIndex: "type_of_txn",
      key: "type_of_txn",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
  ];
  const limit = 25;
  const { TabPane } = Tabs;
  const location = useLocation();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [pageCountVirtual, setPageCountVirtual] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [activeKey, setActiveKey] = useState("1");
  const [VirtualCardData, setVirtualCardData] = useState();
  const [PhysicalCardData, setPhysicalCardData] = useState();
  const [VirtualHistory, setVirtualHistory] = useState();
  const [PhysicalHistory, setPhysicalHistory] = useState();

  useEffect(() => {
    active_key = 1
  }, [])

  useEffect(() => {
    if (activeKey == 1) {
      fetchPhysicalDetail();
    } else {
      fetchVirtualDetail();
    }
  }, [activeKey])
  /************************************** fetchVirtualDetail ****************************************************/
  const fetchVirtualDetail = () => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
      card_type: 'Virtual',
    };
    setTimeout(() => {
      dispatch(getUserCardDetail({ data })).then(async response => {
        if (JSON.stringify(response) !== '{}') {
          const res = response?.data
          let decoded_Data = await decodeData(response.encrypted_details)
          const virtual_Data = {
            "balance": decoded_Data?.balance_details?.available_balance || '0.00',
            "card_number": decoded_Data?.card_details?.card_number || '0000000000000000',
            "card_status": res.card_status,
            "fee_paid": decoded_Data?.card_apply_fees || '0.00',
            "currency": decoded_Data?.balance_details?.card_currency || 'USD',
          }
          setVirtualCardData(virtual_Data);
          fetchVirtualHistory();
        } else {
          const virtual_Data = {
            "card_number": '****************',
            "card_status": 'Inactive',
          }
          setVirtualCardData(virtual_Data);
          setLoading(false)
        }
      }).catch(err => {
        const virtual_Data = {
          "card_number": '****************',
          "card_status": 'Inactive',
        }
        setVirtualCardData(virtual_Data);
        setLoading(false)
      })
    }, 100);
  }
  /************************************** fetchPhysicalDetail ****************************************************/
  const fetchPhysicalDetail = () => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
      card_type: 'Physical',
    };
    setTimeout(() => {
      dispatch(getUserCardDetail({ data })).then(async response => {
        if (JSON.stringify(response) !== '{}') {
          const res = response?.data
          let decoded_Data = await decodeData(response.encrypted_details)
          const physical_Data = {
            "balance": decoded_Data?.balance_details?.available_balance || '0.00',
            "card_number": decoded_Data?.card_details?.card_number || '0000000000000000',
            "card_status": res.card_status,
            "fee_paid": decoded_Data?.card_apply_fees || '0.00',
            "currency": decoded_Data?.balance_details?.card_currency || 'USD',
          }
          setPhysicalCardData(physical_Data);
          fetchPhysicalHistory();
        } else {
          const physical_Data = {
            "card_number": '****************',
            "card_status": 'Inactive',
          }
          setPhysicalCardData(physical_Data);
          setLoading(false)
        }
      }).catch(err => {
        const physical_Data = {
          "card_number": '****************',
          "card_status": 'Inactive',
        }
        setPhysicalCardData(physical_Data);
        setLoading(false)
      })
    }, 100);
  }
  /************************************** fetchPhysicalHistory ****************************************************/
  const fetchPhysicalHistory = (trxnType = '') => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
      card_type: 'Physical',
      end_time: moment(new Date()).format("MMYYYY"),
      start_time: moment(new Date()).subtract(5, "months").format("MMYYYY"),
      trnx_type: trxnType
    }
    setTimeout(() => {
      dispatch(getUserCardHistory({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => item['key'] = index + 1);
          setPhysicalHistory(res)
        } else {
          setPhysicalHistory()
        }
        setLoading(false)

      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /*****************************************************fetchVirtualHistory******************************************************/
  const fetchVirtualHistory = (trxnType = '') => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
      card_type: 'Virtual',
      end_time: moment(new Date()).format("MMYYYY"),
      start_time: moment(new Date()).subtract(5, "months").format("MMYYYY"),
      trnx_type: trxnType
    }
    setTimeout(() => {
      dispatch(getUserCardHistory({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => item['key'] = index + 1);
          setVirtualHistory(res)
        } else {
          setVirtualHistory()
        }
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /*****************************************************onPressFilter******************************************************/
  const onPressFilter = (filterType) => {
    trx_type = filterType
    activeKey == 1 ? fetchPhysicalHistory(filterType) : fetchVirtualHistory(filterType)
  }
  /*****************************************************downloadCsv******************************************************/
  const download_Csv = () => {
    const second = new NodeRSA(Constants.KEY);
    second.setOptions({ encryptionScheme: 'pkcs1' });
    const enc = second.encrypt(location.state?.user_id, 'base64');
    const base64UrlSafeEncodedData = enc.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    const link = trx_type == '' ? `${BASE_URL}${DOWNLOAD_HISTORY}?user_id=${base64UrlSafeEncodedData}&card_type=${activeKey == 1 ? 'Physical' : 'Virtual'}&start_time=${moment(new Date()).subtract(5, "months").format("MMYYYY")}&end_time=${moment(new Date()).format("MMYYYY")}`
      : `${BASE_URL}${DOWNLOAD_HISTORY}?user_id=${base64UrlSafeEncodedData}&card_type=${activeKey == 1 ? 'Physical' : 'Virtual'}&start_time=${moment(new Date()).subtract(5, "months").format("MMYYYY")}&end_time=${moment(new Date()).format("MMYYYY")}&trnx_type=${trx_type}`
    window.open(link);
    return
  }
  /************************************** handleTabChange ****************************************************/
  const handleTabChange = (key) => {
    active_key = key
    setActiveKey(key);
  };
  /************************************** physical data ****************************************************/
  const dataSource = PhysicalHistory?.map((item, index) => {
    return (
      {
        key: item.key,
        sno: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
        date: (<div style={{ marginLeft: -20 }} className="tableIconStyle"><span className="fontFamilyText">{moment.unix(item.transaction_date).format('DD MMM, YYYY')} | {moment.unix(item.transaction_date).format('h:mm A')}</span></div>),
        type_of_txn: (<div className="tableIconStyle"><span className="fontFamilyText">{parseFloat(item?.credit) > 0 ? 'Deposit' : 'Withdraw'}</span></div>),
        amount: (<div style={{ marginLeft: 18 }} className="tableIconStyle"><span className="fontFamilyText"> {toFixedExp(parseFloat(toFixedExp(item?.credit, 8)) > 0 ? parseFloat(toFixedExp(item?.credit, 8)) : parseFloat(toFixedExp(item.debit, 8)), 8)}</span></div>),
        Currency: (<div style={{ marginLeft: 18 }} className="tableIconStyle"><span className="fontFamilyText">{item?.tx_currency ? item?.tx_currency?.toUpperCase() : 'USD'}</span></div>),
      }
    )
  })
  /************************************** virtual table data ****************************************************/
  const virtualData = VirtualHistory?.map((item, index) => {
    return (
      {
        key: item.key,
        sno: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
        date: (<div style={{ marginLeft: -20 }} className="tableIconStyle"><span className="fontFamilyText">{moment.unix(item.transaction_date).format('DD MMM, YYYY')} | {moment.unix(item.transaction_date).format('h:mm A')}</span></div>),
        type_of_txn: (<div className="tableIconStyle"><span className="fontFamilyText">{parseFloat(item?.credit) > 0 ? 'Deposit' : 'Withdraw'}</span></div>),
        amount: (<div style={{ marginLeft: 18 }} className="tableIconStyle"><span className="fontFamilyText"> {toFixedExp(parseFloat(toFixedExp(item?.credit, 8)) > 0 ? parseFloat(toFixedExp(item?.credit, 8)) : parseFloat(toFixedExp(item.debit, 8)), 8)}</span></div>),
        Currency: (<div style={{ marginLeft: 18 }} className="tableIconStyle"><span className="fontFamilyText">{item?.tx_currency ? item?.tx_currency?.toUpperCase() : 'USD'}</span></div>),
      }
    )
  })
  /************************************** menu ****************************************************/
  const menu = (
    <Menu className='topdropdown'>
      <Menu.Item style={{ marginTop: "7px" }} >
        <b> Type of Txn</b>
        <Menu className='trsp_itme' style={{ backgroundColor: 'transparent !important' }}>
          <Menu.Item onClick={() => onPressFilter('')} className='subitems' style={{ lineHeight: '30px', height: '30px', listStyle: "none" }}>
            All
          </Menu.Item>
          <Menu.Item onClick={() => onPressFilter('deposit')} className='subitems' style={{ lineHeight: '30px', height: '30px', listStyle: "none" }}>
            Deposit
          </Menu.Item>
          <Menu.Item onClick={() => onPressFilter('withdraw')} className='subitems' style={{ lineHeight: '30px', height: '30px', listStyle: "none" }}>
            Withdraw
          </Menu.Item>

        </Menu>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Card Details</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className='commonPaddingNew'>
        <div className="tabs__body" >
          <Tabs activeKey={activeKey} style={{ marginTop: 15, display: 'flex' }} onChange={handleTabChange} defaultActiveKey="1">
            <TabPane tab="Physical Card" key="1">
              <div className='commoanSide'>
                <Cards
                  status={PhysicalCardData?.card_status}
                  number={PhysicalCardData?.card_number}
                  feepaid={`${toFixedExp(PhysicalCardData?.fee_paid, 8)} ${PhysicalCardData?.currency}`}
                  availableBalance={`${toFixedExp(PhysicalCardData?.balance, 8)} ${PhysicalCardData?.currency}`}
                  cardstatus={PhysicalCardData?.card_status}
                  kycstatus={PhysicalCardData?.kyc_status}
                  shippingad={PhysicalCardData?.shipping_address}
                />
              </div>
              <div className='transec_histry'>
                <div className='transec_histry__heading'>
                  <h2>Transaction history</h2>
                  <div className='addnewSec'>
                    {PhysicalHistory ? <div className='drop_icon'>
                      <Dropdown overlay={menu}>
                        <button>Filter</button>
                      </Dropdown>
                    </div>
                      :
                      null}

                    {PhysicalHistory ? <ButtonCustom onClick={() => download_Csv()} label="Download" garyBtn /> : null}
                  </div>
                </div>
                <Table dataSource={dataSource} columns={columns} pagination={false} size="large" />
                <Loader isLoading={isLoading} />
              </div>
            </TabPane>
            {/* ----------------------------------------------------------- */}
            <TabPane tab="Virtual Card" key="2">
              <div className='commoanSide'>
                <Cards
                  status={VirtualCardData?.card_status}
                  number={VirtualCardData?.card_number}
                  feepaid={`${toFixedExp(VirtualCardData?.fee_paid, 8)} ${VirtualCardData?.currency}`}
                  availableBalance={`${toFixedExp(VirtualCardData?.balance, 8)} ${VirtualCardData?.currency}`}
                  cardstatus={VirtualCardData?.card_status}
                />
              </div>
              <div className='transec_histry'>
                <div className='transec_histry__heading'>
                  <h2>Transaction history</h2>
                  <div className='addnewSec'>
                    {VirtualHistory ? <div className='drop_icon'>
                      <Dropdown overlay={menu}>
                        <button>Filter</button>
                      </Dropdown>
                    </div> : null}
                    {VirtualHistory ? <ButtonCustom onClick={() => download_Csv()} label="Download" garyBtn /> : null}
                  </div>
                </div>
                <Table dataSource={virtualData} columns={columns} pagination={false} size="large" />
                <Loader isLoading={isLoading} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default CardStatus