import { apiCallGet, apiCallPost } from "../../Services/ApiClient";
import { DOWNLOAD_QUERIES_List, QUERY_LIST_API, RESPONSE_QUERY_API, USER_QUERY_LIST } from "../../Services/EndPoints";
import { toast } from "../../Components/Ui/Toast/Toast";
import commonError from "../../Components/Common/Index";

/************************************** getQueryList ****************************************************/
export const getQueryList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(QUERY_LIST_API, data).then(response => {
        const res = response;
        // console.log('res getQueryList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getQueryList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/************************************** getUserQueryList ****************************************************/
export const getUserQueryList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(USER_QUERY_LIST, data).then(response => {
        const res = response.data;
        // console.log('res getUserQueryList::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getUserQueryList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/************************************** postAdminResponse ****************************************************/
export const postAdminResponse = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(RESPONSE_QUERY_API, data).then(response => {
        const res = response.data;
        // console.log('res postAdminResponse::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('postAdminResponse er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** downloadQueryList ****************************************************/
export const downloadQueryList = ({ search, status }) => {
  return dispatch => {
    // console.log('chk search:::::', search, 'check status:::::', status)
    const link = (!search && !status) ? `${DOWNLOAD_QUERIES_List}` : (search && !status) ? `${DOWNLOAD_QUERIES_List}?search=${search}` : (!search && status) ? `${DOWNLOAD_QUERIES_List}?status=${status}` : `${DOWNLOAD_QUERIES_List}?search=${search}&status=${status}`
    return new Promise((resolve, reject) => {
      apiCallGet(link).then(response => {
        const res = response;
        console.log('res downloadQueryList::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('downloadQueryList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
