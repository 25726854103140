import { Breadcrumb, Table } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import Paginate from "../../Ui/Paginate/Paginate";
import InputCustom from "../../Ui/input/InputCustom";
import { SearchOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';
import { getUserShippingDetail } from "../../../redux/_actions/cardAction";

let paginationData = '';
function PhysicalCardIssuance() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: "Fee Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Shipping address",
      dataIndex: "wallet_address",
      key: "wallet_address",
    },
  ];
  const limit = 25;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    console.log('chk search:::::', search)
    fetchUserShippingList();
  }, [pageCount, search])
  /************************************** fetchUserShippingList ****************************************************/
  const fetchUserShippingList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getUserShippingDetail({ data })).then(response => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => item['key'] = index + 1);
          setUserData(res)
        } else {
          setUserData()
        }
        paginationData = response?.meta?.pages || 0
        setLoading(false)

      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return (
      {
        key: item.key,
        sno: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
        user_name: (<div className="tableIconStyle"><span className="fontFamilyText">{item.first_name+" "+item.last_name}</span></div>),
        email: (<div className="tableIconStyle"><span className="fontFamilyText">{item.email}</span></div>),
        wallet_address: (<div className="tableIconStyle"><span className="fontFamilyText"> {item.street_address+", "+item.city+", "+item.province+", "+item.country+" "+item.post_code}</span></div>),
        phoneNo: (<div style={{ textTransform: 'capitalize' }}><span className="fontFamilyText">+{item.country_code+""+item.mobile_number}</span></div>),
        status: (<div><span>{item.fee_status===null ?"Card Pending" : "Card Issued"}</span></div>)

      }
    )
  })
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item> Physical card Issuance</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding userDatils">
        <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table dataSource={dataSource} columns={columns} pagination={false} size="large" />
        </div>
      </div>
      {paginationData > 1 && <Paginate paginationData={paginationData} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default PhysicalCardIssuance;
