import React, { useState } from 'react'
import { Layout } from "antd";
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Outlet } from "react-router-dom";

function Mainlayout() {
  const { Content } = Layout;
  const [theme, setTheme] = useState();

  const changeTheme = (data) => {
    setTheme(data);
  };
  return (
    <>
      <Header changeTheme={changeTheme} />
      <Content>
        <Outlet context={theme} />
      </Content>
      <Footer />
    </>
  )
}

export default Mainlayout