import { USER_AUTH, UPDATE_LIST, LOGOUT_USERS_PERSIST } from "../_actions/types";

const initialState = {
          userData: '',
          myUserList: [],
}
export default (state = initialState, action) => {
          switch (action.type) {
                    case USER_AUTH:
                              console.log('chk payload:::::', action.payload)
                              return { ...state, userData: action.payload };
                    case UPDATE_LIST:
                              //console.log('chk payload UPDATE_LIST:::::', action.payload)
                              return { ...state, myUserList: action.payload.value };
                    // case LOGOUT_USERS_PERSIST:
                    //           return { ...state, ...initialState };
                    default:
                              return state;
          }
}