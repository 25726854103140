import { Breadcrumb, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import "./OtcControls.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMinMaxData,
  createTrade,
} from "../../../redux/_actions/dashboardAction";
import { toast } from "../../Ui/Toast/Toast";
import Swal from "sweetalert2";

function OtcControls() {
  const initilaObj = {
    usd_min: 0,
    usd_max: 0,
    tut_min: 0,
    tut_max: 0,
    one_tut: 0,
    mintedAmt: 0,
    usdt_fee: 0,
    tut_fee: 0,
    error: {
      usd_min: "",
      usd_max: "",
      tut_min: "",
      tut_max: "",
      one_tut: "",
      usdt_fee: "",
      tut_fee: "",
    },
  };
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [record, setRecord] = useState();
  const [allState, setAllState] = useState(initilaObj);
  /******************************************************************************************/
  useEffect(() => {
    fetchOtcDetail();
  }, []);

  /******************************************************************************************/
  const setValue = (event) => {
    const val = event.target.name;
    setAllState({ ...allState, [event.target.name]: event.target.value });
  };

  /******************************************************************************************/
  const fetchOtcDetail = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(getMinMaxData({}))
        .then((response) => {
          const res = response;
          setRecord(res);
          //set min max with coinsymbol
          res?.min_max_data.forEach((item) => {
            if (item.coin_symbol === "TUTs") {
              setAllState((prevState) => ({
                ...prevState,
                tut_min: item.min,
                tut_max: item.max,
              }));
            } else if (item.coin_symbol === "USDT") {
              setAllState((prevState) => ({
                ...prevState,
                usd_min: item.min,
                usd_max: item.max,
              }));
            }
          });

          //set fees with control state

          res?.fees_data.forEach((item) => {
            if (item.control === "USDT") {
              setAllState((prevState) => ({
                ...prevState,
                usdt_fee: item.value,
              }));
            } else if (item.control === "TUT") {
              setAllState((prevState) => ({
                ...prevState,
                tut_fee: item.value,
              }));
            }
          });
          setAllState((prevState) => ({
            ...prevState,
            one_tut: res?.value_of_tut,
            mintedAmt: res?.minted_amount,
          }));
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  /******************************************************************************************/
  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "min_usd":
          if (value === undefined) {
            error[key] = "Min USDT field is required";
            isValidate = false;
          } else {
            const minValue = parseFloat(value);
            const maxValue = parseFloat(allState.usd_max);
            if (isNaN(minValue) || minValue < 0) {
              error[key] = "Enter a valid Min USDT amount!";
              isValidate = false;
            } else if (minValue >= maxValue) {
              error[key] = "Min USDT value should be less than Max USDT!";
              isValidate = false;
            } else {
              error[key] = "";
            }
          }
          break;
        case "max_usd":
          if (value === undefined) {
            error[key] = "Max USDT field is required";
            isValidate = false;
          } else {
            const minValue = parseFloat(allState.usd_min);
            const maxValue = parseFloat(value);
            if (isNaN(maxValue) || maxValue < 0) {
              error[key] = "Enter a valid Max USDT amount!";
              isValidate = false;
            } else if (maxValue <= minValue) {
              error[key] = "Max USDT value should be greater than Min USDT!";
              isValidate = false;
            } else {
              error[key] = "";
            }
          }
          break;
        case "min_tut":
          if (value === undefined) {
            error[key] = "Min TUT field is required";
            isValidate = false;
          } else {
            const minValue = parseFloat(value);
            const maxValue = parseFloat(allState.tut_max);
            if (isNaN(minValue) || minValue < 0) {
              error[key] = "Enter a valid Min TUT amount!";
              isValidate = false;
            } else if (minValue >= maxValue) {
              error[key] = "Min TUT value should be less than Max TUT!";
              isValidate = false;
            } else {
              error[key] = "";
            }
          }
          break;
        case "max_tut":
          if (value === undefined) {
            error[key] = "Max TUT field is required";
            isValidate = false;
          } else {
            const minValue = parseFloat(allState.tut_min);
            const maxValue = parseFloat(value);
            if (isNaN(maxValue) || maxValue < 0) {
              error[key] = "Enter a valid Max TUT amount!";
              isValidate = false;
            } else if (maxValue <= minValue) {
              error[key] = "Max TUT value should be greater than Min TUT!";
              isValidate = false;
            } else {
              error[key] = "";
            }
          }
          break;
        case "tut_fee":
          if (value === undefined) {
            error[key] = "TUT Fee field is required";
            isValidate = false;
          } else if (value < 0) {
            error[key] = "Enter valid TUT fee amount!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "usdt_fee":
          if (value === undefined) {
            error[key] = "USDT Fee field is required";
            isValidate = false;
          } else if (value < 0) {
            error[key] = "Enter valid USDT fee amount!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "one_tut":
          if (value === undefined) {
            error[key] = "Value of TUT with respect to USDT is required";
            isValidate = false;
          } else if (value < 0) {
            error[key] = "Enter valid USDT amount!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };

  /******************************************************************************************/
  const updateMinMaxValue = (item) => {
    let isValid = handleValidaton({
      min_usd: allState.usd_min,
      max_usd: allState.usd_max,
      min_tut: allState.tut_min,
      max_tut: allState.tut_max,
      value: allState.one_tut,
      tut_fee: allState.tut_fee,
      usdt_fee: allState.usdt_fee,
    });
    if (isValid) {
      setLoading(true);
      const data = {
        min_usdt: allState.usd_min,
        max_usdt: allState.usd_max,
        min_tut: allState.tut_min,
        max_tut: allState.tut_max,
        value_of_tut: allState.one_tut,
        usdt_fees: allState.usdt_fee,
        tut_fees: allState.tut_fee,
      };
      // if (record?.id) {
      //   data.id = record.id;
      // }
      setTimeout(() => {
        dispatch(createTrade({ data }))
          .then((response) => {
            fetchOtcDetail();
            Swal.fire({
              icon: "success",
              title: "Success!",
              confirmButtonColor: "#d3d3d3",
              text: "Record saved successfully!",
              color: "#7D80DF",
            });
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    }
  };

  /******************************************************************************************/
  return (
    <div>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>OTC Controls</Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding">
        <Row gutter={[15, 15]}>
          <Col className="responsive" md={8} xs={24}>
            <div className="cards_detail controlsCard">
              <ul style={{ color: "#000" }}>
                <li>
                  <p>USDT</p>
                </li>
                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        min_usd: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.usd_min}
                    name="usd_min"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Min"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.min_usd}</span>
                </li>

                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        max_usd: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.usd_max}
                    name="usd_max"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Max"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.max_usd}</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="responsive" md={8} xs={24}>
            <div className="cards_detail controlsCard ">
              <ul style={{ color: "#000" }}>
                <li>
                  <p>TUT</p>
                </li>
                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        min_tut: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.tut_min}
                    name="tut_min"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Min"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.min_tut}</span>
                </li>
                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        max_tut: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.tut_max}
                    name="tut_max"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Max"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.max_tut}</span>
                </li>
              </ul>
            </div>
          </Col>

          <Col className="responsive" md={8} xs={24}>
            <div className="cards_detail controlsCard ">
              <ul style={{ color: "#000" }}>
                <li>
                  <p>FEE</p>
                </li>
                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        tut_fee: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.tut_fee}
                    name="tut_fee"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Fee TUT"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.tut_fee}</span>
                </li>
                <li>
                  <InputCustom
                    onBlur={(e) => {
                      handleValidaton({
                        usdt_fee: e.target.value,
                      });
                    }}
                    min={0}
                    type="number"
                    value={allState.usdt_fee}
                    name="usdt_fee"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    label={"Fee USDT"}
                    basicInput
                    maxLength={30}
                  />
                  <span className="error">{allState.error.usdt_fee}</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="usdtInput">
          <InputCustom
            onBlur={(e) => {
              handleValidaton({
                one_tut: e.target.value,
              });
            }}
            min={0}
            type="number"
            value={allState.one_tut}
            name="one_tut"
            onChange={(e) => {
              setValue(e);
            }}
            label={"Value of 1 TUT"}
            basicInput
          />
          <p className="usdt">USDT</p>
        </div>
        <span className="error">{allState.error.one_tut}</span>

        <div className="minted">
          <p>Total TUT Minted</p>
          <p>{allState.mintedAmt}</p>
        </div>

        <div className="submitBtn">
          <ButtonCustom
            yellowBtn
            label="Submit"
            onClick={() => {
              updateMinMaxValue();
            }}
            style={{ width: "208px", height: "45px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default OtcControls;
