/** seting action types */
export const actionTypes = {
  SAVE_CHANGES_GRID_LAYOUT: "SAVE_CHANGES_GRID_LAYOUT",
  SAVE_CHANGES_FONT_SIZE_LAYOUT: "SAVE_CHANGES_FONT_SIZE_LAYOUT",
  SAVE_MOBILE_TAB: "SAVE_MOBILE_TAB",
  SAVE_CURRENT_BREAKPOINT: "SAVE_CURRENT_BREAKPOINT",
};

/*
 * Action creators for login
 */

export const saveMobileTab = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_MOBILE_TAB,
    data: data,
  });
};

export const saveCurrentBrackpoint = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_CURRENT_BREAKPOINT,
    data: data,
  });
};

export const saveChangeGridLayout = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_CHANGES_GRID_LAYOUT,
    data: data,
  });
};
