import { Breadcrumb, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import { EyeOutlined } from "@ant-design/icons";
import { getOtcTransactions, getUserTxnDetails } from "../../../redux/_actions";
import { toast } from "../../Ui/Toast/Toast";
import { getStatus, getValue, maskAddress } from "../../../Services/Utils";
import moment from "moment";
import { IS_PRODUCTION } from "../../../Services/EndPoints";
import Paginate from "../../Ui/Paginate/Paginate";
let paginationData = "";
function OtcTransactions() {
  //   let type = "send_receive";
  const columns = [
    {
      title: "S.NO.",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Txn Type",
      dataIndex: "txnType",
      key: "txnType",
    },
    {
      title: "From Address",
      dataIndex: "fromAddress",
      key: "fromAddress",
    },
    {
      title: "To Address",
      dataIndex: "toAddress",
      key: "toAddress",
    },
    {
      title: "Crypto Paid",
      dataIndex: "cryptoPaid",
      key: "cryptoPaid",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Blockchain Status",
      dataIndex: "blockchainStatus",
      key: "blockchainStatus",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      dataIndex: "View",
      key: "View",
    },
  ];

  const { TabPane } = Tabs;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState("");
  const [limit, setlimit] = useState(25);
  const [userTxn, setUserTxn] = useState();
  const [activeKey, setActiveKey] = useState("1");

  //   useEffect(() => {
  //     type = "send_receive";
  //   }, []);
  useEffect(() => {
    userTxnHistory();
  }, [pageCount]);
  /**************************************fetchUserData****************************************************/
  const userTxnHistory = () => {
    const data = {
      page: pageCount || 1,
      limit: limit,
    };
    setLoading(true);
    setTimeout(() => {
      dispatch(getOtcTransactions({ data }))
        .then((response) => {
          const res = response.data;
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setUserTxn(res);
          } else {
            setUserTxn();
          }
          console.log("RESPONSEEEE", response);
          paginationData = response?.meta?.pages;

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  /************************************** handleTabChange ****************************************************/
  //   const handleTabChange = (key) => {
  //     setPageCount(1);
  //     type =
  //       key == 1
  //         ? "send_receive"
  //         : key == 2
  //         ? "Swap"
  //         : key == 3
  //         ? "cross_chain"
  //         : "on_off_ramp";
  //     setActiveKey(key);
  //   };
  /************************************** onPressView ****************************************************/
  const onPressView = (item) => {
    const { trnx_id } = item;
    const { coin_family } = item.pay_coin;

    if (coin_family == 2) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://goerli.etherscan.io/tx/" + trnx_id
          : "https://etherscan.io/tx/" + trnx_id
      );
    } else if (coin_family == 1) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://testnet.bscscan.com/tx/" + trnx_id
          : "https://bscscan.com/tx/" + trnx_id
      );
    } else if (coin_family == 3) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://live.blockcypher.com/btc-testnet/tx/" + trnx_id
          : "https://live.blockcypher.com/btc/tx/" + trnx_id
      );
    } else if (coin_family == 4) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://mumbai.polygonscan.com/tx/" + trnx_id
          : "https://polygonscan.com/tx/" + trnx_id
      );
    } else if (coin_family == 5) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://sochain.com/tx/LTCTEST/" + trnx_id
          : "https://blockexplorer.one/litecoin/mainnet/tx/" + trnx_id
      );
    } else if (coin_family == 6) {
      return window.open(
        IS_PRODUCTION == 0
          ? "https://nile.tronscan.org/#/transaction/" + trnx_id
          : "https://tronscan.org/#/transaction/" + trnx_id
      );
    }
  };

  /************************************** dataSource ****************************************************/
  const dataSource = userTxn?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {((pageCount ? pageCount : 1) - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      txnType: (
        <div
          className="tableIconStyle "
          style={{ textTransform: "capitalize" }}
        >
          <span className="fontFamilyText">{item.type}</span>
        </div>
      ),
      fromAddress: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{maskAddress(item.from_adrs)}</span>
        </div>
      ),
      toAddress: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{maskAddress(item.to_adrs)}</span>
        </div>
      ),
      cryptoPaid: (
        <div style={{ marginLeft: 20 }} className="tableIconStyle">
          <span className="fontFamilyText">
            {item.pay_coin?.coin_symbol?.toUpperCase()}
            {item.type === "sell"
              ? null
              : ` (${item.pay_coin?.token_type?.toUpperCase()})`}
          </span>
        </div>
      ),

      amount: (
        <div style={{ marginLeft: 10 }} className="tableIconStyle">
          <span className="fontFamilyText">
            {(parseFloat(item.pay_amount) + parseFloat(item.fee)).toFixed(2)}
          </span>
        </div>
      ),
      blockchainStatus: (
        <div style={{ marginLeft: 20 }} className="tableIconStyle">
          <span
            style={{ textTransform: "capitalize" }}
            className="fontFamilyText"
          >
            {item.status}
          </span>
        </div>
      ),
      date: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {moment(item.created_at).format("DD MMM, YYYY")} |{" "}
            {moment(item.created_at).format("h:mm A")}
          </span>
        </div>
      ),
      View: (
        <div
          style={{ marginLeft: -5 }}
          onClick={() => onPressView(item)}
          className="buttonView"
        >
          <span className="fontFamilyText">
            View
            <EyeOutlined />
          </span>
        </div>
      ),
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };
  return (
    <div>
      {/* <div className="tabs__body">
        <Tabs
          activeKey={activeKey}
          //   onChange={handleTabChange}
          defaultActiveKey="1"
        > */}
      {/* <TabPane tab="Otc Transactions" key="1"> */}
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>OTC Transactions</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="large"
        />
        {/* </TabPane> */}
        {/* </Tabs>
      </div> */}

        {paginationData > 1 && (
          <Paginate
            paginationData={paginationData}
            forcePage={pageCount == "" ? 0 : pageCount - 1}
            handlePageClick={(page) => handlePageClick(page)}
          />
        )}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default OtcTransactions;
