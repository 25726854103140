import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Space, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import {
  getProposalDetails,
  getSellerDetailsOfSecondaryProposals,
} from "../../../redux/_actions/assetAction"; // Replace with actual action to fetch proposal details
import { ButtonCustom } from "../../Ui/button/ButtonCustom";

function SecondaryProposalSellerDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    proposalId,
    proposalName,
    backRoute = "/secondaryProposals",
  } = location.state || {};
  //   const proposalId = location.state?.proposalId;
  //   const proposalName = location?.state?.proposalName;
  console.log("proposalId", proposalId);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [proposalDetails, setProposalDetails] = useState([]);

  useEffect(() => {
    if (proposalId !== undefined) {
      fetchProposalDetails(proposalId);
    } else {
      navigate("/secondaryProposals");
    }
  }, [proposalId]);

  const fetchProposalDetails = (proposalId) => {
    setLoading(true);
    const data = { proposal_id: proposalId };
    console.log("DATATA", data);
    dispatch(getSellerDetailsOfSecondaryProposals({ data })) // Replace with actual action to fetch proposal details
      .then((response) => {
        console.log("RESPONSE:::", response.data);
        const res = response?.data?.rows || [];
        if (res.length > 0) {
          res.map((item, index) => (item["key"] = index + 1));
          setProposalDetails(res);
        } else {
          setProposalDetails([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
    },
    {
      title: "Token Quantity",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Per Unit Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total Consumed Token",
      dataIndex: "toal_consumed_token",
      key: "toal_consumed_token",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    // },
  ];

  // const dataSource = [
  //   {
  //     key: "1",
  //     name: "Mike",
  //     age: 32,
  //     action: (
  //       <Space>
  //         <ButtonCustom label="Pending" lytgaryBtn>
  //           Pending
  //         </ButtonCustom>
  //         <ButtonCustom label="Approved" lytgaryBtn>
  //           Approved
  //         </ButtonCustom>
  //       </Space>
  //     ),
  //   },
  // ];

  const dataSource = proposalDetails.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">{index + 1}</span>
        </div>
      ),
      seller_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.seller_wallet_username}</span>
        </div>
      ),
      token_quantity: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.token_quantity}</span>
        </div>
      ),
      price: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.per_unit_price}</span>
        </div>
      ),
      toal_consumed_token: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.total_consumed}</span>
        </div>
      ),
      //   action: (
      //     <div className="tableIconStyle">
      //       action: (
      //       <Space>
      //         <ButtonCustom label="Pending" lytgaryBtn>
      //           Pending
      //         </ButtonCustom>
      //         <ButtonCustom label="Approved" lytgaryBtn>
      //           Approved
      //         </ButtonCustom>
      //       </Space>
      //       ),
      //     </div>
      //   ),
    };
  });

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => navigate(backRoute)}
            style={{ cursor: "pointer" }}
          >
            Raised quote
          </Breadcrumb.Item>
          <Breadcrumb.Item>{proposalName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding userDatils">
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default SecondaryProposalSellerDetails;
