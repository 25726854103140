import React, { useCallback, useEffect, useState } from "react";
import "./SubAdmin.scss";
import { useDispatch } from "react-redux";
import Paginate from "../../Ui/Paginate/Paginate";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import Loader from "../../../Loader/Loader";
import { Dropdown, Select, Space, Table } from "antd";
import { DownOutlined, PlusCircleOutlined, SearchOutlined, SmileOutlined } from "@ant-design/icons";
import { toast } from "../../Ui/Toast/Toast";
import InputCustom from "../../Ui/input/InputCustom";
import { getActivityLogList, getActivityType } from "../../../redux/_actions/subAdminAction";
import moment from "moment";
import debounce from 'lodash.debounce';

export default function Activitylog({ activeTab }) {
  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [metaData, setMetaData] = useState();
  const [filter, setFilter] = useState('Filter');
  const [pageCount, setPageCount] = useState(1);
  const [activityList, setActivityList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');



  useEffect(() => {
    fetchActivityLogList(filter);
    fetchRoleList();
  }, [pageCount, search, activeTab]);

  const fetchRoleList = () => {
    setTimeout(() => {
      dispatch(getActivityType({})).then(response => {
        setActivityList(response);
      }).catch(err => {
        toast.error(err)
      })
    }, 100);
  }

  const fetchActivityLogList = (_filter) => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    if (_filter && _filter !== "Filter") {
      data.action_type = _filter;
    }
    setTimeout(() => {
      dispatch(getActivityLogList({ data })).then(response => {
        const res = response.data;
        setUserData(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>),
        email: item.email,
        ip: [<><p className="name_spacing">{item.ip}</p></>],
        action_type: item.action_type,
        created_at: moment(item.created_at).format("DD-MM-YYYY hh:mm:A"),
      }
    )
  });
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "Email id",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Action Type",
      dataIndex: "action_type",
      key: "action_type",
    },
    {
      title: "Timestamp",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  return (
    <div className="userAccess">
      <div className="userAccess_head">
        <div className="userAccess_head_right">
          <Select
            className="selectuserAcces"
            defaultValue="Filter by Action"
            style={{
              width: 170,
            }}
            value={filter ? filter : "Filter"}
            options={activityList}
            onChange={(e) => {
              setFilter(e);
              fetchActivityLogList(e);
            }}
          />
          <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          <ButtonCustom
            label="Clear"
            lytgaryBtn
            onClick={() => {
              setFilter('Filter');
              setSearch('');
              setLocalValue('');
              fetchActivityLogList();
            }}
          />
        </div>
      </div>
      <div className="userAccess_body">
        <Table

          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
        {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      </div>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}
