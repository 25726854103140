export const SMALLESTUNIT = 100000000;
export const totallimit = 10000000000000000;

export const GRID_LAYOUT = {
  lg: [
    
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 3,
      w: 18,
      minW: 5,
      minH: 5,
      resizeHandles: ["se"],
      static: true ,
      isDraggable: false,
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 0,
      h: 22,
      w: 18,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 18,
      y: 0,
      h: 21,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "BuySellTab",
      x: 37,
      y: 19,
      h: 21,
      w: 6,
      minW: 5,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Market",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 16,
      w: 18,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 18,
      y: 2,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],

  md: [
    
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 3,
      w: 18,
      minW: 5,
      minH: 5,
      resizeHandles: ["se"],
      static: true ,
      isDraggable: false,
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 0,
      h: 22,
      w: 18,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 18,
      y: 0,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "BuySellTab",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Market",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 15,
      w: 18,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 18,
      y: 2,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],

  sm: [
    
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 3,
      w: 18,
      minW: 5,
      minH: 5,
      resizeHandles: ["se"],
      static: true ,
      isDraggable: false,
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 0,
      h: 22,
      w: 18,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 18,
      y: 0,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "BuySellTab",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Market",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 15,
      w: 18,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 18,
      y: 2,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],

  xs: [
    
    {
      i: "SubHeader",
      x: 0,
      y: 0,
      h: 3,
      w: 18,
      minW: 5,
      minH: 5,
      resizeHandles: ["se"],
      static: true ,
      isDraggable: false,
      isResizable: false,
    },
    {
      i: "TradeChart",
      x: 0,
      y: 0,
      h: 22,
      w: 18,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 18,
      y: 0,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "BuySellTab",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Market",
      x: 37,
      y: 19,
      h: 20,
      w: 6,
      minW: 5,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 0,
      y: 5,
      h: 15,
      w: 18,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "MarketTrades",
      x: 18,
      y: 2,
      h: 20,
      w: 6,
      minW: 5,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],
};

export const CUSTOMISE_VIEW_LIST = [
  {
    id: "Market",
    title: "filter_options.market_label",
  },
  {
    id: "TradeChart",
    title: "filter_options.tradechart_label",
  },
  {
    id: "OrderBook",
    title: "filter_options.orderbook_label",
  },
  {
    id: "OpenOrder",
    title: "filter_options.openorder_label",
  },
  {
    id: "MarketTrades",
    title: "filter_options.markettrade_label",
  },
];