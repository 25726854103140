import { combineReducers } from "redux";
import gridReducer from "./gridlayout.reducer";
import LoginReducer from "./LoginReducer";

const appReducer = combineReducers({
  gridReducer,
  LoginReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
