import React, { useCallback, useEffect, useState } from 'react'
import { Breadcrumb, Table } from "antd";
import Loader from "../../../Loader/Loader";
import { SearchOutlined } from "@ant-design/icons";
import Paginate from '../../Ui/Paginate/Paginate';
import InputCustom from '../../Ui/input/InputCustom';
import { useDispatch } from 'react-redux';
import { getRefferralList } from '../../../redux/_actions';
import { toast } from '../../Ui/Toast/Toast';
import debounce from 'lodash.debounce';

let paginationData = '';
function ReferralReward() {
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "User Wallet Address",
      dataIndex: "wallet_address",
      key: "wallet_address",
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
      align: 'center'
    },
    {
      title: "Referral Code",
      dataIndex: "referralcode",
      key: "referralcode",
    },
    {
      title: "No of Referees",
      dataIndex: "referee",
      key: "referee",
      align: 'Center'
    },
  ];
  const dispatch = useDispatch();
  const limit = 25;
  const [isLoading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState('');
  const [pageCount, setPageCount] = useState('');
  const [search, setSearch] = useState('');
  const [reffList, setReffList] = useState();

  useEffect(() => {
    fetchReffList();
  }, [pageCount, search])

  /************************************** fetchReffList ****************************************************/
  const fetchReffList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount || 1,
      search: search
    };
    setTimeout(() => {
      dispatch(getRefferralList({ data })).then((response) => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => (item["key"] = index + 1));
          setLoading(false);
          paginationData = response?.meta?.pages || 0
          setReffList(res);
        } else {
          setLoading(false);
          setReffList();
          paginationData = 0
        }
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });

    }, 100);
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce((search) => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount('')
  }, 1000),
    [],
  );
  /************************************** dataSource ****************************************************/
  const dataSource = reffList?.map((item, index) => {
    return {
      key: item.key,
      sno: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
      userId: (<div style={{ marginLeft: 15 }} className="tableIconStyle "><span className="fontFamilyText">{item.user_id}</span></div>),
      user_name: (<div className="tableIconStyle"><span className="fontFamilyText">{item?.user_wallet_relation?.wallet_name}</span></div>),
      wallet_address: (<div className="tableIconStyle"><span className="fontFamilyText">{item?.user_wallet_relation?.wallet_address}</span></div>),
      email: (<div className="tableIconStyle "><span className="fontFamilyText">{item.user_wallet_relation?.email}</span></div>),
      referralcode: (<div className="tableIconStyle"><span style={{ marginLeft: 15 }} className="fontFamilyText">{item.referral_code}</span></div>),
      referee: (<div className="tableIconStyle"><span className="fontFamilyText">{item.referral_count}</span></div>),
    }
  })
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  return (
    <div>
      <>
        {" "}
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Referral & Rewards</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="appCustomisation commonPadding">
          <div className="annoucements_block_multiData">
            <div style={{ justifyContent: 'end', display: 'flex' }}>
              <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
            </div>
          </div>
          {/* ----------------------------------------------------------- */}
          <div style={{ marginTop: 15 }}>
            <Table dataSource={dataSource} columns={columns} pagination={false} size="large" />
          </div>
          {paginationData > 1 && <Paginate paginationData={paginationData} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
        </div>
      </>
      <Loader isLoading={isLoading} />
    </div>
  )
}

export default ReferralReward