import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCallGet, apiCallPost } from "../../Services/ApiClient";
import { ENABLE_DISABLE_2FA_STATUS, GOOGLE_2FA_STATUS, GOOGLE_AUTH_SECRET_KEY, GOOGLE_AUTH_VERIFY } from "../../Services/EndPoints";
import { USER_AUTH } from "./types";
import commonError from "../../Components/Common/Index";

/************************************** updateCurrency ****************************************************/
export const check2FaStatus = ({ }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(GOOGLE_2FA_STATUS).then(response => {
        const res = response.data;
        //console.log('res check2FaStatus::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('check2FaStatus er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/************************************** enableDisable2Fa ****************************************************/
export const enableDisable2Fa = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ENABLE_DISABLE_2FA_STATUS, data).then(response => {
        const res = response.data;
        //console.log('res enableDisable2Fa::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('enableDisable2Fa er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** secretKey_2FA ****************************************************/
export const secretKey_2FA = ({ }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(GOOGLE_AUTH_SECRET_KEY,).then(response => {
        const res = response.data;
        //console.log('res secretKey_2FA::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('secretKey_2FA er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** verify_2FA ****************************************************/
export const verify_2FA = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GOOGLE_AUTH_VERIFY, data).then(response => {
        const res = response.data;
        console.log('res verify_2FA::::::', res);
        setUser2FASuccess(dispatch, res)
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('verify_2FA er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** setUser2FASuccess ****************************************************/
export const setUser2FASuccess = (dispatch, value) => {
  dispatch({
    type: USER_AUTH,
    payload: value,
  });
  console.log('setUser2FASuccess ::::::', value);
};