import React, { useState } from "react";
import { Col, Row } from "antd";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import { REACT_APP_DOMAIN_KEY } from "../../../Services/EndPoints";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import InputCustom from "../../Ui/input/InputCustom";
import TriskelLogo from '../../Assets/AdminImages/TriskelLogo.png'
import TriskalLoginDarkLogo from '../../Assets/AdminImages/TriskalLoginDarkLogo.png'
import "./ResetPassword.scss";
import { toast } from "../../Ui/Toast/Toast";
import * as Constants from '../../../constants/constants';
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordForAdmin } from "../../../redux/_actions/LoginAction";
import Swal from "sweetalert";
import { getData } from "../../../Services/Utils";

function ResetPassword() {
  const initilaObj = {
    password: "",
    confirmPassword: "",
    error: {
      password: "",
      confirmPassword: "",
    },
  };
  const navigate = useNavigate()
  const loginId = localStorage.getItem("loginId");
  // let tab_data = login?.admin_user_access_data && login?.admin_user_access_data;
  console.log("tab_data", loginId)
  const dispatch = useDispatch();
  const [allState, setAllState] = useState(initilaObj);
  const [isLoading, setLoading] = useState(false);
  const theme = useOutletContext() || getData(Constants.APP_THEME);

  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "password":
          if (value === undefined || value?.length === 0) {
            error[key] = "Password Field Is Required";
            isValidate = false;
          } else if (value.trim().length < 8) {
            error[key] = "Password too short!";
            isValidate = false;
          } else if (value === allState.oldPassword) {
            error[key] = "Old password and new password cannot be same!";
            isValidate = false;
          }else if (!Constants.PASS_REGEX.test(value)) {
            error[key] = "New password must include minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "confirmPassword":
          if (value === undefined || value?.length === 0) {
            error[key] = "Confirm Password Field is Required";
            isValidate = false;
          } else if (value.trim().length < 8) {
            error[key] = "Password too short !";
            isValidate = false;
          } else if (value !== allState.password) {
            error[key] = "Passwords didn't match!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };
  const resetState = () => {
    setAllState(initilaObj);
  }
  const setValue = (event) => {
    if (event.target.value !== "") {
      var error = allState?.error;
      error[event.target.name] = ""
      setAllState({ ...allState, error: { ...error } });
    }
    setAllState({ ...allState, [event.target.name]: event.target.value });

  };
  const resetPass = () => {
    let isValid = handleValidaton({
      password: allState.password,
      confirmPassword: allState.confirmPassword
    })
    if (isValid) {
      setLoading(true);
      const data = {
        id: parseInt(loginId),
        password: allState.password
      }
      setTimeout(() => {
        dispatch(resetPasswordForAdmin({ data })).then(res => {
          setLoading(false);
          Swal({
            icon: 'success',
            title: 'Congratulations!',
            text: `Password updated successfully!`,
            color: '#7D80DF',
          })
          resetState(initilaObj)
          setTimeout(() => {
            window.location.replace("/" + REACT_APP_DOMAIN_KEY);
          }, 3000);
        }).catch(err => {
          setLoading(false);
          toast.error(err)
        })
      }, 100);
    }
  }
  return (
    <div className="flexAuto">
      <div className="forgotScreen">
        <div className="container">
          <div>
            <Row>
              <Col xs={24} md={14} className="forgotScreen__leftCol">
                <LoginDetail topimage={
                  <span>
                    {(theme == "lightTheme" || theme == undefined) ? <img src={TriskelLogo} width={300} /> : <img src={TriskalLoginDarkLogo} width={300} />}
                  </span>
                  // <span><img src={TriskelLogo} width={300} /></span>
                } />
              </Col>
              <Col xs={24} md={10} className="forgotScreen__rytCol">
                <h2>Reset Your Password</h2>

                <div className="forgotScreen__loginLeft">
                  <div className="forgotScreen__inputSec">
                    <div className="forgotScreen__innerInput">
                      <InputCustom passwordInput inputCustum eyeicon
                        label="Password"
                        type={"password"}
                        placeholder={'Enter Password'}
                        onChange={(e) => setValue(e)}
                        value={allState.password}
                        name={"password"}
                        maxLength={50}
                      />
                      <p className="error">{allState.error.password}</p>
                    </div>
                    <div className="forgotScreen__innerInput">
                      <InputCustom passwordInput inputCustum eyeicon
                        label="Confirm Password"
                        type={"password"}
                        placeholder={'Enter Confirm Password'}
                        onChange={(e) => setValue(e)}
                        value={allState.confirmPassword}
                        name={"confirmPassword"}
                        maxLength={50}
                      />
                      <p className="error">{allState.error.confirmPassword}</p>
                    </div>
                  </div>

                  <div className="loginScreen__iconBtn">
                    <ButtonCustom yellowBtn large label="Next" onClick={() => resetPass()} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ResetPassword;
