import React, { useState } from "react";
import { Col, Row } from "antd";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import InputCustom from "../../Ui/input/InputCustom";
import TriskelLogo from '../../Assets/AdminImages/TriskelLogo.png'
import TriskalLoginDarkLogo from '../../Assets/AdminImages/TriskalLoginDarkLogo.png'
import "./ForgotPassword.scss";
import { toast } from "../../Ui/Toast/Toast";
import * as Constants from '../../../constants/constants';
import { useOutletContext } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../redux/_actions/LoginAction";
import Swal from "sweetalert";
import { getData } from "../../../Services/Utils";
import { REACT_APP_DOMAIN_KEY } from "../../../Services/EndPoints";

function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const theme = useOutletContext() || getData(Constants.APP_THEME);

  const resetPass = () => {
    if (email.trim().length == 0) {
      return toast.error('Enter email');
    } else if (!Constants.EMAIL_REGEX.test(email)) {
      return toast.error('Enter valid email');
    } else {
      setLoading(true);
      const data = {
        email: email
      }
      setTimeout(() => {
        dispatch(resetPassword({ data })).then(res => {
          setLoading(false);
          Swal({
            icon: 'success',
            title: 'Congratulations!',
            text: `New password has been sent to your email. Please check and login again!`,
            color: '#7D80DF',
          }).then((result) => {
            window.location.replace("/" + REACT_APP_DOMAIN_KEY);
          });
          setEmail('')
        }).catch(err => {
          setLoading(false);
          toast.error(err)
        })
      }, 100);
    }
  }
  return (
    <div className="flexAuto">
      <div className="forgotScreen">
        <div className="container">
          <div>
            <Row>
              <Col xs={24} md={14} className="forgotScreen__leftCol">
                <LoginDetail topimage={
                  <span>
                    {(theme == "lightTheme" || theme == undefined) ? <img src={TriskelLogo} width={300} /> : <img src={TriskalLoginDarkLogo} width={300} />}
                  </span>
                  // <span><img src={TriskelLogo} width={300} /></span>
                } />
              </Col>
              <Col xs={24} md={10} className="forgotScreen__rytCol">
                <h2>Reset Your Password</h2>

                <div className="forgotScreen__loginLeft">
                  <div className="forgotScreen__inputSec">
                    <div className="forgotScreen__innerInput">
                      <InputCustom basicInput inputCustum
                        label="Email"
                        placeholder={'Enter Email'}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        maxLength={100}
                      />
                    </div>
                  </div>

                  <div className="loginScreen__iconBtn">
                    <ButtonCustom yellowBtn large label="Next" onClick={() => resetPass()} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default ForgotPassword;
