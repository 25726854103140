import { toast } from "../../Components/Ui/Toast/Toast";
import {
  apiCalGetFile,
  apiCallDelete,
  apiCallGet,
  apiCallGetQuery,
  apiCallPost,
  apiCallPostFile,
} from "../../Services/ApiClient";
import {
  ADD_ASSETS,
  ADD_PROPOSAL,
  ASSETS_DELETE_API,
  ASSETS_LIST_API,
  ASSETS_LIST_DROPDOWN_API,
  DELETE_PROPOSAL,
  UPDATE_PROPOSAL,
  PROPOSAL_LIST,
  STO_TRANSACTIONS_LIST,
  UPDATE_AGENT_IN_PROPOSAL,
  UPDATE_ASSETS,
  UPLOAD_FILE_DATA_PROPOSAL,
  EDIT_PROPOSAL,
  GET_PROPOSAL_LIST,
  MINTING_STATUS_UPDATE,
  GET_INVESTED_MINTING_LIST,
  GET_INVESTED_APP_LIST,
  SECONDARY_PROPOSAL_LIST,
  TRANSFER_PROPOSAL_DATA,
  SECONDARY_MARKET_TRANSACTION,
  SECONDARY_MARKET_SELLER_DETAILS,
  SECONDARY_MARKET_UNBLOCK,
  SECONDARY_MARKET_UPDATE_UNBLOCK_STATUS,
  SECONDARY_MARKET_UPDATE_TRANSACTION_AFTER_TRANSFER,
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** Add Assets ****************************************************/
export const addAssets = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_ASSETS, data)
        .then((response) => {
          const res = response.data;
          // console.log('res addAssets::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("addAssets er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Assets List ****************************************************/
export const getAssetsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(ASSETS_LIST_API, data)
        .then((response) => {
          const res = response.data;
          // console.log('res getAssetsList::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getAssetsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Delete Assets ****************************************************/
export const deleteAssets = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(ASSETS_DELETE_API, data)
        .then((response) => {
          const res = response.data;
          // console.log('res deleteAssets::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("deleteAssets er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Update Assets ****************************************************/
export const updateAssets = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_ASSETS, data)
        .then((response) => {
          const res = response.data;
          // console.log('res updateAssets::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("updateAssets er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Add Assets ****************************************************/
export const addProposal = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_PROPOSAL, data)
        .then((response) => {
          const res = response.data;
          // console.log('res addProposal::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("addProposal er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const deleteProposal = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(DELETE_PROPOSAL, data)
        .then((response) => {
          console.log("response in api", response);
          const res = response;
          console.log("res in api", res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("deletePROPOSAL er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const editProposal = ({ data }) => {
  console.log("Edit proposal data", data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_PROPOSAL, data)
        .then((response) => {
          const res = response.data;
          // console.log('res addProposal::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("editProposal er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const editAfterProposal = ({ data }) => {
  console.log("Edit proposal data", data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(EDIT_PROPOSAL, data)
        .then((response) => {
          const res = response.data;
          // console.log('res addProposal::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("editProposal er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

// export const updateProposalAgent = ({ data }) => {
//   console.log("Edit proposal agent data", data);
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       apiCallPost(UPDATE_AGENT_IN_PROPOSAL, data)
//         .then((response) => {
//           const res = response.data;
//           // console.log('res addProposal::::::', response);
//           resolve(res);
//         })
//         .catch((error) => {
//           if (error?.message == "Unable to decode data.") {
//             commonError();
//           } else {
//             const errorMessage = error.message || error;
//             console.log("editProposal er::::::", error);
//             reject(errorMessage);
//           }
//         });
//     });
//   };
// };
/************************************** Assets List for dropdown ****************************************************/
export const getAssetsListDropdown = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGet(ASSETS_LIST_DROPDOWN_API)
        .then((response) => {
          const res = response;
          // console.log('res getAssetsListDropdown::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getAssetsListDropdown er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Add Assets ****************************************************/
export const uploadProposalFileData = ({ formData }) => {
  return (dispatch) => {
    console.log("data fileUpload::::::", formData);
    return new Promise((resolve, reject) => {
      apiCallPostFile(UPLOAD_FILE_DATA_PROPOSAL, formData)
        .then((response) => {
          const res = response.data;
          // console.log('res fileUpload::::::', res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('fileUpload er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** Add Assets ****************************************************/
export const getProposalList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(PROPOSAL_LIST, data)
        .then((response) => {
          const res = response.data;
          // console.log('res proposalList::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("proposalList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getStoTransactionList = ({ data }) => {
  return (dispatch) => {
    console.log("DATA IN ACTION DISPATCH ", data);
    return new Promise((resolve, reject) => {
      apiCallPost(STO_TRANSACTIONS_LIST, data)
        .then((response) => {
          const res = response.data;
          // console.log('res proposalList::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("proposalList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

/****************STO APIS***********************/
export const getProposalsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGetQuery(GET_PROPOSAL_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getMintingInvestmentRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_INVESTED_MINTING_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const mintingStatusUpdate = (data) => {
  return (dispatch) => {
    console.log("data in useraction", data);
    return new Promise((resolve, reject) => {
      apiCallPost(MINTING_STATUS_UPDATE, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getAppRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_INVESTED_APP_LIST, data)
        .then((response) => {
          const res = response.data;
          //console.log('res getUserTxnDetails::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

/************************ SECONDARY APIS *****************************/
export const getSecondaryProposalsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGetQuery(SECONDARY_PROPOSAL_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getSecondaryTransactionsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGetQuery(SECONDARY_MARKET_TRANSACTION, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getSellerDetailsOfSecondaryProposals = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(SECONDARY_MARKET_SELLER_DETAILS, data)
        .then((response) => {
          const res = response.data;
          //console.log('res getUserTxnDetails::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getUnblockRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(SECONDARY_MARKET_UNBLOCK, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const updateUnblockRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(SECONDARY_MARKET_UPDATE_UNBLOCK_STATUS, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const updateTransactionAfterTransfer = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(SECONDARY_MARKET_UPDATE_TRANSACTION_AFTER_TRANSFER, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};
