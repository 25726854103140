import { createStore, applyMiddleware, legacy_createStore } from "redux";
import rootReducer from "../_reducers";
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
          key: 'root',
          storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, {}, applyMiddleware(ReduxThunk));
let persistor = persistStore(store)
// const store = legacy_createStore(rootReducer);
export default { store, persistor }

