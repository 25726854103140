
import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import "./AppCustomisation.scss";
import { Breadcrumb, Col, Row, Table, Tabs } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ModalCommon from "../ModalCommon/ModalCommon";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import { addCurrency, addToken, fileUpload, getCurrencyList, getTokenList, removeCurrency, removeToken, searchCurrency, searchToken, updateCurrency } from "../../../redux/_actions";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import { CopyIcon } from "../../Assets/Images/SvgImgs";
import Paginate from "../../Ui/Paginate/Paginate";
import * as Constants from '../../../constants/constants';
import { validateTRXAddress } from "../../../Services/Utils";
import axios from "axios";
import { BASE_IMAGE_URL, GECKO_SYMBOL_URL } from "../../../Services/EndPoints";
import debounce from 'lodash.debounce';
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

let paginationData = '';
let paginationDataToken = '';
let active_key = 1;
function AppCustomisation() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const TokenColumn = [
    {
      title: "Token Logo",
      dataIndex: "tokenLogo",
      key: "tokenLogo",
    },
    {
      title: "Token Contract Address",
      dataIndex: "contractAddr",
      key: "contractAddr",
    },
    {
      title: "Token Name",
      dataIndex: "currencyName",
      key: "currencyName",
    },
    {
      title: "Token Type",
      dataIndex: "tokenType",
      key: "tokenType",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      align: 'center'
    },
  ];
  const CurrencyColumn = [
    {
      title: "Currency Logo",
      dataIndex: "currencyLogo",
      key: "currencyLogo",
    },
    {
      title: "Currency Name",
      dataIndex: "currencyName",
      key: "currencyName",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
    },
  ];
  const tokenData = [
    {
      name: 'Ethereum',
      value: "ERC20",
      img: Constants.ETH_IMG,
      coin_family: 2
    },
    {
      name: 'Polygon',
      value: "MATIC ERC20",
      img: Constants.MATIC_IMG,
      coin_family: 4
    },
    {
      name: 'Tron',
      value: "TRC20",
      img: Constants.TRON_IMG,
      coin_family: 6
    },
  ];
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localValue, setLocalValue] = useState('')
  /************************************** Currency states ****************************************************/
  const [currencyList, setCurrencyList] = useState();
  const [pageCount, setPageCount] = useState('');
  const [currencyLogo, setCurrencyLogo] = useState('');
  const [currencyRes, setCurrencyRes] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('')
  /************************************** Token states ****************************************************/
  const [tokenList, setTokenList] = useState();
  const [pageCountToken, setPageCountToken] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState('');
  const [selected_coin_family, setCoinFamily] = useState(2);
  const [selectedCoinSymbol, setCoinSymbol] = useState(tokenData[0]?.value);
  const [searchToken1, setSearchToken] = useState('');


  const [limit, setlimit] = useState(25);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    //permission();
    active_key = 1
  }, [])

  useEffect(() => {
    fetchCurrencyList();
  }, [pageCount, search]);

  useEffect(() => {
    fetchTokenList();
  }, [pageCountToken, searchToken1]);
  const permission = () => {
    if(tab_data){
      let editPermission = tab_data.find(d => d.name === "App Customization");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else{
      return true;
    }
  }
  /************************************** data for curency table ****************************************************/
  const dataSource = currencyList?.map((item, index) => {
    return {
      key: item.key,
      currencyLogo: (<div className="imgsec"><div><img src={item.image_url?.includes('https') || item.image_url?.includes('http') ? item.image_url : BASE_IMAGE_URL + item.image_url} style={{ marginLeft: 25, borderRadius: 40 }} width={40} height={40} /></div></div>),
      currencyName: (<div className="tableIconStyle"><span className="fontFamilyText">{item.name} ({item.symbol})</span></div>),
      delete: (<div style={{ marginLeft: 10 }} className={`imgsec ${!permission() ? "disabled" : ""}`}><button onClick={() => { deletePressed(item, "currency") }} className="deleteBtn"><DeleteOutlined /></button></div>),
      edit: (<div className={`imgsec ${!permission() ? "disabled" : ""}`} style={{ marginLeft: 5 }}><button onClick={() => { editPressed(item, "currency") }} className="deleteBtn"><EditOutlined /></button></div>),
    };
  });
  /************************************** data for token table ****************************************************/
  const dataSource1 = tokenList?.map((item, index) => {
    return {
      key: item.key,
      tokenLogo: (<div className="imgsec"><div>{item.coin_image ? <img src={item.coin_image} style={{ marginLeft: 25, borderRadius: 40 }} width={40} height={40} /> : <div className="Text coinImageText" style={{}}>{item.coin_name?.charAt(0).toUpperCase()}</div>}</div></div>),
      contractAddr: (<div className="tableIconStyle"><span className="fontFamilyText">{item.token_address}</span></div>),
      currencyName: (<div className="tableIconStyle"><span className="fontFamilyText">{item.coin_name.toString().length > 14 ? item.coin_name.substring(0, 15) + '...' : item.coin_name} ({item.coin_symbol.toString().length > 8 ? item.coin_symbol.substring(0, 10) + '...' : item.coin_symbol?.toUpperCase()})</span></div>),
      tokenType: (<div className="tableIconStyle"><span className="fontFamilyText">{item.is_token == 1 ? item.coin_family == 2 ? "ERC20" : item.coin_family == 4 ? "MATIC ERC20" : "TRC20" : "--"}</span></div>),
      delete: (<div style={{ marginLeft: 25 }} className={`imgsec ${!permission() ? "disabled" : ""}`}><button onClick={() => { deletePressed(item, "token") }} className="deleteBtn"><DeleteOutlined /></button></div>),
    };
  });
  /************************************** handleTabChange ****************************************************/
  const handleTabChange = (key) => {
    active_key = key
    setLocalValue('');
    setSearch('');
    setSearchToken('');
    setPageCountToken('');
    setPageCount('');
    setActiveKey(key);
  };
  /************************************** fetchCurrencyList ****************************************************/
  const fetchCurrencyList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount || 1,
      search: search
    };
    setTimeout(() => {
      dispatch(getCurrencyList({ data })).then((response) => {
        const res = response.data
        if (res.length > 0) {
          res.map((item, index) => (item["key"] = index + 1));
          setLoading(false);
          paginationData = response?.meta?.pages || 0
          setCurrencyList(res);
        } else {
          setLoading(false);
          setCurrencyList();
          paginationData = 0
        }
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });

    }, 100);
  };
  /************************************** fetchTokenList ****************************************************/
  const fetchTokenList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCountToken || 1,
      search: searchToken1
    };
    setTimeout(() => {
      dispatch(getTokenList({ data })).then((response) => {
        const res = response.data
        if (res.length > 0) {
          res?.map((item, index) => (item["key"] = index + 1));
          setLoading(false);
          paginationDataToken = response?.meta?.pages || 0
          setTokenList(res);
        } else {
          setLoading(false);
          paginationDataToken = 0
          setTokenList();
        }
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };
  /**************************************handleCancel ****************************************************/
  const handleCancel = () => {
    setShowView(false);
    setIsModalOpen(false);
    setIsEdit(false)
  };
  /**************************************deletePressed****************************************************/
  const deletePressed = (item, type) => {
    if (permission()) {
      Swal.fire({
        title: `Are you sure you want to delete this ${type} ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#d3d3d3',
        // cancelButtonColor: '#bec3ca',
        confirmButtonText: 'Yes',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) {
          type == 'currency' ? deleteCurrency(item) : deleteToken(item);
        }
      });
    }
  };
  /**************************************editPressed****************************************************/
  const editPressed = (item, type) => {
    if (permission()) {
      setCurrencyLogo('');
      if (inputRef.current) {
        inputRef.current.value = null;
      }
      setIsEdit(true);
      setCurrencyRes(item);
      setIsModalOpen(true);
    }
  };
  /**************************************deleteToken****************************************************/
  const deleteToken = (item) => {
    setLoading(true);
    const data = {
      coin_id: item.coin_id,
    };
    setTimeout(() => {
      dispatch(removeToken({ data })).then((res) => {
        fetchTokenList();
      })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  /**************************************deleteCurrency****************************************************/
  const deleteCurrency = (item) => {
    setLoading(true);
    const data = {
      currency_id: item.currency_id,
    };
    setTimeout(() => {
      dispatch(removeCurrency({ data })).then((res) => {
        fetchCurrencyList();
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };
  /**************************************updateCurrencyName****************************************************/
  const updateCurrencyName = (text) => {
    debounceLoadData(text);
  };
  /**************************************updateSearch****************************************************/
  const updateSearch = (search, type) => {
    // type == 1 ? setSearch(search) : setSearchToken(search)
    setLocalValue(search);
    debounceData(search, type)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce((search, type) => {
    if (type == 1) {
      setSearch(search.trim());
      setLocalValue(search.trim());
      setPageCount('')
    } else {
      setSearchToken(search)
      setPageCountToken('')
    }
  }, 1000),
    [],
  );
  /**************************************debounceLoadData****************************************************/
  const debounceLoadData = useCallback(debounce(text => {
    if (text.trim().length > 3) {
      search_Currency(text);
    }
  }, 1000),
    [],
  );
  /**************************************search_Currency****************************************************/
  const search_Currency = (name) => {
    setLoading(true);
    setTimeout(() => {
      const data = {
        name: name
      }
      dispatch(searchCurrency({ data })).then((res) => {
        setLoading(false);
        setCurrencyRes(res);
      }).catch((err) => {
        toast.error(err);
        setCurrencyRes('');
        setLoading(false);
      });
    }, 100);
  }
  /**************************************addCurrency****************************************************/
  const add_Currency = () => {
    if (currencyRes == '') {
      return toast.error('All fields are mandatory!');
    }
    setLoading(true);
    const data = {
      name: currencyRes?.name,
      symbol: currencyRes?.symbol,
      sign: currencyRes?.sign,
      image_url: currencyRes?.image_url,
    };
    setTimeout(() => {
      dispatch(addCurrency({ data })).then((res) => {
        pageCount == 1 ? setPageCount('') : setPageCount(1);
        // fetchCurrencyList();
        setLoading(false);
        setIsModalOpen(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Currency added successfully!',
          confirmButtonColor: '#d3d3d3'
        })
      }).catch((err) => {
        setCurrencyRes('')
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };
  /**************************************update_Currency****************************************************/
  const update_Currency = () => {
    if (currencyRes == '') {
      return toast.error('All fields are mandatory!');
    }
    setLoading(true);
    const data = {
      currency_id: currencyRes?.currency_id,
      image_url: uploadedImage,
    };
    setTimeout(() => {
      dispatch(updateCurrency({ data })).then((res) => {
        setLoading(false);
        setIsModalOpen(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Currency updated successfully!',
          confirmButtonColor: '#d3d3d3'
        });
        setIsEdit(false);
        fetchCurrencyList();
      }).catch((err) => {
        setCurrencyRes('')
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };
  /**************************************onPressAdd****************************************************/
  const onPressAdd = () => {
    setCurrencyRes('');
    setCoinSymbol(tokenData[0]?.value);
    setContractAddress('');
    setSearch('');
    setSearchToken('');
    setLocalValue('');
    setName('');
    setDecimals('');
    setSymbol('');
    setCurrencyLogo('');
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setIsModalOpen(true);
  }
  /**************************************donePressed****************************************************/
  const donePressed = () => {
    if (name.trim().length == 0 || symbol.trim().length == 0 || decimals.trim().length == 0)
      return toast.error('All fields are mandatory!');
    else fetchGeckSymbol();
  }
  /**************************************fetchGeckSymbol****************************************************/
  const fetchGeckSymbol = () => {
    setLoading(true);
    setTimeout(() => {
      axios.get(GECKO_SYMBOL_URL).then((res) => {
        let aliasGecko = "gicko_alias"
        const list = res.data
        if (list?.status?.error_code == 429) {
          return add_Token('gicko_alias');
        } else {
          list.map((item, index) => {
            if (symbol.toLowerCase() == item.symbol?.toLowerCase()) {
              aliasGecko = item.id
            }
          })
          add_Token(aliasGecko);
        }
      }).catch((error) => {
        add_Token('gicko_alias')
      });
    }, 100);
  }
  /**************************************addToken****************************************************/
  const add_Token = (gicko_alias) => {
    const data = {
      token_address: contractAddress,
      decimals: decimals,
      coin_name: name,
      coin_symbol: symbol,
      coin_family: selected_coin_family,
      token_type: selected_coin_family == 1 ? 'BEP20' : selected_coin_family == 6 ? 'TRC20' : 'ERC20',
      coin_gicko_alias: gicko_alias,
    };
    setTimeout(() => {
      dispatch(addToken({ data })).then((res) => {
        setLoading(false);
        setIsModalOpen(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Token added successfully!',
          confirmButtonColor: '#d3d3d3'//'#7D80DF',
        })
        pageCountToken == 1 ? setPageCountToken('') : setPageCountToken(1);
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });
    }, 100);
  };
  /**************************************onPressPaste****************************************************/
  const onPressPaste = async () => {
    const address = await navigator.clipboard.readText();
    validateContractAddress(address)
  }
  /**************************************validateContractAddress****************************************************/
  const validateContractAddress = (address) => {
    setContractAddress(address);
    if (selected_coin_family != 3 && selected_coin_family != 6 && !/^(0x){1}[0-9a-fA-F]{40}$/i.test(address)) {
      updateValues();
      return;
    } else if (selected_coin_family == 6 && !validateTRXAddress(address)) {
      updateValues();
      return;
    } else {
      search_Token(address);
    }
  }
  /**************************************updateValues****************************************************/
  const updateValues = () => {
    setName('');
    setSymbol('');
    setDecimals('');
  }
  /**************************************searchToken****************************************************/
  const search_Token = (address) => {
    setLoading(true);
    const data = {
      token_address: address,
      coin_family: selected_coin_family,
    };
    setTimeout(() => {
      dispatch(searchToken({ data })).then((res) => {
        setName(res.name);
        setSymbol(res.symbol);
        setDecimals(res.decimals || '');
        setLoading(false);
      }).catch((err) => {
        toast.error('Invalid contract address');
        setLoading(false);
      });
    }, 100);
  };
  /************************************** uploadFile ****************************************************/
  const uploadFile = (e) => {
    const formData = new FormData();
    const imagedata = e.target.files[0];
    // console.log('chk image data:::::::', imagedata)
    setCurrencyLogo(imagedata);
    formData.append("files", imagedata);
    // formData.append('files', { name: imagedata.name, type: imagedata.type });
    // console.log('formData1111 data:::::::', formData)
    dispatch(fileUpload({ formData })).then((res) => {
      setUploadedImage(res?.file_name)
    }).catch((err) => {
      toast.error(err);
      setLoading(false);
    });
  }
  /************************************** onchangeText ****************************************************/
  const onchangeText = (text) => {
    validateContractAddress(text);
  }
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /************************************** handlePageClickToken ****************************************************/
  const handlePageClickToken = (page) => {
    setPageCountToken(page.selected + 1)
  }
  /************************************** handleSelect ****************************************************/
  const handleSelect = (e) => {
    e == 'ERC20' ? setCoinFamily(2) : e == 'MATIC ERC20' ? setCoinFamily(4) : setCoinFamily(6)
    setCoinSymbol(e);
    setContractAddress('');
    setName('');
    setDecimals('');
    setSymbol('');
  }
  return (
    <div>
      <>
        {" "}
        <div className="breadcrumb assigned">
          <Breadcrumb>
            <Breadcrumb.Item>App Customization</Breadcrumb.Item>
          </Breadcrumb>
          <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
        </div>
        <div className="appCustomisation commonPadding">

          <div className=" addnewSec">
            <ButtonCustom disabled={!permission()} className={`${!permission() ? "disabled" : ""}`} label="Add New" garyBtn onClick={() => onPressAdd()} />
          </div>
          <InputCustom basicInput placeholder={activeKey === "1" ? "Search Currency..." : "Search Token ..."} maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value, activeKey) }} />
          {/* ----------------------------------------------------------- */}
          <div className="tabs__body" >
            <Tabs activeKey={activeKey} style={{ marginTop: 15, display: 'flex' }} onChange={handleTabChange} defaultActiveKey="1">
              <TabPane tab="Currency Preference List" key="1">
                <Table dataSource={dataSource} columns={CurrencyColumn} pagination={false} size="large" />
              </TabPane>
              <TabPane tab="Token Addition" key="2">
                <Table dataSource={dataSource1} columns={TokenColumn} pagination={false} size="large" />
              </TabPane>
            </Tabs>
          </div>
          {/* ----------------------------------------------------------- */}
          <ModalCommon
            headTittle={activeKey === "1" ? "Currency Preference List" : "Add Custom Token"}
            isModalVisible={isModalOpen}
            handleCancel={handleCancel}
          >
            <div className="modalApp">
              {activeKey === "1" ? (
                <>
                  <InputCustom style={{ textTransform: 'capitalize' }} basicInput inputCustum label="Currency Name" disabled={(isEdit || currencyRes?.name) ? true : false} onChange={(e) => updateCurrencyName(e.target.value)} value={currencyRes?.name} />
                  <InputCustom basicInput inputCustum label="Currency Code" disabled value={currencyRes?.symbol} />
                  <InputCustom basicInput inputCustum label="Currency Symbol" disabled value={currencyRes?.sign} />
                  <InputCustom basicInput inputCustum label="Currency Logo" disabled value={currencyRes?.image_url ? currencyRes?.image_url?.includes('https') || currencyRes?.image_url?.includes('http') ? currencyRes?.image_url : BASE_IMAGE_URL + currencyRes?.image_url : currencyRes?.image_url} showView={showView} onClickDiv={() => { isEdit ? setShowView(true) : setShowView(false) }} editText={isEdit ? 'Edit' : ''} />
                  {(isEdit && showView) &&
                    <>
                      <InputCustom fileInput label="New Currency Logo" editText={currencyLogo ? 'Clear' : ''} onClickDiv={() => { currencyLogo && (inputRef.current.value = null); setCurrencyLogo('') }} />
                      <Col md={12} lg={12}><input style={{ width: '100%' }} ref={inputRef} accept="image/png, image/jpeg" type="file" onChange={(e) => uploadFile(e)} /></Col>
                      <span style={{ color: 'red', fontSize: 12, marginTop: -10 }} className="fontFamilyText ">Note: Only PNG,JPG & JPEG files</span>
                    </>
                  }
                  {(isEdit && currencyLogo) ?
                    <div className="addnewSec"><ButtonCustom label={"Update"} yellowBtn onClick={() => { update_Currency() }} /></div>
                    : (isEdit && !currencyLogo) ?
                      null :
                      <div className="addnewSec"><ButtonCustom label={"Add New"} yellowBtn onClick={() => { add_Currency() }} /></div>}
                </>
              ) : (
                <>
                  <Row gutter={[12, 12]}>
                    <Col xs={24}>{" "}<CustomSelect onChange={(e) => handleSelect(e)} labelcustom="labelcustom" CustomSelectStyle="CustomSelectStyle" label={"Select Network"} images data={tokenData} img={tokenData} value={selectedCoinSymbol} /></Col>
                    <Col xs={24}>{" "}<InputCustom maxLength={45} basicInput inputCustum label="Contract Address" innerTxtImg inputInnerTxt value={contractAddress} onChange={(e) => onchangeText(e.target.value)} innerImg={<span onClick={() => onPressPaste()} className="copyIcon"><CopyIcon /></span>} /></Col>
                    <Col xs={24}><InputCustom basicInput inputCustum label="Token Name" value={name} disabled /></Col>
                    <Col xs={24} md={12}>{" "}<InputCustom basicInput inputCustum label="Symbol" value={symbol} disabled /></Col>
                    <Col xs={24} md={12}>{" "}<InputCustom basicInput inputCustum label="Decimals" value={decimals} disabled /></Col>
                  </Row>
                  <div className="addnewSec"><ButtonCustom label="Done" yellowBtn onClick={() => donePressed()} /></div>
                </>
              )}
            </div>
          </ModalCommon>
          {/* ----------------------------------------------------------- */}
          {active_key == "1" ? (paginationData > 1 && <Paginate paginationData={paginationData} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />) : (paginationDataToken > 1 && <Paginate paginationData={paginationDataToken} forcePage={pageCountToken == '' ? 0 : pageCountToken - 1} handlePageClick={(page) => handlePageClickToken(page)} />)}
        </div>
      </>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default AppCustomisation;
