import React from "react";
import "./Dashboard.scss";

function DetailsCard({ keyname, Valname, icon }) {
  return (
    <div className={icon ? "dashboardScreen_cards" : "dashboardScreen1_cards"}>
      {icon && <div className="dashboardScreen_cards_icon">
        <img src={icon} alt="Facebook" width={50} height={50} />
      </div>
      }
      <div className="dashboardScreen_cards_details">
        <div>{keyname} :</div>
        <div className="ml-5">{Valname}</div>
      </div>
    </div>
  );
}

export default DetailsCard;
