import React from "react";

import "./LoginDetail.scss";
function LoginDetail({headTxt, paragraphTxt ,paragraphTxt1,spanTxt,topimage,largehead,spanTxt1}) {

  return (
    <div className="loginDetail">
      <div className="loginDetail__loginContact">
       <div className="loginDetail__qrlogo">
          {topimage && topimage}
        </div>
        <div className="loginDetail__inner_Text">
          {headTxt && <h2>{headTxt}</h2>}
          {largehead && <h2 className="largehead">{largehead}</h2>}
          {paragraphTxt && (
            <p>
              {paragraphTxt}
             { spanTxt && <span>{spanTxt}</span>}
              {paragraphTxt1}
              { spanTxt1 && <span>{spanTxt1}</span>}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginDetail;
