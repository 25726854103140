import { LOGIN_API, REFERRAL_LIST_API, RESET_PASS_API, RESET_PASS_ADMIN_API } from "../../Services/EndPoints";
import { apiCallPost } from "../../Services/ApiClient";
import { LOGOUT_USERS_PERSIST, USER_AUTH } from "./types";
/************************************** Login ****************************************************/
export const login = ({ data }) => dispatch => {
          return new Promise((resolve, reject) => {
                    apiCallPost(LOGIN_API, data).then(response => {
                              const res = response.data;
                              //  console.log('res login::::::', res);
                              (res.google2fa_status != 1 && res.admin_user_access_data?.status!==1) && setUserLoginSuccess(dispatch, res);
                              resolve(res);
                    }).catch(error => {
                              const errorMessage = error.message || error;
                              //console.log('login er::::::', error);
                              reject(errorMessage);
                    });
          });
};
/************************************** resetPassword ****************************************************/
export const resetPassword = ({ data }) => dispatch => {
          return new Promise((resolve, reject) => {
                    apiCallPost(RESET_PASS_API, data).then(response => {
                              const res = response.data;
                              //console.log('res resetPassword::::::', response);
                              resolve(res);
                    }).catch(error => {
                              const errorMessage = error.message || error;
                              //console.log('resetPassword er::::::', error);
                              reject(errorMessage);
                    });
          });
};
/************************************** referral List ****************************************************/
export const getRefferralList = ({ data }) => dispatch => {
          return new Promise((resolve, reject) => {
                    apiCallPost(REFERRAL_LIST_API, data).then(response => {
                              const res = response.data;
                              // console.log('res getRefferralList::::::', response);
                              resolve(res);
                    }).catch(error => {
                              const errorMessage = error.message || error;
                              console.log('getRefferralList er::::::', error);
                              reject(errorMessage);
                    });
          });
};
export const logoutApp = ({ }) => dispatch => {
          return new Promise((resolve, reject) => {
                    setUserLogoutSuccess(dispatch, '')
          });
};
/************************************** setUserLoginSuccess ****************************************************/
export const setUserLoginSuccess = (dispatch, value) => {
          dispatch({
                    type: USER_AUTH,
                    payload: value,
          });
          //   console.log('setUserLoginSuccess ::::::', value);
};
/************************************** setUserLogoutSuccess ****************************************************/
export const setUserLogoutSuccess = (dispatch, value) => {
          dispatch({
                    type: LOGOUT_USERS_PERSIST,
                    // payload: value,
          });
          //  console.log('setUserLogoutSuccess ::::::', value);
};
/************************************** resetPasswordforadmin ****************************************************/
export const resetPasswordForAdmin = ({ data }) => dispatch => {
  return new Promise((resolve, reject) => {
            apiCallPost(RESET_PASS_ADMIN_API, data).then(response => {
                      const res = response.data;
                      //console.log('res resetPassword::::::', response);
                      resolve(res);
            }).catch(error => {
                      const errorMessage = error.message || error;
                      //console.log('resetPassword er::::::', error);
                      reject(errorMessage);
            });
  });
};