import { apiCallGet, apiCallPost } from "../../Services/ApiClient";
import {
     CHECK_PERMISSIONS, USERS_CHART_API, USERS_COUNT_API,
     GET_BALANCE, GET_REVENUE, GET_REVENUE_LIST, MIN_MAX_API, CREATE_TRADE_API
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** userChart ****************************************************/
export const userChart =
  ({ data }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(USERS_CHART_API, data)
        .then((response) => {
          const res = response.data;
          //console.log('res userChart::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('userChart er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
/************************************** userCount ****************************************************/
export const userCount = ({}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGet(USERS_COUNT_API)
        .then((response) => {
          const res = response.data;
          //console.log('res userCount::::::', res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('userCount er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** userCount ****************************************************/
export const checkPermissions = ({}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGet(CHECK_PERMISSIONS)
        .then((response) => {
          const res = response.data;
          //console.log('res userCount::::::', res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('userCount er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** balances ****************************************************/
export const getBalance = ({}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGet(GET_BALANCE)
        .then((response) => {
          const res = response.data;
          //console.log('res balances::::::', res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('balances er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** revenue ****************************************************/
export const getRevenue =
  ({ data }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_REVENUE, data)
        .then((response) => {
          const res = response.data;
          //console.log('res revenue::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('revenue er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
/************************************** revenue list ****************************************************/
export const getRevenueList =
  ({ data }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_REVENUE_LIST, data)
        .then((response) => {
          const res = response.data;
          //console.log('res getRevenueList::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getRevenueList er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
/************************************** Get OTC Detail ****************************************************/
export const getMinMaxData = ({ }) => {
     return dispatch => {
          return new Promise((resolve, reject) => {
               apiCallGet(MIN_MAX_API).then(response => {
                    const res = response.data;
                    console.log('res getMinMaxData::::::', res);
                    resolve(res);
               }).catch(error => {
                    if (error?.message == 'Unable to decode data.') {
                         commonError();
                    } else {
                         const errorMessage = error.message || error;
                         console.log('getMinMaxData er::::::', error);
                         reject(errorMessage);
                    }
               });
          });
     };
};
/************************************** Create Trade ****************************************************/
export const createTrade =
  ({ data }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPost(CREATE_TRADE_API, data)
        .then((response) => {
          const res = response.data;
          //console.log('res createTrade::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('createTrade er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
