import { Col, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import { toast } from "../../Ui/Toast/Toast";
import Swal from "sweetalert2";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import { updateAdminDetailById, roleDetailByRoleId } from "../../../redux/_actions/subAdminAction";
import { useDispatch } from "react-redux";
import Loader from ".././../../Loader/Loader";

export default function ViewAccessForm({ currentRow, roleList, handleCancelView, fetchAdminList }) {

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState()
  useEffect(() => {
    setCurrentRecord(currentRow);
  }, [currentRow]);
  const updateAccess = (role_id, type, id) => {
    if (type === "dropdown") {
      fetchRoleDetailByRoleId(role_id)
    }
    if (type === "radio") {
      let temp = { ...currentRecord }
      for (let i = 0; i < temp?.access?.length; i++) {
        if (temp?.access[i].id === id) {
          temp.access[i].access_name = role_id;
        }
      }
      setCurrentRecord(temp);
    }
  }
  const fetchRoleDetailByRoleId = (id) => {
    const data = {
      role_id: id
    }
    setLoading(true);
    setTimeout(() => {
      dispatch(roleDetailByRoleId({ data })).then(response => {
        let newAccess = [];
        response?.admin_role_menu_list_data.map((item, index) => {
          return newAccess.push({
            id: item?.admin_menu_list_data?.id,
            name: item?.admin_menu_list_data?.menu_list,
            access_name: "Edit"
          })
        });
        let currentrole = roleList.find(d => d.value === id)
        let temp = { ...currentRecord }
        temp.role_id = id;
        temp.role_name = currentrole.label;
        temp.access = newAccess;
        setCurrentRecord(temp);
        //setUserAccess(newAccess);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  const updateUserAccess = () => {
    const data = {
      admin_id: currentRecord.admin_id,
      role_id: currentRecord.role_id,
      role_name: currentRecord.role_name,
      user_access: JSON.stringify(currentRecord.access)
    }
    setLoading(true);
    setTimeout(() => {
      dispatch(updateAdminDetailById({ data })).then(response => {
        fetchAdminList();
        handleCancelView();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: "User access updated successfully!",
          color: '#7D80DF',
        });

        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  return (
    <div className="userAcessForm">
      <Row gutter={[24, 24]}>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <CustomSelect data={roleList}
            onChange={(e) => {
              updateAccess(e, "dropdown");
            }}
            defaultData={currentRecord?.role_id ? currentRecord?.role_id : null} label="Role" placeholder="Role"></CustomSelect>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          {console.log("currentRecord", currentRecord)}
          <label className="formLabel">Access</label>
          <div className="radioGroupDiv">
            {currentRecord?.access?.map((item, index) => {
              return (
                <Radio.Group value={item.access_name} >
                  <Space direction="vertical">
                    <label className="formLabel pb-0">{item?.name}</label>
                    <Radio
                      onChange={(e) => {
                        updateAccess("View Only", "radio", item.id);
                      }}
                      value={"View Only"}>View Only</Radio>
                    <Radio
                      onChange={(e) => {
                        updateAccess("Edit", "radio", item.id);
                      }}
                      value={"Edit"}>Edit</Radio>
                  </Space>
                </Radio.Group>
              )
            })}
          </div>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <ButtonCustom label="Update" onClick={() => { updateUserAccess() }} lytgaryBtn />
        </Col>
      </Row>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}
