import { Checkbox, Col, Radio, Row, Space } from "antd";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import { toast } from "../../Ui/Toast/Toast";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { addRole } from "../../../redux/_actions/subAdminAction";
import Swal from "sweetalert2";
import CheckBoxes from "./checkboxes/index.jsx";
import Loader from ".././../../Loader/Loader";

export default function RoleManagmentForm({ handleCancel, fetchRoleList, menulist }) {
  const initilaObj = {
    error: {
      name: "",
      menu_list: ""
    },
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [allMenuList, setAllMenuList] = useState(menulist);
  const [allState, setAllState] = useState(initilaObj);
  useEffect(() => {
    setAllMenuList(menulist)
  }, []);

  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "name":
          if (value === "") {
            error[key] = "Role Name field is required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "menu_list":
          if (value?.length <= 0) {
            error[key] = "Select atleast one menu";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };
  const addNewRole = () => {
    let selected_ids = allMenuList.filter(d => d.checked == true).map(menu_list => `${parseInt(menu_list.id)}`);
    let isValid = handleValidaton({
      name: roleName,
      menu_list: selected_ids
    });
    if (isValid) {
      setLoading(true);
      const data = {
        role: roleName,
        menu_list_ids: selected_ids,
      }
      setTimeout(() => {
        dispatch(addRole({ data })).then(response => {
          fetchRoleList();
          handleCancel();
          setRoleName("");
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#d3d3d3',
            text: "Role added successfully!",
            color: '#7D80DF',
          });

          setLoading(false)
        }).catch(err => {
          toast.error(err)
          setLoading(false)
        })
      }, 100);
    }
  }

  const handleCheck = (item, status) => {
    let temp = [...allMenuList];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === item) {
        temp[i].checked = status;
      }
    }
    setAllMenuList(temp);
    handleValidaton({ menu_list: temp })
  }
  return (
    <div className="userAcessForm">
      <Row gutter={[24, 24]}>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <InputCustom
            label="Name of Role"
            basicInput
            inputCustum
            eyeicon
            placeholder="Name of Role"
            value={roleName}
            onChange={(e) => {
              setRoleName(e.target.value);
              if (e.target.value !== "") {
                handleValidaton({
                  name: e.target.value
                });
              }
            }}
            maxLength={100}
          />
          <p className="error">{allState.error.name}</p>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <label className="formLabel">Access</label>
          <div className="radioGroupDiv">
            <Space direction="vertical">
              <Checkbox.Group
                style={{
                  width: "100%",
                }}
              >
                {allMenuList?.map((item, index) => {
                  return (
                    <CheckBoxes
                      checked={item.checked}
                      labelText={item.menu_list}
                      value={item.id}
                      onChange={(e) => {
                        let ischecked = e.target.checked;
                        if (ischecked) {
                          if (item.menu_list === "Sub Admin") {
                            Swal.fire({
                              title: `Are you sure you want to give permission for Role Management?`,
                              icon: "question",
                              showCancelButton: true,
                              confirmButtonColor: '#d3d3d3',
                              confirmButtonText: 'Yes',
                              cancelButtonText: 'NO'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleCheck(item.id, ischecked)
                              }
                            });
                          } else {
                            handleCheck(item.id, ischecked)
                          }
                        }
                        else {
                          handleCheck(item.id, ischecked)
                        }
                      }}
                    />
                  )
                }
                )}
              </Checkbox.Group>
              <p className="error">{allState.error.menu_list}</p>
            </Space>
          </div>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <ButtonCustom
            onClick={() => {
              addNewRole();
            }}
            label="Save" lytgaryBtn />
        </Col>
      </Row>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}
