import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import "./Questionnaire.scss";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addCategory,
  getCategoriesList,
  deleteCategory,
  updateCategory,
} from "../../../redux/_actions/questionAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "../../Ui/Toast/Toast";
import moment from "moment";

function UserCategories({ activeTab }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  const [nameError, setNameError] = useState("");
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [limit] = useState(10);
  useEffect(() => {
    // fetchQuestionList();
    fetchCategoryList();
    // fetchQuestionsList();
    window.scrollTo(0, 0); // Move Screeen to Top
  }, [activeTab]);

  const fetchCategoryList = () => {
    const data = {
      limit: limit,
      page: pageCount,
    };
    setTimeout(() => {
      dispatch(getCategoriesList({ data }))
        .then((response) => {
          const res = response.data;
          setCategoryList(res);
          setMetaData(response?.meta);
          setTotalPages(response?.meta?.total);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    }, 100);
  };

  const QuestionnaireColumn = [
    {
      title: "Categories Number",
      dataIndex: "categoriesNumberr",
      key: "categoriesNumberr",
    },
    {
      title: "Categories",
      dataIndex: "categories",
      key: "categories",
    },

    {
      title: "Created At",
      dataIndex: "created",
      key: "created",
    },

    {
      title:<span className="center">Action</span> ,
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const deleteCategoryById = (item) => {
    setLoading(true);
    const data = {
      catid: item.id,
    };
    setTimeout(() => {
      dispatch(deleteCategory({ data }))
        .then((response) => {
          fetchCategoryList();
          Swal.fire({
            icon: "success",
            title: "Success!",
            confirmButtonColor: "#d3d3d3",
            text: "Category deleted successfully!",
            color: "#7D80DF",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  /************************************** data for Questionnaire table ****************************************************/
  const dataSource = categoryList?.map((item, index) => {
    return {
      categoriesNumberr: (
        <div className="imgsec">
          <div>
            {
              <label className="ml-15">
                {(metaData?.page - 1) * limit + (index + 1)}
              </label>
            }
          </div>
        </div>
      ),

      categories: (
        <div style={{ marginLeft: 10 }} className="imgsec disable">
          <span className="fontFamilyText">{item.name}</span>
        </div>
      ),
      created: (
        <div style={{ marginLeft: 10 }} className="imgsec disable">
          <span className="fontFamilyText">
            {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss A")}
          </span>
        </div>
      ),
      actions: (
        <div className="imgsec actionOptions" style={{ marginLeft: 5 }}>
          <button
            className="deleteBtn"
            onClick={() => {
              setCurrentRecord(item);
              setName(item.name);
            }}
          >
            <EditOutlined /> <span>Edit</span>
          </button>
          <button
            className="deleteBtn"
            onClick={() => {
              Swal.fire({
                title: `Are you sure you want to delete?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#d3d3d3",
                confirmButtonText: "Yes",
                cancelButtonText: "NO",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteCategoryById(item);
                }
              });
            }}
          >
            <DeleteOutlined /> <span>Delete</span>
          </button>
        </div>
      ),
    };
  });
  const saveCategory = async () => {
    const isValid = name.trim() === "" ? false : true;
    if (isValid) {
      setLoading(true);
      let data = {
        catName: name,
      };
      setTimeout(() => {
        dispatch(addCategory({ data }))
          .then((response) => {
            fetchCategoryList();
            Swal.fire({
              icon: "success",
              title: "Success!",
              confirmButtonColor: "#d3d3d3",
              text: "Category added successfully!",
              color: "#7D80DF",
            });
            setName("");
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    } else {
      setNameError("Category field is required!");
    }
  };
  const editCategory = async () => {
    const isValid = name.trim() === "" ? false : true;
    if (isValid) {
      setLoading(true);
      let data = {
        catName: name,
        catId: currentRecord?.id,
      };
      setTimeout(() => {
        dispatch(updateCategory({ data }))
          .then((response) => {
            //fetchQuestionsList();
            Swal.fire({
              icon: "success",
              title: "Success!",
              confirmButtonColor: "#d3d3d3",
              text: "Category updated successfully!",
              color: "#7D80DF",
            });
            setName("");
            setCurrentRecord({});
            fetchCategoryList();
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    } else {
      setNameError("Category field is required!");
    }
  };
  return (
    <div>
      <div className="selectores">
        <Row gutter={[12, 12]}>
          <Col xs={24} md={12} lg={6}>
            <InputCustom
              label="Enter Category Name"
              inputCustum
              basicInput
              placeholder="Enter Category Name"
              value={name}
              maxLength={50}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            />
            <p className="error">{nameError}</p>
          </Col>
        </Row>
      </div>

      {!currentRecord.id ? (
        <div className="addButton">
          <ButtonCustom
            label="Add"
            yellowBtn
            onClick={() => {
              saveCategory();
            }}
          />
        </div>
      ) : (
        <>
          <div className="update_operation">
            <div className="addButton">
              <ButtonCustom
                label="Update"
                yellowBtn
                onClick={() => {
                  editCategory();
                }}
              />
            </div>
            <div className="addButton cancelButton">
              <ButtonCustom
                label="Cancel"
                yellowBtn
                onClick={() => {
                  setName("");
                  setCurrentRecord({});
                }}
              />
            </div>
          </div>
        </>
      )}
      <Table
        dataSource={dataSource}
        columns={QuestionnaireColumn}
        pagination={false}
        size="large"
      />
    </div>
  );
}

export default UserCategories;
