import { Breadcrumb, Tabs } from "antd";
import React from "react";
import AssetIssuanceComp from "./AssetIssuanceComp";
import PaymentsAccrued from "./PaymentsAccrued";
import "./AssetIssuance.scss";

function AssetIssuance() {
  const { TabPane } = Tabs;

  return (
    <div className="assetIssuance">
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Asset Issuance</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <div className="tabs__body">
          <Tabs style={{ marginTop: 15, display: "flex" }} defaultActiveKey="1">
            <TabPane tab="Asset Issuance" key="1">
              <AssetIssuanceComp />
            </TabPane>
            {/* <TabPane tab="Payments Accrued" key="2">
            <PaymentsAccrued />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default AssetIssuance;
