import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCallPost, apiCallPostFile } from "../../Services/ApiClient";
import { ADD_CURRENCY_API, ADD_CURRENCY_UPDATE_API, FILE_UPLOAD_API, GET_CURRENCY_LIST, REMOVE_CURRENCY_API, SEARCH_CURRENCY, SEARCH_CURRENCY_API } from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getCurrencyList ****************************************************/
export const getCurrencyList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_CURRENCY_LIST, data).then(response => {
        const res = response.data;
        //console.log('res getCurrencyList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('getCurrencyList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** removeCurrency ****************************************************/
export const removeCurrency = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(REMOVE_CURRENCY_API, data).then(response => {
        const res = response.data;
        //console.log('res removeCurrency::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('removeCurrency er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** addCurrency ****************************************************/
export const addCurrency = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_CURRENCY_API, data).then(response => {
        const res = response.data;
        //console.log('res addCurrency::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('addCurrency er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** updateCurrency ****************************************************/
export const updateCurrency = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_CURRENCY_UPDATE_API, data).then(response => {
        const res = response.data;
        //console.log('res updateCurrency::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('updateCurrency er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** addCurrency ****************************************************/
export const searchCurrency = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(SEARCH_CURRENCY_API, data).then(response => {
        const res = response.data;
        //console.log('res addCurrency::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('addCurrency er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** fileUpload ****************************************************/
export const fileUpload = ({ formData }) => {
  return dispatch => {
    console.log('data fileUpload::::::', formData);
    return new Promise((resolve, reject) => {
      apiCallPostFile(FILE_UPLOAD_API, formData).then(response => {
        const res = response.data;
        // console.log('res fileUpload::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError();
        } else {
          const errorMessage = error.message || error;
          //console.log('fileUpload er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};