import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCalGetFile, apiCallDelete, apiCallGet, apiCallPost } from "../../Services/ApiClient";
import {
  ADD_CATEGORY,
  ADD_QUESTION, CATEGORIES_DELETE_API, CATEGORIES_LIST_API, CATEGORY_LIST_API, DELETE_QUESTION, QUESTIONS_LIST_API, QUESTION_LIST_API, UPDATE_CATEGORY, UPDATE_QUESTION
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** Add Questions ****************************************************/
export const addQuestion = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_QUESTION, data).then(response => {
        const res = response.data;
        // console.log('res addQuestion::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('addQuestion er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Get Category List ****************************************************/
export const getCategoryList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(CATEGORY_LIST_API).then(response => {
        const res = response.data;
        // console.log('res getCategoryList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getCategoryList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Get Question List ****************************************************/
export const getQuestionList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(QUESTION_LIST_API).then(response => {
        const res = response.data;
        // console.log('res getCategoryList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getCategoryList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Questions List ****************************************************/
export const getQuestionsList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(QUESTIONS_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getQuestionsList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getQuestionsList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Delete Questions ****************************************************/
export const deleteQuestion = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(DELETE_QUESTION, data).then(response => {
        const res = response.data;
        // console.log('res getQuestionsList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getQuestionsList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Add Questions ****************************************************/
export const updateQuestionById = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_QUESTION, data).then(response => {
        const res = response.data;
        // console.log('res addQuestion::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('addQuestion er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Add Category ****************************************************/
export const addCategory = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_CATEGORY, data).then(response => {
        const res = response.data;
        // console.log('res addCategory::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('addCategory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Category List ****************************************************/
export const getCategoriesList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(CATEGORIES_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getCategoriesList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getCategoriesList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Delete Category ****************************************************/
export const deleteCategory = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(CATEGORIES_DELETE_API, data).then(response => {
        const res = response.data;
        // console.log('res deleteCategory::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('deleteCategory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Add Category ****************************************************/
export const updateCategory = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_CATEGORY, data).then(response => {
        const res = response.data;
        // console.log('res updateCategory::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateCategory er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};