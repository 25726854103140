import { Empty } from "antd";
import React, { useEffect } from "react";
import './PageNotFound.scss'
import { useNavigate } from "react-router-dom";
function PageNotFound() {
  let navigate = useNavigate()
  useEffect(() => {
    navigate("/")
  }, [])
  return (
    <div className="pagenotfound">
      <Empty
        description={
          <span>
            Page Not Found
          </span>
        }
      />
    </div>
  );
}

export default PageNotFound;
