import { GRID_LAYOUT } from "../../constants/grid.layout";
import { actionTypes } from "../_actions/persist.action";

//console.log("GRID_LAYOUT",GRID_LAYOUT);

const initialState = {
  grid_layout: GRID_LAYOUT,
  mobile_tab: "MARKET",
  current_breakpoint: "lg",
};

const gridReducer = (state = initialState, action) => {
  switch (action.types) {
    case actionTypes.SAVE_CHANGES_GRID_LAYOUT:
      return {
        ...state,
        grid_layout: action.data,
      };
    default:
      return state;
  }
};

export default gridReducer;
