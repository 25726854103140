import { Breadcrumb, DatePicker, Table } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useState } from "react";
import Paginate from "../../Ui/Paginate/Paginate";
import { getCardUserList } from "../../../redux/_actions/cardAction";
import { Link, useLocation } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";
import { DownloadOutlined } from "@ant-design/icons";
import "./CryptoPortfolio.scss";
import { getRevenueList } from "../../../redux/_actions";
import { BASE_URL, DOWNLOAD_GET_REVENUE_LIST } from "../../../Services/EndPoints";
import moment from "moment";
import { decodeData, toFixedExp } from '../../../Services/Utils';

const { RangePicker } = DatePicker;

let paginationData = "";
function CryptoPortfolio() {
  const location = useLocation();

  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [fromDate, setFromDate] = useState("");
  const [localValue, setLocalValue] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    console.log('chk search:::::', search)
    fetchRevenueList("");
  }, [pageCount, search]);

  const fetchRevenueList = (from_date) => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      currency_type: location.state?.activeKey === "1" ? "fiat" : "crypto"
    }
    if (from_date !== "") {
      data.start_date = from_date[0];
      data.end_date = from_date[1];
    }
    console.log("datadata",data)
    setTimeout(() => {
      dispatch(getRevenueList({ data })).then(response => {
        const res = response.data;
        setUserData(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Transaction Hash / Order ID",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Fee(USDT)",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Profit %",
      dataIndex: "profit",
      key: "profit",
    },
    {
      title: "Profit amount(USDT)",
      dataIndex: "profitAmount",
      key: "profitAmount",
    },
  ];

  /************************************** table data ****************************************************/
  const dataSource = userData?.map((item, index) => {
    return {
      key: index + 1,
      SNo: (<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>),
      //SNo: (<label className="ml-15">{(index + 1)}</label>),
      user_name: <div className="tableIconStyle">{item?.user_total_profit_relation?.user_name}</div>,
      email: <div className="tableIconStyle">{item?.user_total_profit_relation?.email}</div>,
      order: <div style={{ marginLeft: 15 }} className="tableIconStyle ">{item?.order_id ? item?.order_id ?? "N/A" : item?.trnx_id ?? "N/A"}</div>,
      amount: <div className="tableIconStyle">{location.state?.activeKey === "1" ? "$" : ""}{toFixedExp(item.amount, 8)} {item.crypto_type}</div>,
      fee: <div style={{ textTransform: "capitalize" }}>{location.state?.activeKey === "1" ? "$" : ""}{toFixedExp(item?.fee ?? 0, 8)}</div>,
      profit: <div style={{ textTransform: "capitalize" }}>{item?.profit_percentage ?? 0}</div>,
      profitAmount: <div style={{ textTransform: "capitalize" }}>{location.state?.activeKey === "1" ? "$" : ""}{toFixedExp(item?.profit_amount ?? 0, 8)}</div>,
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  const download_Csv = () => {
    // const second = new NodeRSA(Constants.KEY);
    // second.setOptions({ encryptionScheme: 'pkcs1' });
    // const enc = second.encrypt(location.state?.user_id, 'base64');
    // const base64UrlSafeEncodedData = enc.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    const link = `${BASE_URL}${DOWNLOAD_GET_REVENUE_LIST}?currency_type=${(location.state?.activeKey === "1" ? "fiat" : "crypto")}&start_date=${fromDate}&end_date=${moment().format("YYYY-MM-DD")}`
    window.open(link);
    return
  }

  return (
    <>
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>{location.state?.activeKey === "1" ? "Fiat" : "Crypto"} Portfolio Revenue Details </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding userDatils">
        <div className="annoucements_block_multiData">
          <div
            className="cryptoPortfolio_link"
            style={{ justifyContent: "end", display: "flex" }}
          >
            <RangePicker
              status="error"
              style={{
                width: "100%",
              }}
              //disabledDate={(current) => current.isAfter(moment().subtract(1, "day"))}
              show={fromDate !== "" ? moment(fromDate) : ""}
              onChange={(e, date) => {
                setFromDate(date);
                fetchRevenueList(date);
              }}
            />
            <Link to={""}
              onClick={() => {
                if (userData?.length > 0) {
                  download_Csv()
                }
              }}
              className={`download_btn ${userData?.length > 0 ? "" : "disabled"}`}>
              Download CSV{" "}
              <span>
                <DownloadOutlined />
              </span>
            </Link>
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default CryptoPortfolio;
