import { Breadcrumb, Tabs } from 'antd'
import React, { useState } from 'react'
import './SubAdmin.scss'
import RoleManagment from './RoleManagment';
import UserAccess from './UserAccess';
import Activitylog from './Activitylog';
import { useSelector } from 'react-redux';

export default function SubAdmin() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let role_name = login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "super_admin";

  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  return (
    <div className='subAdmin'>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Sub Admin</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="tabs__body commonPadding">
        <Tabs style={{ display: 'flex' }}
          onChange={(e) => {
            setActiveTab(e)
          }} defaultActiveKey="1">
          <TabPane tab="Role Management" key="1">
            <RoleManagment activeTab={activeTab} />
          </TabPane>
          <TabPane tab="User Access" key="2">
            <UserAccess activeTab={activeTab} />
          </TabPane>
          {role_name === "super_admin" &&
            <TabPane tab="Activity Logs" key="3">
              <Activitylog activeTab={activeTab} />
            </TabPane>
          }
        </Tabs>
      </div>
    </div>
  )
}
