import React, { useCallback, useState, useEffect } from "react";
import "./ContactUs.scss";
import { Breadcrumb, Col, Dropdown, Menu, Row, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Paginate from "../../Ui/Paginate/Paginate.jsx";
import InputCustom from "../../Ui/input/InputCustom";
import Loader from "../../../Loader/Loader";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { Link, useNavigate } from "react-router-dom";
import { downloadQueryList, getQueryList } from "../../../redux/_actions/contactUsAction.js";
import { toast } from "../../Ui/Toast/Toast.js";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce';
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import DetailsCard from "../Dashboard/DetailsCard.jsx";
import { BASE_URL, DOWNLOAD_QUERIES_List } from "../../../Services/EndPoints.js";

let paginationData = "";
let pending_tickets = 0;
let total_tickets = 0;
function ContactUs() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const statusFilter = [
    {
      value: "4",
      label: "All"
    },
    {
      value: "0",
      label: "Open"
    },
    {
      value: "1",
      label: "In Progress"
    },
    {
      value: "2",
      label: "Resolved"
    },
    {
      value: "3",
      label: "Expired"
    },
  ];
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Ticket Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: <span className="center">Action</span> ,
      dataIndex: "action",
      key: "action",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 25;
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [localValue, setLocalValue] = useState('');
  const [status, setStatus] = useState(4);
  const [queryList, setQueryList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showView, setShowView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    fetchQueryList();
    setTimeout(() => {
      fetchQueryList();
    }, 200);
  }, [pageCount, search, status]);

  /************************************** fetchQueryList ****************************************************/
  const fetchQueryList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount || 1,
      search: search,
      status: status
    };
    setTimeout(() => {
      dispatch(getQueryList({ data })).then((response) => {
        const res = response.data
        // console.log('chk getQueryList res:::::', res)
        if (res.length > 0) {
          res.map((item, index) => (item["key"] = index + 1));
          setLoading(false);
          paginationData = response?.meta?.pages || 0
          pending_tickets = response?.meta?.pending_tickets || 0;
          total_tickets = response?.meta?.total_tickets || 0
          setQueryList(res);
        } else {
          setLoading(false);
          setQueryList();
          paginationData = 0
        }
      }).catch((err) => {
        toast.error(err);
        setLoading(false);
      });

    }, 100);
  };
  /************************************** getStatus ****************************************************/
  const getStatus = (status) => {
    return status == 0 ? 'Open' : status == 1 ? 'In Progress' : status == 2 ? 'Resolved' : 'Expired'
  }
  /************************************** dataSource ****************************************************/
  const dataSource = queryList?.map((item, index) => {
    return (
      {
        key: item?.key,
        sno: (<div style={{ marginLeft: 10 }} className="tableIconStyle "><span className="fontFamilyText">{(pageCount - 1) * limit + (index + 1)}</span></div>),
        ticket_id: (<div style={{ marginLeft: 5 }} className="tableIconStyle "><span className="fontFamilyText">{item.ticket_id}</span></div>),
        user_name: (<div style={{ textTransform: 'capitalize' }} className="tableIconStyle"><span className="fontFamilyText">{item.name}</span></div>),
        email: (<div className="tableIconStyle"><span className="fontFamilyText">{item.email}</span></div>),
        status: (<div style={{ marginLeft: 5, textTransform: 'capitalize' }} ><span className="fontFamilyText">{getStatus(item.status)}</span></div>),
        date: (<div className="tableIconStyle"><span className="fontFamilyText">{moment(item.created_at).format('DD MMM, YYYY')} | {moment(item.created_at).format('h:mm A')}</span></div>),
        action: (<Link to={"/contactUs/view"} style={{ marginLeft: -25 }} state={{ ticket_id: item.ticket_id }} className="buttonView"><span className="fontFamilyText">View<EyeOutlined /></span></Link>)
      }
    )
  })
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  /************************************** downoaldList ****************************************************/
  const downoaldList = () => {
    const link = (!search && !status) ? `${BASE_URL}${DOWNLOAD_QUERIES_List}` : (search && !status) ? `${BASE_URL}${DOWNLOAD_QUERIES_List}?search=${search}` : (!search && status) ? `${BASE_URL}${DOWNLOAD_QUERIES_List}?status=${status}` : `${BASE_URL}${DOWNLOAD_QUERIES_List}?search=${search}&status=${status}`
    window.open(link);
    return
  }

  /*****************************************************onPressFilter******************************************************/
  const onPressFilter = (filterType) => {
    // console.log('chk filterType:::::', filterType)
    setStatus(filterType?.value)
  }
  /************************************** menu ****************************************************/
  const menu = (
    <Menu className='topdropdown'>
      <Menu.Item style={{ marginTop: "7px" }} >
        <b> Select</b>
        <Menu className='trsp_itme' style={{ backgroundColor: 'transparent !important' }}>
          {statusFilter.map((item, index) => {
            return (<Menu.Item onClick={() => onPressFilter(item)} className='subitems' style={{ lineHeight: '30px', height: '30px', listStyle: "none" }}>{item.label}</Menu.Item>)
          })}
        </Menu>
      </Menu.Item>
    </Menu>
  );

  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };

  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );

  /**************************************************************************************************/
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Contact Us</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding supportManagement">
        <div className="supportManagement_ticket">
          <h2>Support Ticket Management</h2>
    
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <Row gutter={[25, 25]} >
              <Col className="responsive" xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                <div className="dashboardScreen1__reward">
                  <DetailsCard
                    keyname={"Total Tickets"}
                    Valname={total_tickets}
                  />
                </div>
              </Col>
              <Col className="responsive" xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                <div className="dashboardScreen1__reward">
                  <DetailsCard
                    keyname={"Pending Tickets"}
                    Valname={pending_tickets}
                  />
                </div>
              </Col>
            </Row>
            {/* <ButtonCustom yellowBtn label={`Total Tickets = ${total_tickets}`} style={{ width: "208px", height: "65px" }} />
            <ButtonCustom yellowBtn label={`Pending Tickets = ${pending_tickets}`} style={{ width: "208px", height: "65px", marginLeft: 15 }} /> */}
          </div>

          {/* ----------------------------------------------------------- */}
          <div className="supportManagement_ticket_selects">
            <div className="annoucements_block_multiData">
              <div style={{ justifyContent: 'start', display: 'flex' }}>
                <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
              </div>
            </div>
            <div className='addnewSec'>
              <div className='drop_icon1'>
                <Dropdown overlay={menu}>
                  <button>{'Status Filter'}</button>
                  {/* <button>{status == 4 ? 'Status' : status == 0 ? 'Open' : status == 1 ? 'In Progress' : status == 2 ? 'Resolved' : status == 3 ? 'Expired' : 'Status'}</button> */}
                </Dropdown>
              </div>
            </div>

            {/* <CustomSelect className="selector" placeholder="Status Filter" defaultData={statusFilter[0].value} data={statusFilter} /> */}
            <ButtonCustom yellowBtn onClick={() => downoaldList()} label="Download Tickets List" style={{ width: "208px", height: "45px" }} />
          </div>
          {/* ----------------------------------------------------------- */}

          {/* <div className="supportManagement_ticket_details">
            <p>Total Tickets = 4585</p>
            <p>Pending Tickets = 0</p>
          </div> */}
        </div>

        {/* ----------------------------------------------------------- */}
        <div style={{ marginTop: 25 }}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="large"
          />
        </div>
      </div>

      {/* ----------------------------------------------------------- */}
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default ContactUs;
