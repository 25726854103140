import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCallPost } from "../../Services/ApiClient";
import { PUSH_ANNOUNCEMENT_API, SEARCH_USER_API } from "../../Services/EndPoints";
import { UPDATE_LIST } from "./types";
import commonError from "../../Components/Common/Index";

/**************************************Update prop values ****************************************************/
export const updateList = ({ prop, value }) => {
  return {
    type: UPDATE_LIST,
    payload: { prop, value },
  };
};

/************************************** sendAnnouncement ****************************************************/
export const sendAnnouncement = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(PUSH_ANNOUNCEMENT_API, data).then(response => {
        const res = response.data;
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getUserListAnnouncement ****************************************************/
export const getUserListAnnouncement = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(SEARCH_USER_API, data).then(response => {
        const res = response.data;
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          reject(errorMessage);
        }
      });
    });
  };
};