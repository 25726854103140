import React, { useCallback, useEffect, useState } from "react";
import "./AssetIssuance.scss";
import "./CardIssuance.scss";
import InputCustom from "../../Ui/input/InputCustom";
import { Popover, Tooltip, Modal, Input } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { Link, useNavigate } from "react-router-dom";
import {
  EditOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Row, Col, Switch } from "antd";
import { useDispatch } from "react-redux";
import {
  deleteProposal,
  getProposalList,
  updateProposalAgent,
} from "../../../redux/_actions/assetAction";
import { toast } from "../../Ui/Toast/Toast";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { addAgentForProposal } from "../../../Services/web3/ContractServices";
import Loader from "../../../Loader/Loader";
import Swal from "sweetalert2";
import Paginate from "../../Ui/Paginate/Paginate";
import debounce from "lodash.debounce";

let paginationData = "";

function AssetIssuanceComp() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [proposalList, setProposalList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [metaData, setMetaData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");

  const [limit] = useState(12);
  useEffect(() => {
    fetchProposalList();
  }, [pageCount, search]);

  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search);
  };

  const debounceData = useCallback(
    debounce((search) => {
      setSearch(search.trim());
      setLocalValue(search.trim());
      setPageCount(1);
    }, 1000),
    []
  );

  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [agentAddress, setAgentAddress] = useState("");
  // const [selectedProposalName, setSelectedProposalName] = useState("");
  // const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
  // const [selectedId, setSelectedId] = useState("");

  // const showModal = (proposalName, tokenAddress, id) => {
  //   setSelectedProposalName(proposalName);
  //   setSelectedTokenAddress(tokenAddress);
  //   setSelectedId(id);
  //   setIsModalVisible(true);
  // };

  // const handleOk = async () => {
  //   try {
  //     setLoading(true);

  //     if (selectedTokenAddress && agentAddress !== "") {
  //       // Call addAgentForProposal and wait for its completion
  //       const { proposalAgentAddress } = await addAgentForProposal(
  //         selectedTokenAddress,
  //         agentAddress
  //       );
  //       if (proposalAgentAddress) {
  //         let data = { id: selectedId, agent_address: proposalAgentAddress };
  //         console.log("DATATTATATA", data);

  //         const updateAgentInProposal = dispatch(updateProposalAgent({ data }));

  //         // Show success message
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success!",
  //           confirmButtonColor: "#d3d3d3",
  //           text: "Agent added successfully!",
  //           color: "#7D80DF",
  //         });
  //       }
  //       // If the function completes without error, setIsModalVisible can be set to false
  //       setIsModalVisible(false);
  //     }
  //   } catch (error) {
  //     // Handle errors here (if needed)
  //     console.error("Error in handleOk:", error.message);
  //     toast.error(error.message);
  //   } finally {
  //     // Regardless of success or failure, setLoading should be set to false
  //     setLoading(false);
  //   }
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const fetchProposalList = () => {
    const data = {
      limit: limit,
      page: pageCount,
      search: search,
    };
    setTimeout(() => {
      setLoading(true);

      dispatch(getProposalList({ data }))
        .then((response) => {
          const res = response.data;
          setProposalList(res);
          setMetaData(response?.meta);
          setTotalPages(response?.meta?.total);
          paginationData = response?.meta?.pages;

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  const getPercentage = (collected, raised) => {
    return ((collected ?? 0) * 100) / (raised ?? 0);
  };

  const navigate = useNavigate();
  const handleEditClick = (item) => {
    // Redirect to the edit page and pass the proposal details as state
    const isStartDateValid = () => {
      const startDateTimestamp = moment.utc(item.start_date).unix();
      const presentTimestamp = moment.utc().unix();
      // console.log("startDateTimestamp", startDateTimestamp);
      // console.log("presentTimestamp", presentTimestamp);

      return startDateTimestamp > presentTimestamp;
    };
    const isEndDatePassed = () => {
      const endDateTimestamp = moment.utc(item.end_date).unix();
      const presentTimestamp = moment.utc().unix();
      // console.log("endDateTimestamp", endDateTimestamp);
      // console.log("presentTimestamp", presentTimestamp);

      return endDateTimestamp < presentTimestamp;
    };
    {
      isStartDateValid()
        ? navigate("/edit-proposal", { state: { proposalData: item } })
        : isEndDatePassed()
        ? toast.error("Proposal already Expired!")
        : toast.error("Proposal already started!");
    }
    console.log("item details", item);
  };
  const handleViewClick = (item) => {
    {
      navigate("/view-proposal", { state: { proposalData: item } });
    }
  };
  const handleDeleteProposal = (id) => {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      // Proceed with deletion if user confirms
      if (result.isConfirmed) {
        // Prepare data for deletion
        const data = {
          proposal_id: id,
        };

        // Send deletion request
        setTimeout(() => {
          dispatch(deleteProposal({ data })).then((response) => {
            console.log(
              "response in deleteproposal dispatch function",
              response
            );
            // Handle success
            if (response && response.status === true) {
              console.log("IN IF CONDITION");
              // Handle success
              Swal.fire(
                "Deleted!",
                "Your proposal has been deleted.",
                "success"
              );
              // Optionally, you may want to update the UI after deletion
              fetchProposalList();
              setLoading(false);
            } else {
              console.log("IN ELSE CONDITION");

              // Handle deletion failure
              Swal.fire("Error!", "Failed to delete the proposal.", "error");
              setLoading(false);
            }
          });
        }, 100);
      }
    });
  };

  return (
    <div className="assetIssuanceComp">
      <div className="searchSec">
        <InputCustom
          basicInput
          placeholder="Quick Search"
          maxLength={50}
          searchImg={<SearchOutlined />}
          inputSearch
          value={localValue}
          onChange={(e) => {
            updateSearch(e.target.value);
          }}
        />
        <div>
          <Link to={"/Proposal"}>
            <ButtonCustom leftIcon={<PlusOutlined />} label="Add" yellowBtn />
          </Link>
        </div>
      </div>
      <Row gutter={[30, 30]}>
        {proposalList.map((item, index) => {
          return (
            <Col lg={8}>
              <div className="cardIssuance">
                <div className="stageNameOuter">
                  {/* <div className="proposalType">Public</div> */}
                  <div className="stageName">
                    <div className="asset_name">
                      <h4 className="stageNameText">{item.title}</h4>
                      <button
                        className={`${
                          moment.utc().unix() < moment.utc(item.end_date).unix()
                            ? moment.utc(item.start_date).unix() >
                              moment.utc().unix()
                              ? "pending-status"
                              : moment.utc(item.end_date).unix() <
                                moment.utc().unix()
                              ? "expired-status"
                              : "completed-status"
                            : (item?.minted_token_quantity /
                                item?.token_quantity) *
                                100 >=
                              75
                            ? "completed-status"
                            : "expired-status"
                        }`}
                        style={{ cursor: "default" }} // Set cursor to default arrow cursor
                      >
                        {moment.utc().unix() < moment.utc(item.end_date).unix()
                          ? moment.utc(item.start_date).unix() >
                            moment.utc().unix()
                            ? "Upcoming"
                            : moment.utc(item.end_date).unix() <
                              moment.utc().unix()
                            ? "Expired"
                            : "Live"
                          : (item?.minted_token_quantity /
                              item?.token_quantity) *
                              100 >=
                            75
                          ? "Completed"
                          : "Expired"}
                      </button>
                    </div>
                    <div className="iconss">
                      {/* <MessageOutlined style={{ color: "#000" }} /> */}
                      <EyeOutlined
                        style={{ color: "#000" }}
                        onClick={() => handleViewClick(item)}
                      />
                      <EditOutlined
                        style={{ color: "#000", cursor: "pointer" }}
                        onClick={() => handleEditClick(item)}
                      />
                      {Math.floor(Date.parse(item?.start_date) / 1000) >
                        Math.floor(Date.now() / 1000) && (
                        <DeleteOutlined
                          style={{ color: "#ff0000", marginLeft: "10px" }} // Style the delete icon as needed
                          onClick={() => handleDeleteProposal(item.id)} // Add your delete proposal handler function here
                        />
                      )}

                      {/* {item?.token_address ? (
                        <Popover content="Add Agent" placement="left">
                          <EditOutlined
                            style={{ color: "#000", cursor: "pointer" }}
                            onClick={() => {
                              showModal(
                                item?.title,
                                item?.token_address,
                                item?.id
                              );
                            }}
                          />
                        </Popover>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="pieChartSec borderBtnImg ">
                  <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbar
                      value={getPercentage(
                        item.collected_fund,
                        item.raise_fund
                      )}
                      text={`${getPercentage(
                        item.collected_fund,
                        item.raise_fund
                      )}%`}
                      styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0.25,

                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",

                        // Text size
                        textSize: "16px",

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        // pathColor: `rgba(62, 152, 199, ${50 / 100})`,
                        // textColor: '#f88',
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div>
                    <p>
                      Token Quantity <br />
                      <span>{item.token_quantity}</span> <br />
                      Sold {item.collected_fund} Tokens <br />
                      {/* Status Approved */}
                    </p>
                  </div>
                </div>
                <div className="detailsSec borderBtnImg">
                  <h4>Initial Value</h4>
                  <div className="dFlexAlc">
                    <p>Token Value</p>
                    <p>
                      {item.token_value} <span>TUT</span>
                    </p>
                  </div>
                  <div className="dFlexAlc">
                    <p>Min Investment</p>
                    <p>
                      {item.min_investment} <span>TUT</span>
                    </p>
                  </div>
                  <div className="dFlexAlc">
                    <p>Fund Raise</p>
                    <p>
                      {item.raise_fund} <span>TUT</span>
                    </p>
                  </div>
                </div>
                <div className="detailsSec borderBtnImg">
                  <div className="dFlexAlc">
                    <p>Start Date</p>
                    <p>
                      {moment(item.start_date).format("DD-MM-YYYY HH:mm")}
                      {/* <span>05:57 PM</span> */}
                    </p>
                  </div>
                  <div className="dFlexAlc">
                    <p>End Date</p>
                    <p>
                      {moment(item.end_date).format("DD-MM-YYYY HH:mm")}
                      {/* <span>06:16 PM</span> */}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default AssetIssuanceComp;

{
  /* <div className="detailsSec borderBtnImg">
                  <div className="dFlexAlc">
                    <h4>Initial Value</h4>
                    <span>
                      <FieldTimeOutlined />
                    </span>
                  </div>
                  <div className="dFlexAlc">
                    <p>Token Value</p>
                    <p>
                      {item.token_value}
                      <span>TUT</span>
                      <EditOutlined />
                    </p>
                  </div>
                  <div className="dFlexAlc">
                    <p>Fund Raise</p>
                    <p>
                      {item.raise_fund} <span>TUT</span>
                    </p>
                  </div>
                  <div className="dFlexAlc">
                    <p>20% Cap</p>
                    <p>
                      <Switch />
                    </p>
                  </div>
                </div> */
}

{
  /* <Modal
        title={`Add Agent for ${selectedProposalName}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Please enter the agent's wallet address:</p>
        <Input
          value={agentAddress}
          onChange={(e) => setAgentAddress(e.target.value)}
        />
      </Modal> */
}
