import { Breadcrumb, Table } from "antd";
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Paginate from "../../Ui/Paginate/Paginate";
import InputCustom from "../../Ui/input/InputCustom";
import debounce from "lodash.debounce";
import { getProposalsList } from "../../../redux/_actions/assetAction";

let paginationData = "";
function MintingAddresses() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  console.log("LOGINININI", login);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    // {
    //   title: "Market",
    //   dataIndex: "market_name",
    //   key: "market_name",
    // },
    {
      title: "Token Name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Type of Proposal",
      dataIndex: "proposal_type",
      key: "proposal_type",
    },
    {
      title: "Total Token Quantity",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Consumed Token Quantity",
      dataIndex: "token_quantity_consume",
      key: "token_quantity_consume",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [ProposalList, setProposalList] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    fetchProposalList();
  }, [pageCount, search]);
  /************************************** fetchProposalList ****************************************************/
  const fetchProposalList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search,
    };
    setTimeout(() => {
      dispatch(getProposalsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows;

          if (response?.data?.data?.count > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setProposalList(res);
          } else {
            setProposalList();
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  /************************************** table data ****************************************************/
  const dataSource = ProposalList?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.proposal_name}</span>
        </div>
      ),
      // market_name: (
      //   <div className="tableIconStyle">
      //     <span className="fontFamilyText">
      //       {item?.relisted === 0
      //         ? "Primary"
      //         : item?.relisted === 1
      //         ? "Secondary"
      //         : "NA"}
      //     </span>
      //   </div>
      // ),

      token_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.token_name}</span>
        </div>
      ),
      proposal_type: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.asset_type?.type_of_proposal}
          </span>
        </div>
      ),
      token_quantity: (
        <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
          <span className="fontFamilyText">{item?.total_token_quantity}</span>
        </div>
      ),
      token_quantity_consume: (
        <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
          <span className="fontFamilyText">
            {item?.consumed_token_quantity || "0.00"}
          </span>
        </div>
      ),
      action: (
        <Link
          to={"/mintingAddresses/mintingDetails"}
          style={{ marginLeft: -25 }}
          state={{
            id: item.id,
            projectId: item.project_id,
            proposalName: item.proposal_name,
            tokenAddress: item?.token_address,
          }}
          className="buttonView"
        >
          <span className="fontFamilyText">
            View <EyeOutlined />
          </span>
        </Link>
      ),
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search);
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(
    debounce((search) => {
      setSearch(search.trim());
      setLocalValue(search.trim());
      setPageCount(1);
    }, 1000),
    []
  );
  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Minting Addresses</Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding userDatils">
        {/* <div className="annoucements_block_multiData">
          <div style={{ justifyContent: 'end', display: 'flex' }}>
            <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
          </div>
        </div> */}

        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default MintingAddresses;
