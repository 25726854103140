import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCallGeckoSymbols, apiCallPost } from "../../Services/ApiClient";
import { ADD_TOKENS_API, GECKO_SYMBOL_URL, GET_TOKENS_LIST, REMOVE_TOKENS_API, SEARCH_TOKENS_API } from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getTokenList ****************************************************/
export const getTokenList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(GET_TOKENS_LIST, data).then(response => {
        const res = response.data;
        //console.log('res getTokenList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('getTokenList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** removeToken ****************************************************/
export const removeToken = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(REMOVE_TOKENS_API, data).then(response => {
        const res = response.data;
        //console.log('res removeToken::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('removeToken er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** addToken ****************************************************/
export const addToken = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_TOKENS_API, data).then(response => {
        const res = response.data;
        //console.log('res addToken::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('addToken er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** searchToken ****************************************************/
export const searchToken = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(SEARCH_TOKENS_API, data).then(response => {
        const res = response.data;
        //console.log('res searchToken::::::', res);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          //console.log('searchToken er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
