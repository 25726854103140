import React, { useState } from "react";
import QuestionnaireTab from "./QuestionnaireTab";
import "./Questionnaire.scss"
import { Breadcrumb, Tabs } from "antd";
import QuestionnaireIcon from "./QuestionnaireIcon";
import UserCategories from "./UserCategories";


function Questionnaire() {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  return (
    <div className="questionnaire">
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Questionnaire</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <div className="tabs__body">
          <Tabs
            style={{ marginTop: 15, display: "flex" }}
            onChange={(e) => {
              setActiveTab(e)
            }}
            defaultActiveKey="1"
          >
            <TabPane tab="Questionnaire" key="1">
              <QuestionnaireTab activeTab={activeTab} />
            </TabPane>
            {/* <TabPane tab="Subscription Questionnaire" key="2">
              <QuestionnaireTab activeTab={activeTab} />
            </TabPane> */}
            {/* <TabPane tab="Questionnaire" key="3">
              <QuestionnaireIcon activeTab={activeTab} />
            </TabPane> */}
            <TabPane tab="User Categories" key="4">
              <UserCategories activeTab={activeTab} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Questionnaire;
