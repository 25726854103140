import React, { useEffect, useState } from "react";
import { Breadcrumb, Tabs, Col, Row, Table } from "antd";
import "./Assets.scss";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { addAssets, getAssetsList, deleteAssets, updateAssets } from "../../../redux/_actions/assetAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "../../Ui/Toast/Toast";
import moment from "moment";


function Assets() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  const [nameError, setNameError] = useState("");
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [assetList, setAssetList] = useState([]);
  const [limit] = useState(10);
  useEffect(() => {
    fetchAssetsList();
    window.scrollTo(0, 0); // Move Screeen to Top
  }, []);

  const fetchAssetsList = () => {
    const data = {
      limit: limit,
      page: pageCount,
    }
    setTimeout(() => {
      dispatch(getAssetsList({ data })).then(response => {
        const res = response.data;
        setAssetList(res);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.total)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
      })
    }, 100);
  }

  const QuestionnaireColumn = [
    {
      title: "Assets Number",
      dataIndex: "assetNumberr",
      key: "assetNumberr",
    },
    {
      title: "Assets",
      dataIndex: "asset_type",
      key: "asset_type",
    },

    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
    },

    {
      title:<span className="center">Action</span> ,
      dataIndex: "actions",
      key: "actions",
    },
  ];
  const deleteAssetById = (item) => {
    setLoading(true);
    const data = {
      assetId: item.id
    }
    setTimeout(() => {
      dispatch(deleteAssets({ data })).then(response => {
        fetchAssetsList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: "Asset deleted successfully!",
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  };
  /************************************** data for Questionnaire table ****************************************************/
  const dataSource = assetList?.map((item, index) => {
    return (
      {
        assetNumberr: (
          <div className="imgsec">
            <div>{(<label className="ml-15">{(((metaData?.page - 1) * limit) + (index + 1))}</label>)}</div>
          </div>
        ),

        asset_type: (
          <div  className="imgsec disable">
            <span className="fontFamilyText">{item.asset_type}</span>
          </div>
        ),
        created: (
          <div  className="imgsec disable">
            <span className="fontFamilyText">{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss A")}</span>
          </div>
        ),
        actions: (
          <div className="imgsec actionOptions" style={{ marginLeft: 5 }}>
            <button className="deleteBtn" 
            onClick={()=>{
              setCurrentRecord(item);
              setName(item.asset_type); 
            }}>
              <EditOutlined /> <span>Edit</span>
            </button>
            <button className="deleteBtn"
              onClick={() => {
                Swal.fire({
                  title: `Are you sure you want to delete?`,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: '#d3d3d3',
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'NO'
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteAssetById(item)
                  }
                });
              }}
            >
              <DeleteOutlined /> <span>Delete</span>
            </button>
          </div>
        ),
      }
    )
  });
  const saveAsset = async () => {
    const isValid = name.trim() === "" ? false : true;
    if (isValid) {
      setLoading(true);
      let data = {
        type: name
      }
      setTimeout(() => {
        dispatch(addAssets({ data })).then(response => {
          fetchAssetsList();
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#d3d3d3',
            text: "Asset added successfully!",
            color: '#7D80DF',
          });
          setName("");
          setLoading(false)
        }).catch(err => {
          toast.error(err)
          setLoading(false)
        })
      }, 100);
    }
    else {
      setNameError("Asset field is required!")
    }
  };
  const editAsset = async () => {
    const isValid = name.trim() === "" ? false : true;
    if (isValid) {
      setLoading(true);
      let data = {
        type: name,
        assetId: currentRecord?.id
      }
      setTimeout(() => {
        dispatch(updateAssets({ data })).then(response => {
          fetchAssetsList();
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#d3d3d3',
            text: "Asset updated successfully!",
            color: '#7D80DF',
          });
          setName("");
          setCurrentRecord({});
          setLoading(false)
        }).catch(err => {
          toast.error(err)
          setLoading(false)
        })
      }, 100);
    }
    else {
      setNameError("Asset field is required!")
    }
  };
  return (
    <div className="questionnaire">
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Asset Names</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="commonPadding">
        <div className="tabs__body">
          <div>
            <div className="selectores">
              <Row gutter={[12, 12]}>
                <Col xs={24} md={12} lg={6}>
                  <InputCustom
                    label="Enter Asset Name"
                    inputCustum
                    basicInput
                    placeholder="Enter Asset Name"
                    value={name}
                    maxLength={50}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError("")
                    }}
                  />
                  <p className="error">{nameError}</p>
                </Col>
              </Row>
            </div>

            {!currentRecord.id ? (
              <div className="addButton">
                <ButtonCustom label="Add" yellowBtn
                  onClick={() => {
                    saveAsset();
                  }} />
              </div>
            ) : (
              <>
                <div className="update_operation">
                  <div className="addButton">
                    <ButtonCustom label="Update" yellowBtn
                      onClick={() => {
                        editAsset();
                      }}
                    />
                  </div>
                  <div className="addButton cancelButton">
                    <ButtonCustom label="Cancel" yellowBtn
                      onClick={() => {
                        setName("")
                        setCurrentRecord({})
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Table
              dataSource={dataSource}
              columns={QuestionnaireColumn}
              pagination={false}
              size="large"
              className="AssetTable"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assets;
