import React from "react";
import "./Cards.scss";
function Cards(props) {
  const {
    status = "Inactive",
    number = "****************",
    kycstatus,
    feepaid,
    cardstatus,
    availableBalance,
    shippingad,
  } = props;
  return (
    <>
      <div className="cards">
        <div className="cards_vertual">
          <h5 className="">Status : {status}</h5>
          <p>{number}</p>
        </div>
        {number == "****************" ? null : (
          <div className="cards_detail">
            <ul>
              <li>
                <p>Fees Paid</p>
                <p style={{ textTransform: "uppercase" }}>{feepaid}</p>
              </li>
              <li>
                <p>Card Balance</p>
                <p style={{ textTransform: "uppercase" }}>{availableBalance}</p>
              </li>
              <li>
                <p>Card Status</p>
                <p>{cardstatus}</p>
              </li>
              {kycstatus ? (
                <li>
                  <p>Kyc Status</p>
                  <p>{kycstatus}</p>
                </li>
              ) : (
                false
              )}
              {shippingad ? (
                <li>
                  <p>shipping Address</p>
                  <p>{shippingad}</p>
                </li>
              ) : (
                false
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default Cards;
