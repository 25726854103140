import React, { useEffect, useState } from "react";
import { Breadcrumb, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import moment from "moment";

function SecondaryTransactionDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { item, backRoute = "/secondaryProposals" } = location.state || {};
  console.log("ITTEEMMMMM", item);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (item === undefined) {
      navigate("/secondaryProposals");
    }
  }, [item]);

  const columns = [
    {
      title: "Initial Rate (Set by seller)",
      dataIndex: "initial_rate",
      key: "initial_rate",
    },
    {
      title: "Token Name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Quantity of token",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Invested amount",
      dataIndex: "invested_amount",
      key: "invested_amount",
    },
    {
      title: "Purchase instance (Round of secondary buying)",
      dataIndex: "purchase_instance",
      key: "purchase_instance",
    },
    {
      title: "Transaction status",
      dataIndex: "transaction_status",
      key: "transaction_status",
    },
    {
      title: " Date of investment",
      dataIndex: "investment_date",
      key: "investment_date",
    },
  ];

  const dataSource = [
    {
      //   key: item?.key || 1,
      initial_rate: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.initial_rate}</span>
        </div>
      ),
      token_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.proposal_data?.token_name}
          </span>
        </div>
      ),
      token_quantity: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.quantity_of_token}</span>
        </div>
      ),
      invested_amount: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.invested_amount}</span>
        </div>
      ),
      purchase_instance: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.purchase_instance}</span>
        </div>
      ),
      transaction_status: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.status}</span>
        </div>
      ),
      investment_date: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {moment(item?.date_of_investment).format("DD-MM-YYYY HH:mm")}
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => navigate(backRoute)}
            style={{ cursor: "pointer" }}
          >
            {item?.proposal_data?.title}
          </Breadcrumb.Item>
          {/* <Breadcrumb.Item>{item?.proposal_data?.title}</Breadcrumb.Item> */}
        </Breadcrumb>
      </div>
      <div className="commonPadding userDatils">
        <div style={{ marginTop: 15 }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default SecondaryTransactionDetails;
