import cogoToast from "cogo-toast";
import { createRef } from "react";
var timer = createRef();
let counter = 0;

class Toaster {
  success = (title = "Success", message) => {
    let options = { position: "top-right", heading: title };
    if (counter === 0) {
      counter = 1;
      cogoToast.success(message, options);
      timer = setTimeout(() => {
        counter = 0;
      }, 3000);
    }
  };

  error = (title = "Error", message) => {
    let options = { position: "top-right", heading: title };
    if (counter === 0) {
      counter = 1;
      cogoToast.error(message, options);
      timer = setTimeout(() => {
        counter = 0;
      }, 3000);
    }
  };

  info = (title = "Info", message) => {
    let options = { position: "top-right", heading: title };
    cogoToast.info(message, options);
  };
}

export const toast = new Toaster();
