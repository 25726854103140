import { Breadcrumb, ConfigProvider, Space, Table, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import debounce from "lodash.debounce";
import Paginate from "../../Ui/Paginate/Paginate";
import InputCustom from "../../Ui/input/InputCustom";
import {
  getProposalsList,
  getSecondaryProposalsList,
  getSecondaryTransactionsList,
  updateTransactionAfterTransfer,
} from "../../../redux/_actions/assetAction";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import Swal from "sweetalert2";
import { secondaryTokensTransfer } from "../../../Services/web3/ContractServices";

let paginationData = "";

function SecondaryProposals() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  const [isLoading, setLoading] = useState(false);
  const [ProposalList, setProposalList] = useState([]);
  const [TransactionList, setTransactionList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [activeTab, setActiveTab] = useState("proposals");

  const limit = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === "proposals") {
      fetchProposalList();
    } else {
      fetchTransactionList();
    }
  }, [pageCount, search, activeTab]);

  /************************************** fetchProposalList ****************************************************/
  const fetchProposalList = () => {
    setLoading(true);
    const data = { limit, page: pageCount, search };
    setTimeout(() => {
      dispatch(getSecondaryProposalsList({ data }))
        .then((response) => {
          console.log(response.data.data.rows);
          const res = response?.data?.data?.rows || [];
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setProposalList(res);
          } else {
            setProposalList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  /************************************** fetchTransactionList ****************************************************/
  const fetchTransactionList = () => {
    setLoading(true);
    const data = { limit, page: pageCount, search };
    setTimeout(() => {
      dispatch(getSecondaryTransactionsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows || [];
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setTransactionList(res);
          } else {
            setTransactionList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  };

  /**********************************Transfer function ****************************************/
  const handleTransfer = (id, tx_hash) => {
    // proposal_id, approval_status, wallet_address
    const data = {
      transaction_id: id,
      mint_tx_hash: tx_hash,
    };
    console.log("data for approval", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(updateTransactionAfterTransfer({ data }))
        .then((response) => {
          fetchTransactionList();
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  /************************************** table data ****************************************************/
  const proposalColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: "12%",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    {
      title: "Position",
      dataIndex: "open_positions",
      key: "open_positions",
    },
    {
      title: "No. of primary investors",
      dataIndex: "primary_investors",
      key: "primary_investors",
    },
    {
      title: "No. of secondary investors ",
      dataIndex: "secondary_investors",
      key: "secondary_investors",
    },
    {
      title: "No. of sellers",
      dataIndex: "no_of_sellers",
      key: "no_of_sellers",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const transactionColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "transaction_proposal_name",
      key: "transaction_proposal_name",
    },
    {
      title: "Buyer's Name",
      dataIndex: "buyer_name",
      key: "buyer_name",
    },
    {
      title: "Buyer's email",
      dataIndex: "buyer_email",
      key: "buyer_email",
    },
    {
      title: "Seller's name",
      dataIndex: "seller_name",
      key: "seller_name",
    },
    {
      title: "Seller's email",
      dataIndex: "seller_email",
      key: "seller_email",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = (
    activeTab === "proposals" ? ProposalList : TransactionList
  ).map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {(pageCount - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.secondary_proposal_data?.title}
          </span>
        </div>
      ),
      transaction_proposal_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.proposal_data?.title}</span>
        </div>
      ),
      open_positions: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.relisted === 0
              ? "Close"
              : item?.secondary_proposal_data?.relisted === 1
              ? "Open"
              : "NA"}
          </span>
        </div>
      ),
      primary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_primary_investors}
          </span>
        </div>
      ),
      secondary_investors: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.no_of_secondary_investors}
          </span>
        </div>
      ),
      no_of_sellers: (
        <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
          <span className="fontFamilyText">{item?.no_of_sellers}</span>
        </div>
      ),
      token_quantity_consume: (
        <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
          <span className="fontFamilyText">
            {item?.consumed_token_quantity || "0.00"}
          </span>
        </div>
      ),
      transaction_id: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.transaction_id}</span>
        </div>
      ),
      investor_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.investor_name}</span>
        </div>
      ),
      amount: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.amount}</span>
        </div>
      ),
      date: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">{item?.date}</span>
        </div>
      ),
      buyer_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_username}
          </span>
        </div>
      ),
      buyer_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.wallet_data?.buyer_email}
          </span>
        </div>
      ),
      seller_name: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.username}
          </span>
        </div>
      ),
      seller_email: (
        <div className="tableIconStyle">
          <span className="fontFamilyText">
            {item?.seller_wallet_data?.email}
          </span>
        </div>
      ),
      action:
        activeTab === "proposals" ? (
          <Link
            to={"/secondaryProposals/sellerDetails"}
            style={{ marginLeft: -25 }}
            state={{
              // id: item.id,
              proposalId: item.proposal_id,
              proposalName: item?.secondary_proposal_data?.title,
              backRoute: "/secondaryProposals",

              // tokenAddress: item?.token_address,
              // transactionId: item?.transaction_id,
            }}
            className="buttonView"
          >
            <span className="fontFamilyText">
              View <EyeOutlined />
            </span>
          </Link>
        ) : activeTab === "transactions" &&
          item?.token_mint_status == "complete" ? (
          <Link
            to={"/secondaryTransactions/details"}
            style={{ marginLeft: -25 }}
            state={{
              item,
            }}
            className="buttonView"
          >
            <span className="fontFamilyText">
              View <EyeOutlined />
            </span>
          </Link>
        ) : (activeTab === "transactions" &&
            item?.token_mint_status == "pending") ||
          item?.token_mint_status == "failed" ? (
          <Space>
            <ButtonCustom
              label="Transfer"
              lytgaryBtn
              onClick={() => {
                Swal.fire({
                  title: `Approve transfer request?`,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#d3d3d3",
                  confirmButtonText: "Yes",
                  cancelButtonText: "NO",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    // Call secondaryTokensTransfer function here
                    try {
                      setLoading(true);
                      const result = await secondaryTokensTransfer({
                        userAddress: item?.buyer_address,
                        tokenAddress: item?.proposal_data?.token_address,
                        amount: item?.quantity_of_token,
                      });
                      console.log("RESULT", result);
                      // Call handleApproval after successful execution of secondaryTokensTransfer
                      if (result) {
                        handleTransfer(item?.id, result?.txHash);
                      }
                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      console.error(
                        "Error in secondaryTokensTransfer:",
                        error.message
                      );
                    }
                  }
                });
              }}
            >
              Transfer
            </ButtonCustom>
          </Space>
        ) : (
          <Space>
            <ButtonCustom label="Transfer" lytgaryBtn disabled={true}>
              Transfer
            </ButtonCustom>
          </Space>
        ),

      // <Link
      //   to={
      //     activeTab === "proposals"
      //       ? "/secondaryProposals/sellerDetails"
      //       : "/secondaryTransactions/details"
      //   }
      //   style={{ marginLeft: -25 }}
      //   state={
      //     activeTab === "proposals"
      //       ? {
      //           // id: item.id,
      //           proposalId: item.proposal_id,
      //           proposalName: item?.secondary_proposal_data?.title,
      //           backRoute: "/secondaryProposals",

      //           // tokenAddress: item?.token_address,
      //           // transactionId: item?.transaction_id,
      //         }
      //       : { item }
      //   }
      //   className="buttonView"
      // >
      //   <span className="fontFamilyText">
      //     View <EyeOutlined />
      //   </span>
      // </Link>
    };
  });

  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  /************************************** updateSearch ****************************************************/
  //   const updateSearch = (search) => {
  //     setLocalValue(search);
  //     debounceData(search);
  //   };

  //   /************************************** debounceData ****************************************************/
  //   const debounceData = useCallback(
  //     debounce((search) => {
  //       setSearch(search.trim());
  //       setLocalValue(search.trim());
  //       setPageCount(1);
  //     }, 1000),
  //     []
  //   );

  /************************************** handleTabChange ****************************************************/
  const handleTabChange = (key) => {
    setActiveTab(key);
    setPageCount(1); // Reset to the first page when switching tabs
    // setSearch(""); // Reset search when switching tabs
    // setLocalValue(""); // Reset local search input when switching tabs
  };

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>
            {/* {activeTab === "proposals" ? "Raised Quotes" : "Transactions"} */}
            Secondary Marketplace
          </Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Super Admin"}
        </span>
      </div>
      <div className="commonPadding userDatils  tabs__body">
        <Tabs defaultActiveKey="proposals" onChange={handleTabChange}>
          <Tabs.TabPane tab="Raised Quotes" key="proposals">
            <div style={{ marginTop: 15 }}>
              <Table
                dataSource={dataSource}
                columns={proposalColumns}
                pagination={false}
                size="large"
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Secondary market transactions" key="transactions">
            <div style={{ marginTop: 15 }}>
              <Table
                dataSource={dataSource}
                columns={transactionColumns}
                pagination={false}
                size="large"
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default SecondaryProposals;
