import "./RewardManagement.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Table } from "antd";
import Paginate from "../../Ui/Paginate/Paginate";
import Loader from "../../../Loader/Loader";
import { toast } from "../../Ui/Toast/Toast";
import { getClaimRequestList, getUpdateClaimStatus } from "../../../redux/_actions/referralAction";
import debounce from 'lodash.debounce';
import BigNumber from 'bignumber.js';
import Swal from "sweetalert2";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    name: record.name,
  }),
};


function RewardManagement() {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);
  const columns = [
    // {
    //   title: <ButtonCustom lightBtn label="Settle All" />,
    //   dataIndex: "settle",
    //   key: "settle",
    // },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "No. of Referrals",
      dataIndex: "referral",
      key: "referral",
    },
    {
      title: "Request withdrawal Amount(crypto)",
      dataIndex: "deposit_crypto",
      key: "deposit",
    },
    {
      title: "Request withdrawal Amount(fiat)",
      dataIndex: "deposit_fiat",
      key: "deposit",
    },
    {
      title: <span className="center">Action</span> ,
      dataIndex: "action",
      key: "action",
    },
  ];
  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [troneDetail, setTroneDetail] = useState();
  const [tokenAddress, setTokenAddress] = useState();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    console.log('chk search:::::', search)
    fetchClaimList();
  }, [pageCount, search]);

  const permission = () => {
    if (tab_data) {
      let editPermission = tab_data.find(d => d.name === "Reward Management");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const fetchClaimList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    setTimeout(() => {
      dispatch(getClaimRequestList({ data })).then(response => {
        const res = response.data;
        setUserData(res);
        setTokenAddress(response.usdt_token_address);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  // const updateSearch = (search) => {
  //   setLocalValue(search);
  //   debounceData(search)
  // };
  /************************************** table data ****************************************************/
  const data = userData?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (((metaData?.page - 1) * limit) + (index + 1)),
        // settle: "",
        name: item?.AdminApproval_user_data?.user_name || "N/A",
        email: item?.AdminApproval_user_data?.email,
        deposit_crypto: (<label className="ml-100">{item?.amount_in_crypto || 0}</label>),
        deposit_fiat: (<label className="ml-100">{item?.amount_in_fiat || 0}</label>),
        referral: (<label className="ml-50">{(item?.AdminApproval_user_data?.fran_referred + item?.AdminApproval_user_data?.gp_referred + item?.AdminApproval_user_data?.mas_fran_referred + item?.AdminApproval_user_data?.pre_fran_referred)}</label>),
        action: (
          <div className="rewardManagementbtn">
            <button
              className={item?.status === 2 ? "settle" : ""}
              disabled={!permission()}
              onClick={async () => {
                if (item?.status === 1 && permission()) {
                  Swal.fire({
                    title: `Are you sure you want to settle payment?`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#d3d3d3',
                    // cancelButtonColor: '#bec3ca',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'NO'
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      if (item?.amount_in_crypto) {
                        await updateStatus(item, tokenAddress); //TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj
                      } else {
                        settleCallApi(item);
                      }
                    }
                  });
                }
              }}
              style={{ color: "#000" }}
            >
              {" "}
              {item?.status === 2 ? "Settled" : "Settle Payment"}{" "}
            </button>
          </div>
        ),
      }
    )
  });
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );

  /**************************************getBalance****************************************************/
  const getBalance = async (contractAddress) => {
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      if (contractAddress) {
        let contract = await window.tronWeb
          .contract()
          .at(contractAddress);
        const decimals = await contract.methods.decimals().call();
        let result = await contract
          .balanceOf(window.tronWeb.defaultAddress.base58)
          .call();
        return new BigNumber(result.toString())
          .dividedBy(new BigNumber(10).pow(decimals))
          .toNumber();
      } else {
        let walletBalances = await window.tronWeb.trx.getAccount(
          window.tronWeb.defaultAddress.base58
        );
        return walletBalances;
      }
    } else {
      return 0;
    }
  };

  /**************************************update request****************************************************/
  const createAndSendTransactionForToken = async (responseToSendBack, contract_address) => {
    try {
      let contract = await window.tronWeb
        .contract()
        .at(contract_address);
      let result = await contract.decimals().call();
      const decimalsPower = new BigNumber(10).pow(result);

      console.log(
        new BigNumber(responseToSendBack.amount).multipliedBy(decimalsPower).toFixed(0)
      );

      var parameter = [
        { type: 'address', value: responseToSendBack.to_address },
        {
          type: 'uint256',
          value: new BigNumber(responseToSendBack.amount).multipliedBy(decimalsPower).toFixed(0),
        },
      ];

      var options = {
        feeLimit: 50000000,
        callValue: 0,
      };

      const tx = await window.tronWeb.transactionBuilder.triggerSmartContract(
        contract_address,
        "transfer(address,uint256)",
        options,
        parameter,
        window.tronWeb.defaultAddress.base58
      );

      var signedTx = await window.tronWeb.trx.sign(tx.transaction);
      var txid = await window.tronWeb.trx.sendRawTransaction(signedTx);
      setLoading(false);
      return txid;
    } catch (error) {
      setLoading(false);
    }
  };

  const settleCallApi = (item, txid) => {
    const data = {
      id: item?.id,
      user_id: item?.AdminApproval_user_data?.user_id,
      amount_in_fiat: item?.amount_in_fiat,
    }
    if (txid) {
      data.tx_id = txid;
    } else {
      data.tx_id = "txid";
    }
    setTimeout(() => {
      dispatch(getUpdateClaimStatus({ data })).then(response => {
        fetchClaimList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: response,
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  const updateStatus = async (item, contractAddress) => {

    setLoading(true);
    const tronWeb = window.tronWeb;

    if (tronWeb) {
      if (tronWeb.ready) {
        // Get the current address
        let tempBalance = await getBalance(contractAddress);
        if (tempBalance >= 0) {
          if (contractAddress) {
            //we have wallet and we are logged in
            setTroneDetail({
              name: window.tronWeb.defaultAddress.name,
              address: window.tronWeb.defaultAddress.base58,
              balance: item.amount_in_crypto,
              network: window.tronWeb.fullNode.host,
              link: 'true',
            });
            let responseToSendBack = {
              amount: item.amount_in_crypto,
              to_address: item?.AdminApproval_user_data?.user_wallet_relation?.wallet_address
            }
            let response_res = await createAndSendTransactionForToken(responseToSendBack, contractAddress);
            if (response_res) {
              settleCallApi(item, response_res?.txid);
              console.log("response_res", response_res)
            }
          } else {
            let tempFrozenBalance = 0;

            if (!tempBalance.balance) {
              tempBalance.balance = 0;
            }
            if (
              !tempBalance.frozen &&
              !tempBalance.account_resource.frozen_balance_for_energy
            ) {
              tempFrozenBalance = 0;
            } else {
              if (
                tempBalance.frozen &&
                tempBalance.account_resource.frozen_balance_for_energy
              ) {
                tempFrozenBalance =
                  tempBalance.frozen[0].frozen_balance +
                  tempBalance.account_resource.frozen_balance_for_energy
                    .frozen_balance;
              }
              if (
                tempBalance.frozen &&
                !tempBalance.account_resource.frozen_balance_for_energy
              ) {
                tempFrozenBalance = tempBalance.frozen[0].frozen_balance;
              }
              if (
                !tempBalance.frozen &&
                tempBalance.account_resource.frozen_balance_for_energy
              ) {
                tempFrozenBalance =
                  tempBalance.account_resource.frozen_balance_for_energy
                    .frozen_balance;
              }
            }
          }
        }
        else {
          toast.error('Low balance.')
          console.log("Low balance.");
        }
      }
      else {
        toast.error('TronLink not ready or user not logged in.')
        console.log("TronLink not ready or user not logged in.");
      }
    } else {
      toast.error('TronWeb not found. Make sure TronLink is installed.')
      console.log("TronWeb not found. Make sure TronLink is installed.");
    }

  }


  return (
    <div>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Reward Management</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      <div className="commonPadding userDatils Referrals rewardManagment">
        <div style={{ marginTop: 15 }}>
          <Table
            // rowSelection={{
            //   type: selectionType,
            //   //   ...rowSelection,
            // }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
        </div>
        <div></div>
      </div>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default RewardManagement;
