import { Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import { EyeOutlined } from "@ant-design/icons";

import { toast } from "../../Ui/Toast/Toast";
import {
  getStatus,
  getValue,
  maskAddress,
  toFixedExp,
} from "../../../Services/Utils";
import moment from "moment";
import {
  BASE_URL,
  DOWNLOAD_MINT_PROPOSAL_CSV,
  IS_PRODUCTION,
} from "../../../Services/EndPoints";
import Paginate from "../../Ui/Paginate/Paginate";
import Swal from "sweetalert2";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  batchMintingAll,
  getPastEventLogsForBatchMint,
  getPastEventLogsForTokenMint,
  mintTokenForAddress,
} from "../../../Services/web3/ContractServices";
import {
  getAppRequests,
  getMintingInvestmentRequests,
  mintingStatusUpdate,
} from "../../../redux/_actions/assetAction";

let type = "Minting Requests";
let paginationData = "";
function MintingTab(props) {
  const columns = [
    {
      title: "S.NO.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Email Id",
      dataIndex: "ext_email_id",
      key: "ext_email_id",
    },
    {
      title: "User Address",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "Network",
    //   dataIndex: "network",
    //   key: "network",
    // },
    {
      title: "Amount",
      dataIndex: "investedAmt",
      key: "investedAmt",
    },
    {
      title: "Action",
      dataIndex: "View",
      key: "View",
    },
  ];
  const columns1 = [
    {
      title: "S.NO.",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Email Id",
      dataIndex: "ext_email_id",
      key: "ext_email_id",
    },
    {
      title: "User Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Initiator Name",
      dataIndex: "initiator_name",
      key: "initiator_name",
    },
    {
      title: "Initiator Email",
      dataIndex: "initiator_email",
      key: "initiator_email",
    },
    {
      title: "Action",
      dataIndex: "View",
      key: "View",
    },
  ];
  const { TabPane } = Tabs;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState("");
  const [limit, setlimit] = useState(10);
  const [mintingInvestmentRequests, setMintingInvestmentRequests] = useState();
  const [appRequests, setAppRequests] = useState();

  const [activeKey, setActiveKey] = useState("1");

  const id = location.state?.id;
  const projectid = location.state?.projectId;
  const proposal_name = location.state?.proposalName;
  const tokenAddress = location.state?.tokenAddress;

  useEffect(() => {
    type = "Minting Requests";
    // addAgentForProposal(location.state?.id);
  }, []);
  useEffect(() => {
    if (type === "Minting Requests") {
      getProposalInvestmentRequests();
    } else {
      getInvestedAppRequests();
    }
  }, [type, pageCount]);

  function transformData(amount, userAddress, txId, tokenAddress, tx_hash) {
    const addressList = [];

    // Ensure all arrays have the same length
    if (userAddress.length === txId.length && txId.length === amount.length) {
      for (let i = 0; i < userAddress.length; i++) {
        const data = {
          proposal_id: id,
          wallet_address: userAddress[i],
          user_token_quantity: Number(amount[i] / Math.pow(10, 18)),
          transaction_id: txId[i],
          token_address: tokenAddress,
          tx_hash: tx_hash,
        };
        addressList.push(data);
      }
    } else {
      console.error("Arrays must have the same length.");
    }

    return addressList;
  }
  const handleMinted = async (
    amount,
    userAddress,
    txId,
    tokenAddress,
    tx_hash
  ) => {
    // proposal_id, approval_status, wallet_address
    if (Array.isArray(userAddress) && userAddress.length > 0) {
      const data = transformData(
        amount,
        userAddress,
        txId,
        tokenAddress,
        tx_hash
      );

      setLoading(true);
      setTimeout(() => {
        //   console.log("DATA", data);
        dispatch(mintingStatusUpdate({ addressList: data }))
          .then((response) => {
            const res = response;
            getProposalInvestmentRequests();
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    } else {
      const data = {
        proposal_id: id,
        wallet_address: userAddress,
        user_token_quantity: Number(amount / Math.pow(10, 18)),
        transaction_id: txId,
        token_address: tokenAddress, // Include token address
        tx_hash: tx_hash, // Initialize with an empty string, if you have the hash, you can update it later
      };
      console.log("data in handleminted", data);
      setLoading(true);
      setTimeout(() => {
        //   console.log("DATA", data);
        dispatch(mintingStatusUpdate({ addressList: [data] }))
          .then((response) => {
            const res = response;
            getProposalInvestmentRequests();
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }, 100);
    }
  };
  // const handleRejection = (projectId, status, userAddress) => {
  //   const data = {
  //     proposal_id: projectId,
  //     approval_status: status,
  //     wallet_address: userAddress,
  //   };
  //   setLoading(true);
  //   setTimeout(() => {
  //     //   console.log("DATA", data);
  //     dispatch(investmentRequestsAprovalRejection({ data }))
  //       .then((response) => {
  //         const res = response;
  //         console.log(res);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         toast.error(err);
  //         setLoading(false);
  //       });
  //   }, 100);
  // };
  /**************************************fetchUserData****************************************************/
  const getProposalInvestmentRequests = () => {
    const data = {
      proposal_id: location.state?.id,
      // type: type,
      page: pageCount || 1,
      limit: limit,
    };
    setLoading(true);
    setTimeout(() => {
      console.log("DATA", data);
      dispatch(getMintingInvestmentRequests({ data }))
        .then((response) => {
          const res = response?.data?.data?.investmentdata?.rows;
          console.log("RES in TAB", res);
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setMintingInvestmentRequests(res);
          } else {
            setMintingInvestmentRequests();
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const getInvestedAppRequests = () => {
    const data = {
      user_id: location.state?.id,
      // type: type,
      // page: pageCount || 1,
      // limit: limit,
    };
    setLoading(true);
    setTimeout(() => {
      dispatch(getAppRequests({ data }))
        .then((response) => {
          const res = response.data;
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setAppRequests(res?.slice(0, 2));
          } else {
            setAppRequests();
          }
          paginationData = response?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  /************************************** handleTabChange ****************************************************/
  const handleTabChange = (key) => {
    setPageCount(1);
    type = key == 1 ? "Minting Requests" : "App Requests";
    setActiveKey(key);
  };

  const downoaldList = () => {
    const link = `${BASE_URL}${DOWNLOAD_MINT_PROPOSAL_CSV}?proposal_id=${id}`;
    window.open(link);
    return;
  };

  /************************************** dataSource ****************************************************/
  // const dataSource1 = appRequests?.map((item, index) => {
  //   return {
  //     key: item.key,
  //     sno: (
  //       <div style={{ marginLeft: 15 }} className="tableIconStyle ">
  //         <span className="fontFamilyText">
  //           {((pageCount ? pageCount : 1) - 1) * limit + (index + 1)}
  //         </span>
  //       </div>
  //     ),
  //     Username: (
  //       <div
  //         className="tableIconStyle "
  //         style={{ textTransform: "capitalize" }}
  //       >
  //         <span className="fontFamilyText">{"Tamanna"}</span>
  //       </div>
  //     ),
  //     ext_email_id: (
  //       <div className="tableIconStyle ">
  //         <span className="fontFamilyText">
  //           {"tamanna.chandel@antiersolutions.com"}
  //         </span>
  //       </div>
  //     ),
  //     network: (
  //       <div className="tableIconStyle">
  //         <span className="fontFamilyText">{"Matic ERC20"}</span>
  //       </div>
  //     ),
  //     amount: (
  //       <div style={{ marginLeft: 10 }} className="tableIconStyle">
  //         <span className="fontFamilyText">{getValue(item.amount)}</span>
  //       </div>
  //     ),
  //     initiator_name: (
  //       <div style={{ marginLeft: 12 }} className="tableIconStyle">
  //         <span className="fontFamilyText">{"Raman"}</span>
  //       </div>
  //     ),
  //     initiator_email: (
  //       <div style={{ marginLeft: -22 }} className="tableIconStyle">
  //         <span className="fontFamilyText">
  //           {"tamanna.chandel@antiersolutions.com"}
  //         </span>
  //       </div>
  //     ),
  //     View: (
  //       <>
  //         <div style={{ marginLeft: -22 }} className="agreementBtn">
  //           <button
  //             onClick={() => {
  //               Swal.fire({
  //                 title: `Approve`,
  //                 icon: "question",
  //                 showCancelButton: true,
  //                 confirmButtonColor: "#d3d3d3",
  //                 confirmButtonText: "Yes",
  //                 cancelButtonText: "NO",
  //               }).then((result) => {});
  //             }}
  //           >
  //             Approve
  //           </button>
  //           <button
  //             onClick={() => {
  //               Swal.fire({
  //                 title: `Decline`,
  //                 icon: "question",
  //                 showCancelButton: true,
  //                 confirmButtonColor: "#d3d3d3",
  //                 confirmButtonText: "Yes",
  //                 cancelButtonText: "NO",
  //               }).then((result) => {});
  //             }}
  //             className={`declineBtn`}
  //           >
  //             Decline
  //           </button>
  //         </div>
  //       </>
  //     ),
  //   };
  // });
  /************************************** dataSource ****************************************************/
  const dataSource = mintingInvestmentRequests?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {((pageCount ? pageCount : 1) - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      Username: (
        <div
          className="tableIconStyle "
          style={{ textTransform: "capitalize" }}
        >
          <span className="fontFamilyText">{item?.wallet_data?.username}</span>
        </div>
      ),
      ext_email_id: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{item?.wallet_data?.email}</span>
        </div>
      ),
      address: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{item?.user_adrs}</span>
        </div>
      ),
      // network: (
      //   <div className="tableIconStyle">
      //     <span className="fontFamilyText">{"Matic ERC20"}</span>
      //   </div>
      // ),
      investedAmt: (
        <div style={{ marginLeft: 12 }} className="tableIconStyle">
          <span className="fontFamilyText">
            {getValue(item.user_token_quantity)}
          </span>
        </div>
      ),
      View:
        (item?.token_mint_status === "pending" ||
          item?.token_mint_status === "failed") &&
        item?.qa_status === null ? (
          <>
            <div style={{ marginLeft: -22 }} className="agreementBtn">
              <button
                disabled={
                  item.soft_cap_status !== 1 ||
                  // item.hard_cap_status !== 1 ||
                  item?.wallet_data?.approval_status !== "approved"
                } // Disable if soft_cap_status is not 1
                onClick={() => {
                  const endDateTime = moment(item?.proposal_data?.end_date);
                  const currentDateTime = moment();

                  console.log("endDateTime", endDateTime);
                  console.log("currentDateTime", currentDateTime);
                  console.log(
                    "currentDateTime.isSameOrAfter(endDateTime)",
                    currentDateTime.isSameOrAfter(endDateTime, "day")
                  );

                  if (currentDateTime.isSameOrAfter(endDateTime, "day")) {
                    Swal.fire({
                      title: `Are you sure you want to mint?`,
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#d3d3d3",
                      confirmButtonText: "Yes",
                      cancelButtonText: "NO",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          setLoading(true);
                          const { amount, userAddress, txHash } =
                            await mintTokenForAddress({
                              userAddress: item?.user_adrs,
                              tokenAddress: location.state?.tokenAddress,
                              amount: item?.user_token_quantity,
                            });
                          if ((amount, userAddress, txHash)) {
                            handleMinted(
                              amount,
                              userAddress,
                              item?.id,
                              tokenAddress,
                              txHash
                            );
                          }
                          setLoading(false);
                        } catch (error) {
                          setLoading(false);
                          console.error(
                            "Error in addressWhitelistSingle:",
                            error.message
                          );
                        }
                      }
                    });
                  } else {
                    toast.error("Minting is not allowed before end date.");
                  }
                }}
              >
                Mint
              </button>
            </div>
          </>
        ) : item?.qa_status === 0 ? (
          <div style={{ marginLeft: -22 }}>In process</div>
        ) : (
          <div style={{ marginLeft: -22 }}>{item.token_mint_status}</div>
        ),
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  const handleMintAll = async () => {
    const confirmation = await Swal.fire({
      title: "Mint All",
      text: "Are you sure you want to mint all requests?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d3d3d3",
      confirmButtonText: "Yes",
      cancelButtonText: "NO",
    });

    if (!confirmation.isConfirmed) {
      toast.error("Agent declined to mint all requests!");
      return;
    }

    console.log("mintingInvestmentRequests", mintingInvestmentRequests);

    if (!mintingInvestmentRequests || mintingInvestmentRequests.length === 0) {
      toast.error("No data to mint!");
      return;
    }

    const pendingRequests = mintingInvestmentRequests.filter(
      (item) =>
        item?.token_mint_status === "pending" && item?.qa_status === null
    );

    const unwhitelistedAddresses = pendingRequests.filter(
      (item) => item?.wallet_data?.approval_status !== "approved"
    );

    if (unwhitelistedAddresses.length > 0) {
      toast.error("Some addresses are not whitelisted!");
      return;
    }

    if (pendingRequests.length === 0) {
      Swal.fire({
        title: "No Pending Requests",
        text: "There are no pending requests to mint!.",
        icon: "info",
      });
      return;
    }

    const softCapStatus = pendingRequests[0].soft_cap_status;
    const endDateTime = moment(pendingRequests[0]?.proposal_data?.end_date);
    const currentDateTime = moment();

    console.log("endDateTime", endDateTime);
    console.log("currentDateTime", currentDateTime);
    console.log(
      "currentDateTime.isSameOrAfter(endDateTime)",
      currentDateTime.isSameOrAfter(endDateTime, "day")
    );

    console.log("pendingRequests", pendingRequests);

    const userAddresses = [];
    const amounts = [];
    const tokenAddress = location.state?.tokenAddress;
    const trxId = [];

    pendingRequests.forEach((item) => {
      userAddresses.push(item.user_adrs);
      amounts.push((item.user_token_quantity * Math.pow(10, 18)).toString());
      trxId.push(item.id);
    });

    if (
      softCapStatus === 1 &&
      currentDateTime.isSameOrAfter(endDateTime, "day")
    ) {
      try {
        setLoading(true);
        const data = { userAddresses, amounts, tokenAddress };
        console.log("DATA BEFORE HITTING CONTRACT METHOD", data);
        const { mintedAmounts, walletAddresses, txHash } =
          await batchMintingAll({
            data,
          });
        if ((amounts, userAddresses, txHash)) {
          handleMinted(
            mintedAmounts,
            walletAddresses,
            trxId,
            tokenAddress,
            txHash
          );
          Swal.fire({
            title: "Success!",
            text: "All requests have been approved.",
            icon: "success",
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
        console.error("Error in mintingAll method:", error.message);
      }
    } else {
      toast.error("Soft cap or end date not reached!");
    }
  };

  return (
    <div>
      <div className="tabs__body">
        <div className="addnewSec">
          {activeKey == 1 ? (
            <ButtonCustom onClick={handleMintAll} label="Mint All" lytgaryBtn />
          ) : null}
          {/* <ButtonCustom
            onClick={getPastEventLogsForTokenMint}
            label="Past Mint Events"
            garyBtn
          />
          <ButtonCustom
            onClick={getPastEventLogsForBatchMint}
            label="Past BatchMint Events"
            garyBtn
          /> */}

          <ButtonCustom
            // onClick={() => alert("download csv")}
            onClick={() => downoaldList()}
            label="Download CSV"
            lytgaryBtn
          />
        </div>
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          defaultActiveKey="1"
        >
          <TabPane tab="Minting Requests" key="1">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="large"
            />
          </TabPane>
          {/* <TabPane tab="App Requests" key="2">
            <Table
              dataSource={dataSource1}
              columns={columns1}
              pagination={false}
              size="large"
            />
          </TabPane> */}
        </Tabs>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default MintingTab;
