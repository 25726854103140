import { Col, Radio, Row, Space } from "antd";
import React, { useState } from "react";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import Loader from ".././../../Loader/Loader";
import * as Constants from '../../../constants/constants';
import { useDispatch } from "react-redux";
import { addAdmin, roleDetailByRoleId } from "../../../redux/_actions/subAdminAction";
import Swal from "sweetalert2";
import { toast } from "../../Ui/Toast/Toast";

export default function UserAcessForm({ roleList, handleCancel, fetchAdminList }) {
  const initilaObj = {
    name: "",
    email: "",
    role_id: null,
    error: {
      name: "",
      email: "",
      role_id: "",
    },
  };
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [allState, setAllState] = useState(initilaObj);
  const [userAccess, setUserAccess] = useState([]);
  const handleValidaton = (data) => {
    var error = allState?.error;
    var isValidate = true;
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "name":
          if (value === undefined || value?.length === 0) {
            error[key] = "Name Field Is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "email":
          if (value === undefined || value?.length === 0) {
            error[key] = "Email Field is Required";
            isValidate = false;
          } else if (!Constants.EMAIL_REGEX.test(value)) {
            error[key] = "Invalid email!";
            isValidate = false;
          } else {
            error[key] = "";
          }
          break;
        case "role_id":
          if (value === null || value?.length === 0) {
            error[key] = "Role Field is Required";
            isValidate = false;
          } else {
            error[key] = "";
          }
        default:
      }
    }
    setAllState({ ...allState, error: { ...error } });
    return isValidate;
  };
  const resetState = () => {
    setAllState(initilaObj);
  }
  const setValue = (event, name, value) => {
    if (name) {
      if (value !== "") {
        handleValidaton({ [name]: value });
      }
      setAllState({ ...allState, [name]: value });
    } else {
      // if (event.target.value !== "") {
      //   handleValidaton({ [event.target.name]: event.target.value });
      // }
      if (event.target.value !== "") {
        var error = allState?.error;
        error[event.target.name] = ""
        setAllState({ ...allState, error: { ...error } });
      }

      setAllState({ ...allState, [event.target.name]: event.target.value });
    }

  };
  const addUserAccess = () => {
    let selected_role = roleList.find(d => d.value == allState.role_id);
    let isValid = handleValidaton({
      name: allState.name,
      email: allState.email,
      role_id: allState.role_id
    });
    if (isValid) {
      const data = {
        name: allState.name,
        email: allState.email,
        role_id: allState.role_id,
        role_name: selected_role.label,
        user_access: JSON.stringify(userAccess)
      }
      setLoading(true);
      setTimeout(() => {
        dispatch(addAdmin({ data })).then(response => {
          resetState();
          fetchAdminList();
          handleCancel();
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#d3d3d3',
            text: "User Added successfully!",
            color: '#7D80DF',
          });

          setLoading(false)
        }).catch(err => {
          toast.error(err)
          setLoading(false)
        })
      }, 100);
    }
  }
  const fetchRoleDetailByRoleId = (id) => {
    const data = {
      role_id: id
    }
    setLoading(true);
    setTimeout(() => {
      dispatch(roleDetailByRoleId({ data })).then(response => {
        let newAccess = [];
        response?.admin_role_menu_list_data.map((item, index) => {
          return newAccess.push({
            id: item?.admin_menu_list_data?.id,
            name: item?.admin_menu_list_data?.menu_list,
            access_name: "Edit"
          })
        });
        setUserAccess(newAccess);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  const updateAccess = (access, id) => {
    let temp = [...userAccess];
    for (let i = 0; i < temp?.length; i++) {
      if (temp[i].id === id) {
        temp[i].access_name = access;
      }
    }
    setUserAccess(temp);
  }
  return (
    <div className="userAcessForm">
      <Row gutter={[24, 24]}>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <InputCustom
            label="Name"
            basicInput
            inputCustum
            eyeicon
            placeholder="Name"
            name={"name"}
            maxLength={100}
            value={allState.name}
            onChange={(e) => {
              setValue(e);
            }}
          />
          <p className="error">{allState.error.name}</p>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <InputCustom
            label="Email"
            basicInput
            inputCustum
            eyeicon
            placeholder="Email"
            maxLength={100}
            name={"email"}
            value={allState.email}
            onChange={(e) => {
              setValue(e);
            }}
          />
          <p className="error">{allState.error.email}</p>
        </Col>
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <CustomSelect
            data={roleList}
            name="role_id"
            allowClear
            defaultData={allState.role_id ? allState.role_id : null}
            onChange={(e) => {
              setValue("", "role_id", e);
              fetchRoleDetailByRoleId(e)
            }}
            label="Role"
            placeholder="Role"></CustomSelect>
          <p className="error">{allState.error.role_id}</p>
        </Col>
        {/* ----------------------------------------------------------- */}
        {allState.role_id &&
          <Col xs={24}>
            <label className="formLabel">Access</label>
            <div className="radioGroupDiv">
              {userAccess?.map((item, index) => {
                return (
                  <Radio.Group value={item.access_name} >
                    <Space direction="vertical">
                      <label className="formLabel pb-0">{item?.name}</label>
                      <Radio
                        onChange={(e) => {
                          updateAccess("View Only", item.id);
                        }}
                        value={"View Only"}>View Only</Radio>
                      <Radio
                        onChange={(e) => {
                          updateAccess("Edit", item.id);
                        }}
                        value={"Edit"}>Edit</Radio>
                    </Space>
                  </Radio.Group>
                )
              })}
            </div>
          </Col>}
        {/* ----------------------------------------------------------- */}
        <Col xs={24}>
          <ButtonCustom label="Submit"
            onClick={() => {
              addUserAccess();
            }}
            lytgaryBtn />
        </Col>
      </Row>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div >
  );
}
