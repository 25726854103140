import { Modal } from "antd";
import React from "react";
import "./ModalCommon.scss";
function ModalCommon({ isModalVisible, handleOk, handleCancel, children,headTittle }) {
  return (
    <div>
      <Modal
        className="comnModal"
        title={headTittle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
      >
        {children}
      </Modal>
    </div>
  );
}

export default ModalCommon;
