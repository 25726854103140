import React, { Component } from "react";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { labelText, value, name, checked, newClass, labelLink } = this.props;
    return (
      <div className={`checkBoxCustom withLabelLink ${this.props.disabled && "setDisableCurser"}`}>
        <input
          value={value}
          name={name}
          type="checkbox"
          checked={checked}
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
        <label className={"checkBoxContainer" + (newClass ? " " + newClass : "")}>
        {labelText}
        </label>
        {this.props.error?.length > 0 ? (
          <label className="error">{this.props.error}</label>
        ) : null}
      </div>
    );
  }
}

export default Checkbox;
