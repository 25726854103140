import {
  Breadcrumb,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, DOWNLOAD_REWARD_HISTORY } from '../../../Services/EndPoints';
import * as Constants from '../../../constants/constants';
import Paginate from "../../Ui/Paginate/Paginate";
import "./ReferralDetails.scss";
import { getReferralDetail, getReferralTypeList, getRewardHistory, updateUserLevel } from "../../../redux/_actions/referralAction";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import NodeRSA from "node-rsa";
import debounce from 'lodash.debounce';
import InputCustom from "../../Ui/input/InputCustom";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import ModalCommon from "../ModalCommon/ModalCommon";
function ReferralDetails() {
  const navigate = useNavigate()
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const limit = 10;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [rewardHistory, setRewardHistory] = useState();
  const [referralType, setReferralType] = useState();
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localValue, setLocalValue] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [selectedLevelError, setSelectedLevelError] = useState('');

  useEffect(() => {
    if (location.state?.user_id) {
      fetchReferralTypeList();
      fetchReferralDetail();
      fetchReferralRewardHistory();
    }
    else {
      navigate("/")
    }
  }, [pageCount, search, selectedType]);

  const permission = () => {
    if (tab_data) {
      let editPermission = tab_data.find(d => d.name === "Referrals");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const fetchReferralDetail = () => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
    }
    setTimeout(() => {
      dispatch(getReferralDetail({ data })).then(response => {
        setUserData(response)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  const fetchReferralTypeList = () => {
    setTimeout(() => {
      dispatch(getReferralTypeList()).then(response => {
        setReferralType(response)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  const fetchReferralRewardHistory = () => {
    setLoading(true);
    const data = {
      user_id: location.state?.user_id,
      page: pageCount,
      limit: limit,
      search: search,
    }
    if (selectedType) {
      data.referral_type_id = selectedType?.value;
    }
    console.log("datadata", data)
    setTimeout(() => {
      dispatch(getRewardHistory({ data })).then(response => {
        setRewardHistory(response?.data);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  const showModal = () => {
    if (permission()) {
      setIsModalOpen(true);
    }
  };
  const handleOk = () => {
    updateLevel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  /************************************** table data ****************************************************/
  const dataSource = rewardHistory?.map((item, index) => {
    return (
      {
        key: index + 1,
        SNo: (((metaData?.page - 1) * limit) + (index + 1)),
        name: item?.referralCommission_user_data?.user_name,
        email: item?.referralCommission_user_data?.email,
        referral: moment(item?.created_at).format("DD/MM/YYYY"),
        rewards: (<label className="ml-50">{"$" + item?.commission_amount}</label>),
      }
    )
  });
  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Referral Date",
      dataIndex: "referral",
      key: "referral",
    },
    {
      title: "Rewards Earned",
      dataIndex: "rewards",
      key: "rewards",
    },
  ];

  <Table dataSource={dataSource} columns={columns} />;
  const menu = (
    <Menu className="topdropdown">
      <Menu.Item style={{ marginTop: "7px" }}>
        <b> {selectedType?.label} </b>
        <Menu
          className="trsp_itme"
          style={{ backgroundColor: "transparent !important" }}
        >
          <Menu.Item
            onClick={(event) => {
              setSelectedType()
            }}
            className="subitems"
            style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
          >
            All
          </Menu.Item>
          {referralType?.map((item, index) => {
            return (
              <Menu.Item
                onClick={(event) => {
                  setSelectedType(item)
                }}
                className="subitems"
                style={{ lineHeight: "30px", height: "30px", listStyle: "none" }}
              >
                {item.label}
              </Menu.Item>
            )
          }
          )}
        </Menu>
      </Menu.Item>
    </Menu>
  );
  const getDepositAmount = () => {
    let amount = 0;
    if (userData?.user_details?.referrals_user_data?.user_referralAdminApproval_data?.length > 0) {
      let general_public_id = referralType[0]?.value;
      let type_id = userData?.user_details?.referrals_user_data?.user_referralAdminApproval_data?.find(d => d?.referral_type_id === general_public_id);
      amount = type_id?.amount_in_crypto ?? 0;
    }
    return amount;
  }
  const download_Csv = () => {
    // const second = new NodeRSA(Constants.KEY);
    // second.setOptions({ encryptionScheme: 'pkcs1' });
    // const enc = second.encrypt(location.state?.user_id, 'base64');
    // const base64UrlSafeEncodedData = enc.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    if (selectedType) {
      const link = `${BASE_URL}${DOWNLOAD_REWARD_HISTORY}?user_id=${location.state?.user_id}&search=${search}&referral_type_id=${selectedType?.value}`
      window.open(link);
    }
    else {
      const link = `${BASE_URL}${DOWNLOAD_REWARD_HISTORY}?user_id=${location.state?.user_id}&search=${search}`
      window.open(link);
    }
    return
  }
  const updateLevel = () => {
    if (selectedLevel === "") {
      setSelectedLevelError("Please select level!")
      return;
    }
    const data = {
      user_id: location.state?.user_id,
      referral_type_id: userData?.user_details?.referrals_user_data?.referral_type_id,
      next_referral_type_id: selectedLevel,
    }
    setLoading(true);
    setTimeout(() => {
      dispatch(updateUserLevel({ data })).then(response => {
        fetchReferralTypeList();
        fetchReferralDetail();
        fetchReferralRewardHistory();
        setLoading(false);
        setIsModalOpen(false);
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }
  return (
    <div className="">
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>Referral Details</Breadcrumb.Item>
        </Breadcrumb>
        <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span>
      </div>
      {console.log("userData", userData)}
      <div className="commonPadding">
        <div className="ReferralDetails-Top">
          <div className="cards ReferralDetails-card">
            <div className="cards_detail Category">
              <ul style={{ color: "#000" }}>
                <li>
                  <p>Name</p>
                  <p style={{ textTransform: "" }}> {userData?.user_details?.referrals_user_data?.user_name}</p>
                </li>
                <li>
                  <p>Email</p>
                  <Tooltip title={userData?.user_details?.referrals_user_data?.email}>
                    <p className="ellipsis">
                      {userData?.user_details?.referrals_user_data?.email}
                    </p>
                  </Tooltip>
                </li>
                <li>
                  <p>Category</p>
                  <div>
                    <p>
                      {userData?.user_details?.type} <EditOutlined className={`${!permission() ? "disabled" : ""}`} onClick={showModal} />
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="cards_detail referralsEarned">
              <ul style={{ color: "#000" }}>
                <li>
                  <p>Total No. of Referrals</p>
                  <p style={{ textTransform: "" }} className="right-text">{(userData?.user_details?.referrals_user_data?.mas_fran_referred ?? 0) + (userData?.user_details?.referrals_user_data?.pre_fran_referred ?? 0) + (userData?.user_details?.referrals_user_data?.gp_referred ?? 0) + (userData?.user_details?.referrals_user_data?.fran_referred ?? 0)}</p>
                </li>
                <li>
                  <p>Total Referrals Earned</p>
                  <p style={{ textTransform: "" }} className="right-text">${userData?.total_rewards ?? 0}</p>
                </li>
                <li>
                  <p>Application For Upgrade</p>
                  <div className="applicationBtn">
                    <button>{userData?.user_details?.referrals_user_data?.user_referralAdminApproval_data?.length > 0 ? 'Yes' : 'No'}</button>
                  </div>
                </li>
                <li>
                  <p>Amount deposit</p>
                  <p className="right-text">${getDepositAmount()} </p>
                </li>

                <li>
                  <p></p>
                  <span className="right-text">(General Public to Franchisee)</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="transec_histry__heading">
          <h2>Referral Details</h2>
          <div className="addnewSec">
            <div className="referralDropdown referralDetails-RightSide">
              {/* <div style={{ justifyContent: "end", display: "flex" }}>
                <InputCustom
                  basicInput
                  placeholder="Search..."
                  maxLength={50}
                  searchImg={<SearchOutlined />}
                  inputSearch
                />
              </div> */}
              <div className="annoucements_block_multiData">
                <div style={{ justifyContent: 'end', display: 'flex' }}>
                  <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
                </div>
              </div>
              <Dropdown overlay={menu}>
                <button>All</button>
              </Dropdown>
              <ButtonCustom
                onClick={() => {
                  if (rewardHistory?.length > 0) {
                    download_Csv()
                  }
                }}
                className={`download_btn ${rewardHistory?.length > 0 ? "" : "disabled"}`} label="Download" garyBtn />
            </div>
          </div>
        </div>
        <div className="generalCarde">
          {referralType?.map((item, index) => {
            return (
              <>
                <div className="cards_detail general">
                  <ul style={{ color: "#000" }}>
                    <li>
                      <p>{item?.label}</p>
                      {item?.value === 1 &&
                        <p style={{ textTransform: "" }}>{userData?.user_details?.referrals_user_data?.gp_referred}</p>
                      }
                      {item?.value === 2 &&
                        <p style={{ textTransform: "" }}>{userData?.user_details?.referrals_user_data?.fran_referred}</p>
                      }
                      {item?.value === 3 &&
                        <p style={{ textTransform: "" }}>{userData?.user_details?.referrals_user_data?.pre_fran_referred}</p>
                      }
                      {item?.value === 4 &&
                        <p style={{ textTransform: "" }}>{userData?.user_details?.referrals_user_data?.mas_fran_referred}</p>
                      }
                    </li>
                  </ul>
                </div>
              </>
            )
          }
          )}
        </div>
        <div className="userDatils ">
          <div style={{ marginTop: 15 }}>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="large"
            />
          </div>
          {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
        </div>
      </div>
      <ModalCommon
        // title={<><label className="heading-type"> Current Level</label> <label>{userData?.user_details?.type}</label></>}
        // visible={isModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
        headTittle={<><label className="heading-type"> Current Level</label> <label>{userData?.user_details?.type}</label></>}
        isModalVisible={isModalOpen}
        handleCancel={handleCancel}
      >
        <div>
          <p className="error">{selectedLevelError}</p>
          <Radio.Group>
            <Space direction="vertical">
              {referralType?.map((item, index) => {
                return (
                  <>
                    {userData?.user_details?.referrals_user_data?.referral_type_id !== item.value &&
                      <Radio
                        onChange={(event) => {
                          setSelectedLevelError("")
                          setSelectedLevel(event.target.value);
                        }}
                        value={item?.value}>{item?.label}</Radio>
                    }
                  </>
                )
              }
              )}
            </Space>
          </Radio.Group>
          <div className="addnewSec referral_detail"><ButtonCustom label="No" className="no_button" yellowBtn onClick={() => handleCancel()} /><ButtonCustom className="yes_button" label="Yes" yellowBtn onClick={() => handleOk()} /></div>
        </div>
      </ModalCommon>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}
export default ReferralDetails;
