import React, { useCallback, useEffect, useState } from "react";
import "./SubAdmin.scss";
import { Checkbox, Select } from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import ModalCommon from "../ModalCommon/ModalCommon";
import UserAcessForm from "./UserAcessForm";
import ViewAccessForm from "./ViewAccessForm";
import InputCustom from "../../Ui/input/InputCustom";
import { getRoleListForDropdown, getAdminList, deleteAdmin, adminDetailById, resendPassword } from "../../../redux/_actions/subAdminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../Ui/Toast/Toast";
import Loader from ".././../../Loader/Loader";
import Paginate from "../../Ui/Paginate/Paginate";
import debounce from 'lodash.debounce';

export default function UserAccess({ activeTab }) {
  const login = useSelector((state) => state?.LoginReducer?.userData);
  let tab_data = login?.admin_user_access_data?.access && JSON.parse(login?.admin_user_access_data?.access);

  const dispatch = useDispatch();
  const limit = 10;
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenView, setIsModalOpenView] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [metaData, setMetaData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('Filter');
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    fetchRoleList();
    fetchAdminList();
  }, [activeTab, search, pageCount]);

  const permission = () => {
    if (tab_data) {
      let editPermission = tab_data.find(d => d.name === "Sub Admin");
      if (editPermission.access_name === "Edit") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const fetchRoleList = () => {
    setTimeout(() => {
      dispatch(getRoleListForDropdown({})).then(response => {
        setRoleList(response);
      }).catch(err => {
        toast.error(err)
      })
    }, 100);
  }
  const fetchAdminList = (filter) => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
      search: search
    }
    if (filter) {
      data.role_id = filter;
    }
    setTimeout(() => {
      dispatch(getAdminList({ data })).then(response => {
        const res = response.data;
        let newData = [];
        res.map((item, index) => {
          return newData.push({
            id: item.id,
            admin_id: item.admin_id,
            name: item.name,
            email: item.email,
            role_name: item.role_name,
            isChecked: false,
            status: item.status,
            status_string: item.status === 1 ? "Pending" : (item.status === 2 ? "Activated" : "Expired"),
            status_class: item.status === 1 ? "userpanding" : (item.status === 2 ? "useractive" : "userExpired"),
            access: JSON.parse(item.access)
          })
        });
        setAdminList(newData);
        setMetaData(response?.meta);
        setTotalPages(response?.meta?.pages)
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  }

  const getAccessRows = (access) => {
    return access?.map(menu_list => `${menu_list.name}`).join(' | ')
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModalView = () => {
    let currentRow = adminList.find(d => d.isChecked);
    fetchAdminDetail(currentRow);
  };
  const handleCancelView = () => {
    setIsModalOpenView(false);
  };
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1)
  }
  const changeCheckboxChild = (checked, id) => {
    let myUsers = adminList;
    myUsers.map((user, index) => {
      if (id === "p2") {
        setIsParentChecked(checked);
        if (checked) {
          user.isChecked = checked;
        } else {
          user.isChecked = checked;
        }
      } else {
        if (user.id === id) {
          if (checked) {
            user.isChecked = checked;
          } else {
            user.isChecked = checked;
          }
        }
      }
      const isAllChildsChecked = myUsers.every(
        (user) => user.isChecked === true
      );
      if (isAllChildsChecked) {
        setIsParentChecked(checked);
      } else {
        setIsParentChecked(false);
      }
      return user;
    });
    setAdminList([...myUsers]);
  };
  const checkCheckboxChecked = () => {
    let checked = adminList.filter(d => d.isChecked).length;
    if (checked > 1) {
      return false;
    }
    else {
      if (checked == 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }
  const deleteAdminById = (item) => {
    setLoading(true);
    const data = {
      admin_ids: adminList?.filter(d => d.isChecked).map(d => d.admin_id)
    }
    setTimeout(() => {
      dispatch(deleteAdmin({ data })).then(response => {
        fetchAdminList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: "User deleted successfully!",
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  };
  const sendPassword = (item) => {
    setLoading(true);
    const data = {
      admin_id: item.admin_id,
      email: item.email
    }
    setTimeout(() => {
      dispatch(resendPassword({ data })).then(response => {
        fetchAdminList();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          confirmButtonColor: '#d3d3d3',
          text: "Password resend successfully!",
          color: '#7D80DF',
        })
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  };
  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search)
  };
  /**************************************debounceData****************************************************/
  const debounceData = useCallback(debounce(search => {
    setSearch(search.trim());
    setLocalValue(search.trim());
    setPageCount(1)
  }, 1000),
    [],
  );
  // admin detail
  const fetchAdminDetail = (item) => {
    setLoading(true);
    const data = {
      admin_id: item.admin_id
    }
    setTimeout(() => {
      dispatch(adminDetailById({ data })).then(response => {
        setCurrentRow({
          access: JSON.parse(response.access),
          email: response.email,
          id: response.id,
          admin_id: response.admin_id,
          name: response.name,
          role_id: response.role_id,
          role_name: response.role_name,
          status: response.status
        })
        setIsModalOpenView(true);
        setLoading(false)
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      })
    }, 100);
  };
  return (
    <div className="userAccess">
      <div className="userAccess_head">

        <div className="userAccess_head-buttons">
          {permission() &&
            <>
              <button className="userAccess_head_addBtn" onClick={showModal}>
                <PlusCircleOutlined />
              </button>

              <ButtonCustom
                label="View Access"
                title={!checkCheckboxChecked() ? "Please select only one row" : ""}
                lytgaryBtn
                onClick={showModalView}
                disabled={!checkCheckboxChecked()}
              />
              {/* <ButtonCustom label="Edit" lytgaryBtn /> */}
              <ButtonCustom
                onClick={() => {
                  Swal.fire({
                    title: `Are you sure you want to delete?`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#d3d3d3',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'NO'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteAdminById()
                    }
                  });
                }}
                title={adminList.filter(d => d.isChecked).length <= 0 ? "Select atleast one record" : ""}
                disabled={adminList.filter(d => d.isChecked).length <= 0}
                label="Delete"
                lytgaryBtn />
            </>
          }
        </div>

        <div className="userAccess_head_right">
          <ButtonCustom
            label="Clear"
            lytgaryBtn
            onClick={() => {
              setFilter('Filter');
              setSearch('');
              setLocalValue('');
              fetchAdminList();
            }}
          />
          <Select
            className="selectuserAcces"
            defaultValue="Filter by Role"
            style={{
              width: 170,
            }}
            value={filter ? filter : "Filter"}
            options={roleList}
            onChange={(e) => {
              setFilter(e);
              fetchAdminList(e);
            }}
          />
          <InputCustom basicInput placeholder="Search..." maxLength={50} searchImg={<SearchOutlined />} inputSearch value={localValue} onChange={(e) => { updateSearch(e.target.value) }} />
        </div>
      </div>
      <div className="userAccess_body user-access-custom">
        <table class="ant-table">
          <thead className="ant-table-thead">
            <tr>
              <th>
                {adminList.length > 0 &&
                  <>
                    <Checkbox
                      value="parent"
                      onChange={(e) => changeCheckboxChild(e.target.checked, "p2")}
                      checked={isParentChecked}
                    //disabled={isSelectAll || (selectedArray.length >= 100 && !isParentChecked)}
                    />
                  </>}
              </th>
              <th>S.No.</th>
              <th>Email id</th>
              <th>Name</th>
              <th>Role</th>
              <th>Access</th>
            </tr>
          </thead>
          <tbody>
            {adminList.length <= 0 &&
              <tr class="ant-table-placeholder">
                <td colspan="6" class="ant-table-cell">
                  <div class="ant-empty ant-empty-normal">
                    <div class="ant-empty-image">
                      <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg></div>
                    <div class="ant-empty-description">No data</div>
                  </div>
                </td>
              </tr>
            }
            {adminList.length > 0 &&
              adminList.map((item, index) => {
                return [
                  <>
                    <tr>
                      <td>
                        <Checkbox
                          checked={item?.isChecked}
                          value="child"
                          onChange={(e) => changeCheckboxChild(e.target.checked, item.id)}
                        //disabled={isSelectAll || (selectedArray.length >= 100 && !item.isChecked)}
                        />
                      </td>
                      <td>{(((metaData?.page - 1) * limit) + (index + 1))}</td>
                      <td>
                        {item.email ? item.email : "N/A"}
                      </td>
                      {item.status === 3 ?
                        <td> {[<><p className="name_spacing">{item.name}</p> <p className={item.status_class}>{item.status_string} <span> |</span> <a href="javascript:void(0)"
                          onClick={() => {
                            Swal.fire({
                              title: `Are you sure you want to resend invite?`,
                              icon: "question",
                              showCancelButton: true,
                              confirmButtonColor: '#d3d3d3',
                              confirmButtonText: 'Yes',
                              cancelButtonText: 'NO'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                sendPassword(item)
                              }
                            });
                          }}>Resend</a> </p></>]}</td> :
                        <td> {[<><p className="name_spacing">{item.name}</p> <p className={item.status_class}>{item.status_string} </p></>]}</td>}
                      <td>
                        {item.role_name}
                      </td>
                      <td>
                        {(
                          <div className="tableInineFlexed">
                            {getAccessRows(item?.access)}
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                ];
              })}

          </tbody>
        </table>

        {totalPages > 1 && <Paginate paginationData={totalPages} forcePage={pageCount == '' ? 0 : pageCount - 1} handlePageClick={(page) => handlePageClick(page)} />}
      </div>
      <ModalCommon
        headTittle={"User Access Form"}
        isModalVisible={isModalOpen}
        handleCancel={handleCancel}
      >
        <div className="modalApp">
          <UserAcessForm roleList={roleList} handleCancel={handleCancel} fetchAdminList={fetchAdminList} />
        </div>
      </ModalCommon>
      <ModalCommon
        headTittle={"View Access"}
        isModalVisible={isModalOpenView}
        handleCancel={handleCancelView}
      >
        <div className="modalApp">
          <ViewAccessForm currentRow={currentRow} roleList={roleList} fetchAdminList={fetchAdminList} handleCancelView={handleCancelView} />
        </div>
      </ModalCommon>
      {/* ----------------------------------------------------------- */}
      <Loader isLoading={isLoading} />
    </div>
  );
}
