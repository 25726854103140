import { toast } from "../../Components/Ui/Toast/Toast";
import { apiCalGetFile, apiCallGet, apiCallPost } from "../../Services/ApiClient";
import {
  MANU_LIST_API, ADD_ROLE_API, DELETE_ROLE, UPDATE_ADMIN_DETAIL_API,
  EDIT_ROLE_API, ROLE_LIST_API, ROLE_LIST_FOR_DROPDOWN_API, ROLE_DETAIL_BY_ROLE_API,
  ADD_ADMIN_API, ADMIN_LIST_API, DELETE_ADMIN_API, ADMIN_DETAIL_API, RESEND_PASSWORD_API,
  ACTIVITY_LOG_API, ACTIVITY_TYPE_API
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getMenuList ****************************************************/
export const getMenuList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(MANU_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getMenuList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getMenuList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Add New Role ****************************************************/
export const addRole = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_ROLE_API, data).then(response => {
        const res = response.data;
        // console.log('res addNewRole::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('addNewRole er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Get Role List ****************************************************/
export const getRoleList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ROLE_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getRoleList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getRoleList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};

/************************************** deleteRole ****************************************************/
export const deleteRole = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(DELETE_ROLE, data).then(response => {
        const res = response.data;
        // console.log('res deleteRole::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('deleteRole er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Update New Role ****************************************************/
export const updateRole = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(EDIT_ROLE_API, data).then(response => {
        const res = response.data;
        // console.log('res updateRole::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateRole er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getRoleListForDropdown ****************************************************/
export const getRoleListForDropdown = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(ROLE_LIST_FOR_DROPDOWN_API, data).then(response => {
        const res = response.data;
        // console.log('res getRoleListForDropdown::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getRoleListForDropdown er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Role Details ****************************************************/
export const roleDetailByRoleId = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ROLE_DETAIL_BY_ROLE_API, data).then(response => {
        const res = response.data;
        // console.log('res roleDetailByRoleId::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('roleDetailByRoleId er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Add User Access ****************************************************/
export const addAdmin = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADD_ADMIN_API, data).then(response => {
        const res = response.data;
        // console.log('res addUserAccess::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('addUserAccess er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Admin list ****************************************************/
export const getAdminList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADMIN_LIST_API, data).then(response => {
        const res = response.data;
        // console.log('res getAdminList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getAdminList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Delete Admin ****************************************************/
export const deleteAdmin = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(DELETE_ADMIN_API, data).then(response => {
        const res = response.data;
        // console.log('res deleteAdmin::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('deleteAdmin er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Admin Detail ****************************************************/
export const adminDetailById = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ADMIN_DETAIL_API, data).then(response => {
        const res = response.data;
        // console.log('res adminDetailById::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('adminDetailById er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Update Admin Detail ****************************************************/
export const updateAdminDetailById = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(UPDATE_ADMIN_DETAIL_API, data).then(response => {
        const res = response.data;
        // console.log('res updateAdminDetailById::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('updateAdminDetailById er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Delete Admin ****************************************************/
export const resendPassword = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(RESEND_PASSWORD_API, data).then(response => {
        const res = response.data;
        // console.log('res deleteAdmin::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('deleteAdmin er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** Activity Log list ****************************************************/
export const getActivityLogList = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallPost(ACTIVITY_LOG_API, data).then(response => {
        const res = response.data;
        // console.log('res getActivityLogList::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getActivityLogList er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};
/************************************** getActivity type ****************************************************/
export const getActivityType = ({ data }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallGet(ACTIVITY_TYPE_API, data).then(response => {
        const res = response.data;
        // console.log('res getActivityType::::::', response);
        resolve(res);
      }).catch(error => {
        if (error?.message == 'Unable to decode data.') {
          commonError()
        } else {
          const errorMessage = error.message || error;
          console.log('getActivityType er::::::', error);
          reject(errorMessage);
        }
      });
    });
  };
};